import { configureAPIRoutes } from '@virtus/components/NavigationBar/utils/routeFormatter';
import configureHeader from 'config/headerConfig';

interface IConfig {
  apiUrl: string;
  appBuildNumber: string;
  appEnv: string;
  appVersion: string;
  appVersionBuild: string;
  clientId: string;
  signalrClientId: string;
  docViewerUrl: string;
  tenantId: string;
  headerConfig: any;
  appName: string;
  b2cSigninPolicy: string;
  actuateKey: string;
  ssrsServer: string;
  ssrsKey: string;
  actuate11WebServiceRootURL: string;
  actuate11VolumeName: string;
  actuate11RootURL: string;
  actuateRootURL: string;
  b2cPasswordResetPolicy: string;
  userLoginApiUrl: string;
  signalrUrl: string;
  envColor?: string;
  signalrApiUrl: string;
  reconFileUploadServerName: string;
  reconFileUploadFileFolder: string;
  dataEnvironment: string;
  appInsightsKey: string;
}

export const getConfig = (apiRoutes: any): IConfig => {
  const applicationName: string = 'Genesis';
  const versionNumber: string = '1.4.3';
  const envEnvironment: string = process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : 'local';
  const envAppBuildNumber: string = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : '0000';

  let b2cResetPolicy: string = 'B2C_1_passwordReset1';
  if (envEnvironment === 'prod') {
    b2cResetPolicy = 'B2C_1_PasswordReset';
  }

  const routes = configureAPIRoutes(apiRoutes);

  const headerConfig = configureHeader(routes);

  const actuateKey =
    'ZmwINRj5TtUVAY1ocfcSeDDdQ9O5MRlR8lFvaRZCz%2ByWXN7CksbZi5%2FSx372h7sx2IliIb4tJ0EV8%2B2T3C6DeMBzPWbVVmJNSVQ43WJiN2989MhK345jraL7HJWMbaKbcOEVhhqq5D%2F5l18KqBEGKMJEdAoDRtuEk1bGrVL%2BwYTI3V6qif1LjPtiNQZmg2JCjLpkOf2xJGXR43Rrqaxayw%3D%3D';

  const ssrsKey = 'dmlydHVzYXBwXFZQX1NWQ19TU1JTX1JlcDpmJiZXaVRDQlhDI04lN1Q2QDhoWQ==';

  if (envEnvironment === 'local') {
    return {
      actuate11RootURL: 'http://deveus2actrpt04.virtusapp.local:8910',
      actuate11VolumeName: 'virtus_iserver',
      actuate11WebServiceRootURL: 'http://actuatedev.virtusapp.local:11100',
      actuateKey,
      ssrsServer: 'deveus2srssql01',
      ssrsKey,
      actuateRootURL: 'http://deveus2actrpt04.virtusapp.local:8910',
      // actuateRootURL: 'http://actuatedev.virtusapp.local:8910/',
      apiUrl: 'https://devnext.virtusllc.net/genesisapi/',
      // apiUrl: 'http://localhost/genesisapi/',
      appBuildNumber: envAppBuildNumber,
      appEnv: envEnvironment,
      appName: applicationName,
      appVersion: versionNumber,
      appVersionBuild: versionNumber + '.' + envAppBuildNumber,
      b2cPasswordResetPolicy: b2cResetPolicy,
      b2cSigninPolicy: 'B2C_1_GenesisSignUpSignIn',
      clientId: 'dc641514-5992-4b71-9da2-5a645f489a27', // 'c7bdc462-eeb6-4baf-81fd-9a46c9041743',
      docViewerUrl: 'http://vpdocviewer.virtusapp.local/Default.aspx',
      envColor: '#c0392b',
      headerConfig,
      signalrApiUrl: 'https://devnext.virtusllc.net/SignalR/api',
      signalrClientId: 'd23d0d58-0f54-426a-8a27-61a7c4c826ee',
      signalrUrl: 'https://devnext.virtusllc.net/SignalR/SignalRHub',
      tenantId: 'devvirtuswebportal',
      userLoginApiUrl: 'https://devnext.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
      reconFileUploadServerName: '\\\\FS01',
      reconFileUploadFileFolder: '\\Virtus_Recon',
      // dataEnvironment: 'lastndev',
      dataEnvironment: '',
      appInsightsKey: '7e30f989-b63a-4fc4-904b-8c4cd9df4284',
    };
  }

  if (envEnvironment === 'dev') {
    return {
      actuate11RootURL: 'http://deveus2actrpt04.virtusapp.local:8910',
      actuate11VolumeName: 'virtus_iserver',
      actuate11WebServiceRootURL: 'http://actuatedev.virtusapp.local:11100',
      actuateKey,
      ssrsServer: 'deveus2srssql01',
      ssrsKey,
      actuateRootURL: 'http://deveus2actrpt04.virtusapp.local:8910',
      // actuateRootURL: 'http://actuatedev.virtusapp.local:8910/',
      apiUrl: 'https://devnext.virtusllc.net/genesisapi/',
      appBuildNumber: envAppBuildNumber,
      appEnv: envEnvironment,
      appName: applicationName,
      appVersion: versionNumber,
      appVersionBuild: versionNumber + '.' + envAppBuildNumber,
      b2cPasswordResetPolicy: b2cResetPolicy,
      b2cSigninPolicy: 'B2C_1_GenesisSignUpSignIn',
      clientId: 'dc641514-5992-4b71-9da2-5a645f489a27',
      docViewerUrl: 'http://vpdocviewer.virtusapp.local/Default.aspx',
      envColor: '#c0392b',
      signalrApiUrl: 'https://devnext.virtusllc.net/SignalR/api',
      signalrClientId: 'd23d0d58-0f54-426a-8a27-61a7c4c826ee',
      signalrUrl: 'https://devnext.virtusllc.net/SignalR/SignalRHub',
      tenantId: 'devvirtuswebportal',
      userLoginApiUrl: 'https://devnext.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
      headerConfig,
      reconFileUploadServerName: '\\\\FS01',
      reconFileUploadFileFolder: '\\Virtus_Recon',
      dataEnvironment: '',
      appInsightsKey: '7e30f989-b63a-4fc4-904b-8c4cd9df4284',
    };
  }

  if (envEnvironment === 'qa') {
    return {
      actuate11RootURL: 'http://actuatedev.virtusapp.local:8910',
      actuate11VolumeName: 'virtus_iserver',
      actuate11WebServiceRootURL: 'http://actuatedev.virtusapp.local:11100',
      actuateKey,
      ssrsServer: 'deveus2srssql01',
      ssrsKey,
      actuateRootURL: 'http://actuatedev.virtusapp.local:8910',
      apiUrl: 'https://stgnext.virtusllc.net/genesisapi/',
      appBuildNumber: envAppBuildNumber,
      appEnv: envEnvironment,
      appName: applicationName,
      appVersion: versionNumber,
      appVersionBuild: versionNumber + '.' + envAppBuildNumber,
      b2cPasswordResetPolicy: b2cResetPolicy,
      b2cSigninPolicy: 'B2C_1_GenesisSignUpSignIn',
      clientId: '6b1ac100-0891-4fcc-8125-65b43564c521',
      docViewerUrl: 'http://vpdocviewer.virtusapp.local/Default.aspx',
      envColor: '#ffa824',
      signalrApiUrl: 'https://stgnext.virtusllc.net/SignalR/api',
      signalrClientId: 'd23d0d58-0f54-426a-8a27-61a7c4c826ee',
      signalrUrl: 'https://stgnext.virtusllc.net/SignalR/SignalRHub',
      tenantId: 'stagingvirtuswebportal',
      userLoginApiUrl: 'https://stgnext.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
      headerConfig,
      reconFileUploadServerName: '\\\\FS01',
      reconFileUploadFileFolder: '\\Virtus_Recon',
      dataEnvironment: '',
      appInsightsKey: 'fdf05f70-98d9-4cbe-8188-986970abe023',
    };
  }

  if (envEnvironment === 'prod') {
    return {
      actuate11RootURL: 'http://rs01.virtusapp.local:8910',
      actuate11VolumeName: 'virtus_iserver',
      actuate11WebServiceRootURL: 'http://rs01.virtusapp.local:11100',
      actuateKey,
      ssrsServer: 'deveus2srssql01',
      ssrsKey,
      actuateRootURL: 'http://rs01.virtusapp.local:8910',
      apiUrl: 'https://next.virtusllc.net/genesisapi/',
      appBuildNumber: envAppBuildNumber,
      appEnv: envEnvironment,
      appName: applicationName,
      appVersion: versionNumber,
      appVersionBuild: versionNumber + '.' + envAppBuildNumber,
      b2cPasswordResetPolicy: b2cResetPolicy,
      b2cSigninPolicy: 'B2C_1_GenesisSignUpSignIn',
      clientId: 'a0097d9f-d322-43b0-8fac-fae2a83cf800',
      docViewerUrl: 'http://vpdocviewer.virtusapp.local/Default.aspx',
      signalrApiUrl: 'https://next.virtusllc.net/SignalR/api',
      signalrClientId: 'd23d0d58-0f54-426a-8a27-61a7c4c826ee',
      signalrUrl: 'https://next.virtusllc.net/SignalR/SignalRHub',
      tenantId: 'prodvirtuswebportal',
      userLoginApiUrl: 'https://next.virtusllc.net/UserLoginApi/api/UserLogin/DoLogin/',
      headerConfig,
      reconFileUploadServerName: '\\\\FS01',
      reconFileUploadFileFolder: '\\Virtus_Recon',
      dataEnvironment: '',
      appInsightsKey: '4949ccb1-b744-49d5-955a-c0536f5b7ebc',
    };
  }

  throw new Error('No config found for ' + applicationName + ' ' + envEnvironment + ' environment');
};

const config = getConfig([]);
export default config;
