import React, { memo } from 'react';
import uniqid from 'uniqid';
import { shallowCompare } from 'util/helpers/helperFunctions';
import ValidationControl from '../ValidationControl/ValidationControl';
import DropdownControl, { IProps } from './DropdownControl';

export interface IProp extends IProps {
  isRequired: boolean;
  isReadonly: boolean;
  hideValidationControl?: boolean;
  isValid?: boolean;
  validationMessage?: string;
}
export class DropdownControlObject extends React.Component<IProp> {
  private uid = uniqid();
  public render() {
    return (
      <table>
        <tbody>
          <tr>
            {!this.props.hideValidationControl ? (
              <td>
                <ValidationControl
                  {...this.props}
                  isValid={this.props.isValid !== undefined ? this.props.isValid : true}
                  message={this.props.validationMessage ? this.props.validationMessage : 'An input is required'}
                  uid={this.uid}
                />
              </td>
            ) : (
              <></>
            )}
            <td>
              <DropdownControl {...this.props} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

function arePropsEqual(prevProps: IProp, nextProps: IProp) {
  const oldProps = {
    ...prevProps,
  };
  const newProps = {
    ...nextProps,
  };
  if (JSON.stringify(oldProps.data) !== JSON.stringify(newProps.data)) {
    return false;
  }

  if ('data' in oldProps) {
    delete oldProps.data;
  }
  if ('data' in newProps) {
    delete newProps.data;
  }

  if ('theme' in oldProps) {
    delete oldProps.theme;
  }
  if ('theme' in newProps) {
    delete newProps.theme;
  }

  if ('getDefaultValue' in oldProps) {
    delete oldProps.getDefaultValue;
  }
  if ('getDefaultValue' in newProps) {
    delete newProps.getDefaultValue;
  }

  if ('onChange' in oldProps) {
    delete oldProps.onChange;
  }
  if ('onChange' in newProps) {
    delete newProps.onChange;
  }

  if ('onAddModalLabelClick' in oldProps) {
    delete oldProps.onAddModalLabelClick;
  }
  if ('onAddModalLabelClick' in newProps) {
    delete newProps.onAddModalLabelClick;
  }
  if ('onAddClick' in oldProps) {
    delete oldProps.onAddClick;
  }
  if ('onAddClick' in newProps) {
    delete newProps.onAddClick;
  }

  if ('onEditClick' in oldProps) {
    delete oldProps.onEditClick;
  }
  if ('onEditClick' in newProps) {
    delete newProps.onEditClick;
  }

  return shallowCompare(oldProps, newProps);
}

export default memo(DropdownControlObject, arePropsEqual);
