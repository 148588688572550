import { VirtusIcon } from '@virtus/components/icons';
import { Route } from '@virtus/components/page/models/route';
import { ComplianceIcon } from 'icons/ComplianceIcon';
import { DealMaintenaceIcon } from 'icons/DealMaintenaceIcon';
import { DealSetupIcon } from 'icons/DealSetupIcon';
import GenesisIcon from 'icons/GenesisIcon';
import { GlobalInfoIcon } from 'icons/GlobalInfoIcon';
import { HelpIcon } from 'icons/HelpIcon';
import { HomeIcon } from 'icons/HomeIcon';
import { ReportsIcon } from 'icons/ReportsIcon';
import { SystemSetupIcon } from 'icons/SystemSetupIcon';
import { ToolsIcon } from 'icons/ToolsIcon';
import { VExternalIcon } from 'icons/VExternalIcon';
import { VInternalIcon } from 'icons/VInternalIcon';

const headerConfig = (routes: Route[]) => {
  const homeRoute: Route = {
    // context: 'Home',
    fullPath: '/DealList',
    icon: {
      component: HomeIcon,
      enableInSideBar: true,
      sideBarProps: { style: { height: '16px', width: '16px' } },
    },
    name: 'Home',
    path: 'DealList',
  };

  if (routes.length > 0) {
    routes = [homeRoute, ...routes];
    applyIcons(routes);
  }
  return {
    appButton: {
      icon: {
        component: GenesisIcon,
        props: {
          style: { width: '12px', marginRight: '6px' },
        },
      },
      name: 'Genesis',
      routePath: '/DealList',
    },
    routes: {
      mainLogo: {
        component: VirtusIcon,
        props: {
          style: {
            width: '100px',
          },
        },
      },
      name: 'root',
      path: '',
      subroutes: routes,
    },
  };
};

const applyIcons = (routes: any) => {
  routes.forEach((route: any) => {
    const component = getIcon(Number(route.menuId));
    if (component) {
      route.icon = {
        component,
        enableInSideBar: true,
        sideBarProps: { style: { height: '16px', width: '16px' } },
      };
    }
  });
};

const getIcon = (menuId: number) => {
  switch (menuId) {
    case 218:
      return GlobalInfoIcon;
    case 121:
      return ReportsIcon;
    case 854:
      return ToolsIcon;
    case 137:
      return SystemSetupIcon;
    case 1008:
      return VInternalIcon;
    case 138:
      return HelpIcon;
    case 115:
      return DealSetupIcon;
    case 134:
      return DealMaintenaceIcon;
    case 988:
      return VExternalIcon;
    case -1:
      return ComplianceIcon;
    case 136:
      return ComplianceIcon;
    default:
      return;
  }
};

export default headerConfig;
