export enum ControllerNameEnum {
  AccountingSnapshot = 'AccountingSnapshot',
  AccountTransactionMapping = 'AccountTransactionMapping',
  Accrual = 'Accrual',
  AccruedInterest = 'AccruedInterest',
  AccruedInterestReport = 'AccruedInterestReport',
  AgentsContacts = 'AgentsContacts',
  Asset = 'Asset',
  AssetBuilder = 'AssetBuilder',
  AssetFacilityProperties = 'AssetFacilityProperties',
  AssetFacilityTransactions = 'AssetFacilityTransactions',
  AssetReconSnapshot = 'AssetReconSnapshot',
  AssetSecurityProperties = 'AssetSecurityProperties',
  BanksCounterparties = 'BanksCounterparties',
  BenchmarkIndexPrice = 'BenchmarkIndexPrice',
  CaseNumberConfiguration = 'CaseNumberConfiguration',
  CashAccounts = 'CashAccounts',
  CashAccountTransfer = 'CashAccountTransfer',
  CashFlowSnapshot = 'CashFlowSnapshot',
  CashRecon = 'CashRecon',
  CdsOrCdx = 'CdsOrCdx',
  CommonFunctions = 'CommonFunctions',
  Company = 'Company',
  Compliance = 'Compliance',
  ComplianceConfig = 'ComplianceConfig',
  CountryAlloc = 'CountryAlloc',
  CreditAgreements = 'CreditAgreements',
  CreditDefaultSwaps = 'CreditDefaultSwaps',
  CrossRef = 'CrossRef',
  CurrencyExchangeRate = 'CurrencyExchangeRate',
  CusipExchange = 'CusipExchange',
  DataIntegrity = 'DataIntegrity',
  DataRules = 'DataRules',
  DdaAccounts = 'DdaAccounts',
  DdaReports = 'DdaReports',
  DealCustom = 'DealCustom',
  DealDashboard = 'DealDashboard',
  DealInfo = 'DealInfo',
  DealMaintenance = 'DealMaintenance',
  DealPortfolio = 'DealPortfolio',
  DynamicReport = 'DynamicReport',
  ExpressionBuilder = 'ExpressionBuilder',
  Facility = 'Facility',
  FacilityAssetSettlement = 'FacilityAssetSettlement',
  FacilityFeePayment = 'FacilityFeePayment',
  FacilityTrades = 'FacilityTrades',
  FacilityTransactions = 'FacilityTransactions',
  Fee = 'Fee',
  FtpExportFiles = 'FtpExportFiles',
  GdiConfig = 'GdiConfig',
  GdiSnapshot = 'GdiSnapshot',
  Generic = 'Generic',
  GlobalDataFeed = 'GlobalDataFeed',
  GlobalExpectedTransactions = 'GlobalExpectedTransactions',
  GlobalInfo = 'GlobalInfo',
  GlobalIssue = 'GlobalIssue',
  GtamEntity = 'GtamEntity',
  GtamEntityController = 'GtamEntityController',
  Hedges = 'Hedges',
  HedgeSetup = 'HedgeSetup',
  HolidayCalendar = 'HolidayCalendar',
  ImportFromFileJobInventory = 'ImportFromFileJobInventory',
  IncludesAssetLiability = 'IncludesAssetLiability',
  IndustryClassification = 'IndustryClassification',
  InterestAccrual = 'InterestAccrual',
  InterestRate = 'InterestRate',
  IssueDerivative = 'IssueDerivative',
  IssueEquities = 'IssueEquities',
  IssueLoans = 'IssueLoans',
  Issuer = 'Issuer',
  IssuersAndBorrowers = 'IssuersAndBorrowers',
  IssuersBorrowers = 'IssuersBorrowers',
  Issues = 'Issues',
  IssueSecurities = 'IssueSecurities',
  IssueSetup = 'IssueSetup',
  Laserfiche = 'Laserfiche',
  LiabilitiesCapitalStructure = 'LiabilitiesCapitalStructure',
  LiabilityInfo = 'LiabilityInfo',
  LiborCurve = 'LiborCurve',
  LoanFacilities = 'LoanFacilities',
  LoanFacility = 'LoanFacility',
  LoanInterest = 'LoanInterest',
  MisReports = 'MisReports',
  NewFacilityTransaction = 'NewFacilityTransaction',
  NewLoanRequest = 'NewLoanRequest',
  NextUserDefaultSettings = 'NextUserDefaultSettings',
  NexusConfig = 'NexusConfig',
  NexusPortfolio = 'NexusPortfolio',
  Notifications = 'Notifications',
  Otc = 'Otc',
  Payees = 'Payees',
  Portfolio = 'Portfolio',
  PortfolioSnapshotLayouts = 'PortfolioSnapshotLayouts',
  PortfolioSnapshots = 'PortfolioSnapshots',
  PricingConfiguration = 'PricingConfiguration',
  PrincipalBalance = 'PrincipalBalance',
  ProjectedPayments = 'ProjectedPayments',
  Queues = 'Queues',
  RatingDisclosureIssuer = 'RatingDisclosureIssuer',
  RatingMatrix = 'RatingMatrix',
  Ratings = 'Ratings',
  ReferenceEntities = 'ReferenceEntities',
  ReferencePortfolios = 'ReferencePortfolios',
  ReportConfiguration = 'ReportConfiguration',
  ReportGroups = 'ReportGroups',
  ReportingSchedule = 'ReportingSchedule',
  ReportPackage = 'ReportPackage',
  Restructures = 'Restructures',
  SecurityAsset = 'SecurityAsset',
  SecurityTrades = 'SecurityTrades',
  Service = 'Service',
  Servicers = 'Servicers',
  Settlement = 'Settlement',
  Sql = 'Sql',
  Sweeps = 'Sweeps',
  SwiftTradeManagement = 'SwiftTradeManagement',
  SystemAdmin = 'SystemAdmin',
  SystemSetup = 'SystemSetup',
  Tools = 'Tools',
  TradeManagement = 'TradeManagement',
  TradingRestriction = 'TradingRestriction',
  TransactionDetails = 'TransactionDetails',
  TransactionMatching = 'TransactionMatching',
  Transactions = 'Transactions',
  VirtusCompliance = 'VirtusCompliance',
  VirtusComplianceSettings = 'VirtusComplianceSettings',
  VirtusDataFeed = 'VirtusDataFeed',
  VirtusImport = 'VirtusImport',
  VirtusMarketValue = 'VirtusMarketValue',
  VirtusPerformance = 'VirtusPerformance',
  VirtusRecon = 'VirtusRecon',
  VirtusReports = 'VirtusReports',
  VirtusTrsSnapshots = 'VirtusTrsSnapshots',
  VirtusUtil = 'VirtusUtil',
  WizardRestructure = 'WizardRestructure',
  WizardTransactions = 'WizardTransactions',
  XMLTradeFileAccess = 'XMLTradeFileAccess',
}

export enum SelectionType {
  MultiSelect = 'MultiSelect',
  SingleSelect = 'SingleSelect',
}

export enum UpdateTypes {
  Group,
  Subgroup,
}

export enum FileType {
  XLS,
  CSV,
  XLSX,
}

export interface IGroupKeys {
  groupName?: string;
  subgroupName?: string;
}

export enum ImportLayoutType {
  Item = 'ITEM',
  Issue = 'ISSU',
}

export enum ImportLayoutDesc {
  Item = 'Purchase Lot',
  Issue = 'Asset/Contract',
}

export enum ImportFileMessage {
  FileExcessError = `Possible causes could be:
    1. Invalid file type.
    2. Invalid sheet name (excel files only).
    3. Source file has been corrupted or locked by another process.`,
}

export enum CouponIndex {
  CouponIndexType = 0,
  CouponIndex = 1,
}
