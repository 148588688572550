// contains SNAKE CASE constants
export const dbConstAgentId = 'agent_id';
export const dbConstAssetId = 'asset_id';
export const dbConstRatingAgency = 'rating_agency';
export const dbConstElectionDesignation = 'election_designation';
export const dbConstElectionStatus = 'election_status';
export const dbConstShowRsLink = 'show_rs_link';
export const dbConstElectionComments = 'election_comments';
export const dbConstShowLinkFacility = 'show_link_facility';
export const dbConstBankId = 'bank_id';
export const dbConstAccountId = 'account_id';
export const dbConstReportTitle = 'report_title';
export const dbConstReportFile = 'report_file';
export const dbConstMaxItems = 'max_items';
export const dbConstAllTestsHaveRun = 'all_tests_have_run';
export const dbConstCurrencyCode = 'currency_code';
export const dbConstRatingSource = 'rating_source';
export const dbConstSwiftCode = 'SWIFT_code';
export const dbConstAccountNum = 'account_num';
export const dbConstAccountName = 'account_name';
export const dbConstOperationMessageCode = 'operation_message_code';
export const dbConstOperationMessage = 'operation_message';
export const dbConstActionMessage = 'action_message';
export const dbConstActionMessageCode = 'action_message_code';
export const dbConstMessageDesc = 'message_desc';
export const dbConstOperationDetails = 'operation_details';
export const dbConstRatingId = 'rating_id';
export const dbConstAssetType = 'asset_type';
export const dbConstServicerType = 'servicer_type';
export const dbConstRating = 'Rating';
export const dbConstRatingDate = 'rating_date';
export const dbConstRatingEndDate = 'rating_end_date';
export const dbConstRatingComment = 'rating_comments';
export const dbConstWatchListStatus = 'watchlist_status';
export const dbConstRowVersion = 'row_version';
export const dbConstTaId = 'ta_id';
export const dbConstPikPercentage = 'pik_percentage';
export const dbConstShowEdit = 'show_edit';
export const dbConstShowDel = 'show_del';
export const dbConstShowDelete = 'show_delete';
export const dbConstUserId = 'user_id';
export const dbConstLegalName = 'legal_name';
export const dbConstBankNumber = 'bank_number';
export const dbConstDtccId = 'dtcc_id';
export const dbConstGiinNumber = 'giin_number';
export const dbConstCutOffTime = 'cut_off_time';
export const dbConstBankRoles = 'bank_roles';
export const dbConstAddr1 = 'addr_1';
export const dbConstAddr2 = 'addr_2';
export const dbConstAgentCity = 'agent_city';
export const dbConstAgentState = 'agent_state';
export const dbConstAgentZipCode = 'agent_zip_code';
export const dbConstAgentProvince = 'agent_province';
export const dbConstAgentCountry = 'agent_country';
export const dbConstAgentMainPhone = 'agent_main_phone';
export const dbConstAgentMainFax = 'agent_main_fax';
export const dbConstAgentNote = 'agent_note';
export const dbConstSilentMode = 'silent_mode';
export const dbConstOperationConfirmed = 'operation_confirmed';
export const dbConstAbaNumber = 'ABA_number';
export const dbConstDtcNumber = 'dtc_number';
export const dbConstSwiftBicCode = 'swift_bic_code';
export const dbConstSecondaryBicCode = 'secondary_bic_code';
export const dbConstBrokerDealerId = 'broker_dealer_id';
export const dbConstOasysAcronym = 'oasys_acronym';
export const dbConstDtcNumberEq = 'dtc_number_eq';
export const dbConstOasysAcronymEq = 'oasys_acronym_eq';
export const dbConstBrokerDealerIdEq = 'broker_dealer_id_eq';
export const dbConstFtrActionType = 'ftr_action_type';
export const dbConstAssetTypeId = 'asset_type_id';
export const dbConstFtrEventId = 'ftr_event_id';
export const dbConstCompanyId = 'company_id';
export const dbConstIssueType = 'issue_type';
export const dbConstSecurityType = 'security_type';
export const dbConstLienType = 'lien_type';
export const dbConstDerivativeType = 'derivative_type';
export const dbConstAssetTypeDescription = 'asset_type_description';
export const dbConstTradeCounterParty = 'trade_counterparty';
export const dbConstSaleReasonCode = 'sale_reason_code';
export const dbConstMarketTradeType = 'market_trade_type';
export const dbConstSecFee = 'sec_fee';
export const dbConstPurchaseTypeId = 'purchase_type_id';
export const dbConstSettlementAgent = 'settlement_agent';
export const dbConstTraderName = 'trader_name';
export const dbConstSettlementInstructions = 'settlement_instructions';
export const dbConstBrokerCommission = 'broker_commission';
export const dbConstStrikePrice = 'strike_price';
export const dbConstDdaTransId = 'dda_trans_id';
export const dbConstCreatedWith = 'created_with';
export const dbConstCashTransId = 'cash_trans_id';
export const dbConstCounterpartyContact = 'counterparty_contact';
export const dbConstDescription = 'ta_description';
export const dbConstTaCode = 'ta_code';
export const dbConstTaComment = 'ta_comment';
export const dbConstParticipationAgent = 'participation_agent';
export const dbConstDtcBrokerBicId = 'DTC_broker_bic_id';
export const dbConstDtcBrokerBicIdEq = 'DTC_broker_bic_id_eq';
export const dbConstEuroclearBrokerBicId = 'euroclear_broker_bic_id';
export const dbConstEuroclearBrokerBicIdEq = 'euroclear_broker_bic_id_eq';
export const dbConstClearstreamBrokerBicId = 'clearstream_broker_bic_id';
export const dbConstClearstreamBrokerBicIdEq = 'clearstream_broker_bic_id_eq';
export const dbConstCtmAcronym = 'CTM_acronym';
export const dbConstCtmAcronymEq = 'CTM_acronym_eq';
export const dbConstEuroclearNumber = 'euroclear_number';
export const dbConstEuroclearNumberEq = 'euroclear_number_eq';
export const dbConstRecordId = 'record_id';
export const dbConstClearstreamNumber = 'clearstream_number';
export const dbConstClearstreamNumberEq = 'clearstream_number_eq';
export const dbConstCountryCode = 'country_code';
export const dbConstShortLongTerm = 'short_long_term';
export const dbConstLocForeignCurrency = 'loc_foreign_currency';
export const dbConstIssuerId = 'issuer_id';
export const dbConstLfBorrowerName = 'lf_borrower_name';
export const dbConstMatchRegex = 'match_regex';
export const dbConstMatchRegexId = 'match_regex_id';
export const dbConstVerified = 'verified';
export const dbConstActive = 'active';
export const dbConstMatchResult = 'match_result';
export const dbConstAsOfDate = 'as_of_date';
export const dbConstIssuerName = 'issuer_name';
export const dbConstIssueName = 'issue_name';
export const dbConstCountryRating = 'country_rating';
export const dbConstEntityId = 'entity_id';
export const dbConstCurrentFitchIndustryId = 'current_fitch_industry_id';
export const dbConstFitchIssuerIDNumber = 'Fitch_Issuer_ID_Number';
export const dbConstRsIssueSellId = 'rs_issue_sell_id';
export const dbConstMoodysIssuerNumber = 'moodys_issuer_number';
export const dbConstCurrentMoodysIndustryId = 'current_moodys_industry_id';
export const dbConstCurrentSpIndustryId = 'current_sp_industry_id';
export const dbConstSuccessCriteriaRequired = 'success_criteria_required';
export const dbConstXRefExists = 'XRefExists';
export const dbConstIwId = 'iw_id';
export const dbConstFacilityId = 'facility_id';
export const dbConstCaId = 'ca_id';
export const dbConstInstructions = 'instructions';
export const dbConstDealType = 'deal_type';
export const dbConstIssuerRatingType = 'issuer_rating_type';
export const dbConstCollGroupCode = 'coll_group_code';
export const dbConstCollGroupComments = 'coll_group_comments';
export const dbConstCollGroupDesc = 'coll_group_desc';
export const dbConstIssuerCusip = 'issuer_cusip';
export const dbConstAssignedDate = 'assigned_date';
export const dbConstIssuerCusipComments = 'issuer_cusip_comments';
export const dbConstNewFacilityCommitmentPriorRs = 'new_facility_commitment_Prior_rs';
export const dbConstIssuerRating = 'issuer_rating';
export const dbConstIssuerRatingDesignation = 'issuer_rating_designation';
export const dbConstIssuerRatingComments = 'issuer_rating_comments';
export const dbConstRatingCode = 'rating_code';
export const dbConstGroupCode = 'group_code';
export const dbConstContact = 'Contact_ids';
export const dbConstCode = 'code';
export const dbConstCallDate = 'call_date';
export const dbConstCommitmentAmount = 'commitment_amount';
export const dbConstCostTransferMethod = 'cost_transfer_method';
export const dbConstMarketPriceType = 'market_price_type';
export const dbConstTradeAllocationMethod = 'trade_allocation_method';
export const dbConstRequiresRestructureDesignation = 'requires_restructure_designation';
export const dbConstMajorMinor = 'major_minor';
export const dbConstJurisdictionCode = 'jurisdiction_code';
export const dbConstRsDate = 'rs_date';
export const dbConstFundedAmount = 'funded_amount';
export const dbConstTransCashAmounts = 'trans_cash_amounts';
export const dbConstPrincipalAmount = 'principal_amount';
export const dbConstIssueTypeDesc = 'issue_type_desc';
export const dbConstNewLoanAmountLc = 'new_loan_amount_lc';
export const dbConstIsLiabilityInSystem = 'is_liability_in_system';
export const dbConstSecurityTypeGroup = 'security_type_group';
export const dbConstCurrentOutstanding = 'Current Outstanding';
export const dbConstNewLoanFacilityLotAllocation = 'new_loan_facility_lot_allocation';
export const dbConstFacilityLotAllocation = 'facility_lot_allocation';
export const dbConstNewLoanAmountBc = 'new_loan_amount_bc';
export const dbConstSecuritytype = 'Security Type';
export const dbConstAssignmentId = 'assignment_id';
export const dbConstAssignmentType = 'assignment_type';
export const dbConstFacilityName = 'facility_name';
export const dbConstCaDescription = 'ca_description';
export const dbConstMaxTransDate = 'max_trans_date';
export const dbConstFwId = 'fw_id';
export const dbConstValidation = 'validation';
export const dbConstIsDealIssuer = 'is_deal_issuer';
export const dbConstOptCurrency = 'opt_currency';
export const dbConstMultiCurrency = 'multi_currency';
export const dbConstCurrentCommitmentAmount = 'current_commitment_amount';
export const dbConstCurrentFundedAmount = 'current_funded_amount';
export const dbConstNetFundedAmount = 'net_funded_amount';
export const dbConstSystemStartDate = 'system_start_date';
export const dbConstValidated = 'Validated';
export const dbConstIssueCode = 'issue_code';
export const dbConstFacilityType = 'facility_type';
export const dbConstEffectiveDate = 'effective_date';
export const dbConstStartDate = 'start_date';
export const dbConstMaturityDate = 'maturity_date';
export const dbConstOrigCommitmentAmount = 'orig_commitment_amount';
export const dbConstFacilityGlobalTransId = 'facility_global_trans_id';
export const LoanID = 'Loan ID';
export const dbConstDisplayName = 'display_name';
export const dbConstItemSubEntityId = 'item_sub_entity_id';
export const dbConstDataType = 'data_type';
export const dbConstSubEntity = 'sub_entity';
export const dbConstQCValidationStatus = 'QC_validation_status';
export const dbConstAggregationTitle = 'aggregation_title';
export const dbConstCategoryTitle = 'category_title';
export const dbConstFacilityNumber = 'facility_number';
export const dbConstCUSIPNumber = 'CUSIP_number';
export const dbConstIsinNumber = 'ISIN_number';
export const dbConstAsDescription = 'as_description';
export const dbConstAsComments = 'as_comments';
export const dbConstUseAsDefault = 'use_as_default';
export const dbConstDefaultApplication = 'default_application';
export const dbConstRuleDesc = 'rule_desc';
export const dbConstRuleOrder = 'rule_order';
export const dbConstRuleComment = 'rule_comment';
export const dbConstAsRuleId = 'as_rule_id';
export const dbConstRuleId = 'rule_id';
export const dbConstAsId = 'as_id';
export const dbConstLINNumber = 'LIN_number';
export const dbConstLxIdentifier = 'LX_identifier';
export const dbConstFeeId = 'fee_id';
export const dbConstAmountId = 'amount_id';
export const dbConstAmountType = 'amount_type';
export const dbConstFacilitySchedId = 'facility_sched_id';
export const dbConstPurchaseAmount = 'purchase_amount';
export const dbConstDisbursementReceipt = 'disbursement_receipt';
export const dbConstPurchaseRate = 'purchase_rate';
export const dbConstComponentCategory = 'component_category';
export const dbConstComponentDesc = 'component_desc';
export const dbConstBaseAmount = 'base_amount';
export const dbConstComponentCurrency = 'component_currency';
export const dbconstFundingDate = 'funding_date';
export const dbConstCommitmentPeriodEndDate = 'commitment_period_end_date';
export const dbConstSettleDate = 'settle_date';
export const dbConstTransTypeCat = 'trans_type_cat';
export const dbConstCurrentPikAmount = 'current_pik_amount';
export const dbConstPikAmount = 'pik_amount';
export const dbConstSequenceNumber = 'sequence_number';
export const dbConstSpRepurchase = 'sp_repurchase';
export const dbConstTradeType = 'trade_type';
export const dbConstLenderContactId = 'lender_contact_id';
export const dbConstBorrowerContactId = 'borrower_contact_id';
export const dbConstSecurityLevel = 'security_level';
export const dbConstFacilityStatus = 'facility_status';
export const dbConstTradeStatus = 'trade_status';
export const dbConstFacilityAmortizationType = 'facility_amortization_type';
export const dbConstFacilityComments = 'facility_comments';
export const dbConstNonBaseCurrency = 'non_base_currency';
export const dbConstPrepaymentInstructions = 'prepayment_instructions';
export const dbConstOperationPrimaryKey = 'operation_primary_key';
export const dbConstOperationResultCode = 'operation_result_code';
export const dbConstSeparateCashTtransaction = 'separate_cash_transaction';
export const dbConstSeparatePayee = 'separate_payee';
export const dbConstFeeSignSale = 'fee_sign_sale';
export const dbConstIncludeInAdjPrice = 'include_in_adj_price';
export const dbConstFeeSignPurchase = 'fee_sign_purchase';
export const dbConstFeeType = 'fee_type';
export const dbConstPaymentDate = 'payment_date';
export const dbConstCalculatedAmount = 'calculated_amount';
export const dbConstDefaultDate = 'default_date';
export const dbConstDefaultStatus = 'default_status';
export const dbConstDefaultReason = 'default_reason';
export const dbConstNonperformingId = 'nonperforming_id';
export const dbConstNonperforming = 'non_performing';
export const dbConstAuditId = 'audit_id';
export const dbConstFieldName = 'field_name';
export const dbConstCompId = 'comp_id';
export const dbConstTableName = 'table_name';
export const dbConstPKeys = 'p_keys';
export const dbConstUpdateDate = 'update_date';
export const dbConstUpdateBy = 'update_by';
export const dbConstComments = 'comments';
export const dbConstMultiplePrimeLoans = 'multiple_prime_loans';
export const dbConstOrigCommitmentFee = 'orig_commitment_fee';
export const dbConstGlobalParAmount = 'global_par_amount';
export const dbConstGlobalPrincipalAmount = 'global_principal_amount';
export const dbConstSavedCalculatedInterestAmount = 'saved_calculated_interest_amount';
export const dbConstSavedWrittenUpAmount = 'saved_written_up_amount';
export const dbConstSavedWrittenDownAmount = 'saved_written_down_amount';
export const dbConstCalculatedInterestAmount = 'calculated_interest_amount';
export const dbConstGlobalTransPrice = 'global_trans_price';
export const dbConstGlobalExpectedInterestAmount = 'global_expected_interest_amount';
export const dbConstSavedTransactionInterestAmount = 'saved_transaction_interest_amount';
export const dbConstDealName = 'deal_name';
export const dbConstNewFactor = 'new_factor';
export const dbConstGlobalInterestAmount = 'global_interest_amount';
export const dbConstInterestAmount = 'interest_amount';
export const dbConstSavedPikAmount = 'saved_pik_amount';
export const dbConstOldFactor = 'old_factor';
export const dbConstPikInterestRate = 'pik_interest_rate';
export const dbConstEquityType = 'equity_type';
export const dbConstIssueDate = 'issue_date';
export const dbConstIssueStatus = 'issue_status';
export const dbConstExpectedAvgLife = 'expected_avg_life';
export const dbConstIssuePrice = 'issue_price';
export const dbConstIssueDenomination = 'issue_denomination';
export const dbConstCouponType = 'coupon_type';
export const dbConstIamId = 'iam_id';
export const dbConstIntRateResetPeriod = 'int_rate_reset_period';
export const dbConstCouponIndex = 'coupon_index';
export const dbConstIntPaymentPeriod = 'int_payment_period';
export const dbConstPrincPaymentPeriod = 'princ_payment_period';
export const dbConstOriginalSpread = 'original_spread';
export const dbConstLblOriginalCoupon = 'lbl_original_coupon';
export const dbConstOriginalCoupon = 'original_coupon';
export const dbConstIntAccrualBeginDate = 'int_accrual_begin_date';
export const dbConstIntAccrualEndDate = 'int_accrual_end_date';
export const dbConstIntRateOption = 'int_rate_option';
export const dbConstFirstPaymentDate = 'first_payment_date';
export const dbConstIntPaymentSchedule = 'int_payment_schedule';
export const dbConstInterestOnly = 'interest_only';
export const dbConstOfferingAmount = 'offering_amount';
export const dbConstOutstandingAmount = 'outstanding_amount';
export const dbConstCurrentPikFactor = 'current_pik_factor';
export const dbConstBondTypeId = 'bond_type_id';
export const dbConstAmortizingSecurity = 'amortizing_security';
export const dbConstRestricted = 'restricted';
export const dbConstSecStatusId = 'sec_status_id';
export const dbConstUnitDeal = 'unit_deal';
export const dbConstPayInKind = 'pay_in_kind';
export const dbConstPayInKindExpDate = 'pay_in_kind_exp_date';
export const dbConstDefIntPikBond = 'def_int_PIK_bond';
export const dbConstTenderExchOffer = 'tender_exch_offer';
export const dbConstOfferInKind = 'offer_in_kind';
export const dbConstOikFirstPaymentDate = 'OIK_first_payment_date';
export const dbConstConvCommodType = 'conv_commod_type';
export const dbConstGuarantorId = 'guarantor_id';
export const dbConstInterestAmountPik = 'interest_amount_pik';
export const dbConstInterestAmountCash = 'interest_amount_cash';
export const dbConstIssueId = 'issue_id';
export const dbConstIssueTransId = 'issue_trans_id';
export const dbConstDealRowVersion = 'deal_row_version';
export const dbConstOriginalIndex = 'original_index';
export const dbConstTradeDirection = 'trade_direction';
export const dbConstRefIssueId = 'ref_issue_id';
export const dbConstLoanDescription = 'loan_description';
export const dbConstLoanAllocation = 'loan_allocation';
export const dbConstRecordsetHeader = 'recordset_header';
export const dbConstFacilityMarketValueComments = 'facility_market_value_comments';
export const dbConstMarkitDepth = 'markit_depth';
export const dbConstPricingType1 = 'pricing_type_1';
export const dbConstPricingType2 = 'pricing_type_2';
export const dbConstPricingType3 = 'pricing_type_3';
export const dbConstPricingType4 = 'pricing_type_4';
export const dbConstPricingType5 = 'pricing_type_5';
export const dbConstPricingType6 = 'pricing_type_6';
export const dbConstPricingType7 = 'pricing_type_7';
export const dbConstPricingType8 = 'pricing_type_8';
export const dbConstPricingType9 = 'pricing_type_9';
export const dbConstIndexType = 'index_type';
export const dbConstIndexCurrency = 'index_currency';
export const dbConstIndexTenor = 'index_tenor';
export const dbConstIndexValue = 'index_value';
export const dbConstCanDelete = 'can_delete';
export const dbConstIntCurrentSpread = 'int_current_spread';
export const dbConstInitialSpread = 'initial_spread';
export const dbConstMinIndexRate = 'min_index_rate';
export const dbConstMaxIndexRate = 'max_index_rate';
export const dbConstFsComments = 'fs_comments';
export const dbConstCategory = 'category';
export const dbConstIssueMarketValueComments = 'issue_market_value_comments';
export const dbConstEquityMarketValueComments = 'equity_market_value_comments';
export const dbConstMarketValueIndent = 'market_value_indent';
export const dbConstMarketValueGaap = 'market_value_GAAP';
export const dbConstUdfLookupSp = 'udf_lookup_sp';
export const dbConstLookupTypeId = 'lookup_type_id';
export const dbConstAllowNulls = 'allow_nulls';
export const dbConstMinLength = 'min_length';
export const dbConstMaxLength = 'max_length';
export const dbConstMinValue = 'min_value';
export const dbConstMaxValue = 'max_value';
export const dbConstLookupType = 'lookup_type';
export const dbConstAccrualBasis = 'accrual_basis';
export const dbConstAccrualRate = 'accrual_rate';
export const dbConstFeeComments = 'fee_comments';
export const dbConstFeeDesc = 'fee_desc';
export const dbConstFeeDescription = 'fee_description';
export const dbConstFeePayee = 'fee_payee';
export const dbConstFeeCurrency = 'fee_currency';
export const dbConstLocId = 'loc_id';
export const dbConstFoaId = 'foa_id';
export const dbConstFeePaymentSchedType = 'fee_payment_sched_type';
export const dbConstFeeAmount = 'fee_amount';
export const dbConstInterestType = 'interest_type';
export const dbConstAccrualBeginDate = 'accrual_begin_date';
export const dbConstAccrualEndDate = 'accrual_end_date';
export const dbConstCalcFeeType = 'calc_fee_type';
export const dbConstUtilizationMax = 'utilization_max';
export const dbConstUtilizationMin = 'utilization_min';
export const dbConstLoanId = 'loan_id';
export const dbConstRecordSource = 'record_source';
export const dbConstHistoryComments = 'history_comments';
export const dbConstReinstatedDate = 'reinstated_date';
export const dbConstSchedComments = 'sched_comments';
export const dbConstLastPaymentDate = 'last_payment_date';
export const dbConstAssetTypeFr = 'asset_type_fr';
export const dbConstAssetTypeMr = 'asset_type_mr';
export const dbConstAssetTypeSpr = 'asset_type_spr';
export const dbConstUpperCaseFitchRating = 'FITCH_RATING';
export const dbConstUpperCaseMoodysRating = 'MOODY_RATING';
export const dbConstUpperCaseSPRating = 'SP_RATING';
export const dbConstIssueRating = 'issue_rating';
export const dbConstIssueRatingDesignation = 'issue_rating_designation';
export const dbConstWrittenDownAmount = 'written_down_amount';
export const dbConstWrittenUpAmount = 'written_up_amount';
export const dbConstOustandingAmountAsOfTransDate = 'oustanding_amount_as_of_trans_date';
export const dbConstAccountReportOrder = 'account_report_order';
export const dbConstWireToInstructions = 'wire to instructions';
export const dbConstAccountType = 'account_type';
export const dbConstAccountAlias = 'Account_Alias';
export const dbConstCurrencyDenominated = 'currency_denominated';
export const dbConstLookupCodeDesc = 'lookup_code_desc';
export const dbConstLookupCode = 'lookup_code';
export const dbConstMultipleProceeds = 'multiple_proceeds';
export const dbConstDefaultMappingExists = 'default_mapping_exists';
export const dbConstMultipleAccounts = 'multiple_accounts';
export const dbConstPrincProceedsAmt = 'princ_proceeds_amt';
export const dbConstMiscProceedsAmt = 'misc_proceeds_amt';
export const dbConstCashRemaining = 'cash_remaining';
export const dbConstIntProceedsAmt = 'int_proceeds_amt';
export const dbConstReinvestableProceedsType = 'reinvestable_proceeds_type';
export const dbConstProceedsAttribute = 'proceeds_attribute';
export const dbConstTransCurrencyDesc = 'trans_currency_desc';
export const dbConstUserModifAccMap = 'user_modif_acc_map';
export const dbConstProceedsType = 'proceeds_type';
export const dbConstBankName = 'Bank Name';
export const dbConstInitialFundingAmount = 'initial_funding_amount';
export const dbConstDueDateMethod = 'due_date_method';
export const dbConstDueDateNDays = 'due_date_n_days';
export const dbConstDueDateNbdAdjustment = 'due_date_nbd_adjustment';
export const dbConstSchedFirstReportDate = 'sched_first_report_date';
export const dbConstSchedFirstPaymentDate = 'sched_first_payment_date';
export const dbConstReportFrequency = 'report_frequency';
export const dbConstReportDateNbdAdjustment = 'report_date_nbd_adjustment';
export const dbConstPaymentDateNbdAdjustment = 'payment_date_nbd_adjustment';
export const dbConstDetermDateNbdAdjustment = 'determ_date_nbd_adjustment';
export const dbConstDetermDateNDays = 'determ_date_n_days';
export const dbConstDetermDateMethod = 'determ_date_method';
export const dbConstReqReserveAmount = 'req_reserve_amount';
export const dbConstReqReservePercent = 'req_reserve_percent';
export const dbConstReserveRequirement = 'reserve_requirement';
export const dbConstInvestmentRestrictions = 'investment_restrictions';
export const dbConstActiveAccount = 'active_account';
export const dbConstUnfundedCommitmentReserveFlag = 'unfunded_commitment_reserve_flag';
export const dbConstIncludeInAccountSummary = 'include_in_account_summary';
export const dbConstAccountComments = 'account_comments';
export const dbConstReconSummaryAccount = 'recon_summary_account';
export const dbConstTradeCounterpartyString = 'trade_counterparty_string';
export const dbConstTransAccruedIntType = 'trans_accrued_int_type';
export const dbConstTransAccruedIntBalance = 'trans_accrued_int_balance';
export const dbConstOutstandingAmountTraded = 'outstanding_amount_traded';
export const dbConstOutstandingAmountSettled = 'outstanding_amount_settled';
export const dbConstPriorFactor = 'prior_factor';
export const dbConstRuleName = 'rule_name';
export const dbConstRuleComments = 'rule_comments';
export const dbConstSavedPrincipalParAmount = 'saved_principal_par_amount';
export const dbConstExpSettleDate = 'exp_settle_date';
export const dbConstPrincipalParAmount = 'principal_par_amount';
export const dbConstPaydownAllocationPikMethod = 'paydown_allocation_pik_method';
export const dbConstSavedPrincipalAmount = 'saved_principal_amount';
export const dbConstIssueRatingComments = 'issue_rating_comments';
export const dbConstIssueGtComments = 'issuegt_comments';
export const dbConstIssueGlobalTransId = 'issue_global_trans_id';
export const dbConstFeePaymentId = 'fee_payment_id';
export const dbConstNewFacilityId = 'new_facility_id';
export const dbConstRsId = 'rs_id';
export const dbConstTransactionId = 'transaction_id';
export const dbConstDerivativeDescription = 'derivative_description';
export const dbConstTransCashAmount = 'trans_cash_amount';
export const dbConstCurrentCoupon = 'current_coupon';
export const dbConstCurrentCouponDate = 'current_coupon_date';
export const dbConstCurrentFactor = 'current_factor';
export const dbConstCurrentParAmount = 'current_par_amount';
export const dbConstCurrentParAmountLong = 'current_par_amount_long';
export const dbConstMarketValueIssue = 'market_value_issue';
export const dbConstCurrentFactorDate = 'current_factor_date';
export const dbConstOriginalFaceAmount = 'original_face_amount';
export const dbConstTradeAccruedIntType = 'trade_accrued_int_type';
export const dbConstIssueTransPrice = 'issue_trans_price';
export const dbConstTransFactor = 'trans_factor';
export const dbConstCurrentSpread = 'current_spread';
export const dbConstCurrentIndex = 'current_index';
export const dbConstEntityType = 'entity_type';
export const dbConstJurisdictionCodes = 'jurisdiction_codes';
export const dbConstIssueIdOut = 'issue_id_out';
export const dbConstIssueCurrency = 'issue_currency';
export const dbConstHasSubEntities = 'has_sub_entities';
export const dbConstSystemEntityType = 'system_entity_type';
export const dbConstSubEntityName = 'sub_entity_name';
export const dbConstFacilityRating = 'facility_rating';
export const dbConstFacilityRatingDesignation = 'facility_rating_designation';
export const dbConstCurrencyCodeDesc = 'currency_code_desc';
export const dbConstPIKAmountSystemStartDate = 'pik_amount_system_start_date';
export const dbConstFacilityTypeDesc = 'facility_type_desc';
export const dbConstIndexTypeDescription = 'index_type_description';
export const dbConstIamIdDescription = 'iam_id_description';
export const dbConstIntSpread = 'int_spread';
export const dbConstCurrentSpreadDate = 'current_spread_date';
export const dbConstExchRateOptCurrency = 'exch_rate_opt_currency';
export const dbConstExchRateBaseCurrency = 'exch_rate_base_currency';
export const dbConstBorrowingLimitOptCurrency = 'borrowing_limit_opt_currency';
export const dbConstBorrowingLimitBaseCurrency = 'borrowing_limit_base_currency';
export const dbConstFirstIntRateResetDate = 'first_int_rate_reset_date';
export const dbConstInterestRateType = 'interest_rate_type';
export const dbConstLoanIndexType = 'loan_index_type';
export const dbConstMlaCost = 'mla_cost';
export const dbConstCurrentInterestRate = 'current_interest_rate';
export const dbConstFirstAccrualPeriodBeginDate = 'first_accrual_period_begin_date';
export const dbConstFirstAccrualPeriodEndDate = 'first_accrual_period_end_date';
export const dbConstEndDate = 'end_date';
export const dbConstOriginalAmount = 'original_amount';
export const dbConstIntPaymentPeriodDesc = 'int_payment_period_desc';
export const dbConstIndexTypeDesc = 'index_type_desc';
export const dbConstLoanCode = 'loan_code';
export const dbConstLoanCurrency = 'loan_currency';
export const dbConstOriginalAmountBc = 'original_amount_bc';
export const dbConstExchRateLc = 'exch_rate_lc';
export const dbConstExchRateBc = 'exch_rate_bc';
export const dbConstLoanFxFactor = 'loan_fx_factor';
export const dbConstSubjectToIndexLimit = 'subject_to_index_limit';
export const dbConstOriginalContractIndex = 'original_contract_index';
export const dbConstFacilityRatingComments = 'facility_rating_comments';
export const dbConstLoanExchangeRateString = 'loan_exchange_rate_string';
export const dbConstCscParameters = 'csc_parameters';
export const dbConstCscParameterValues = 'csc_parameter_values';
export const dbConstCscDescription = 'csc_description';
export const dbConstTestCscId = 'test_csc_id';
export const dbConstTradeLoanAmountBc = 'trade_loan_amount_bc';
export const dbConstTradeLoanAmount = 'trade_loan_amount';
export const dbConstBaseCurrency = 'base_currency';
export const dbConstScheduleBeginDate = 'schedule_begin_date';
export const dbConstBeginDate = 'begin_date';
export const dbConstCurrentIntPaymentPeriod = 'current_int_payment_period';
export const dbConstNonBaseCurrencyDesc = 'non_base_currency_desc';
export const dbConstBaseCurrencyDesc = 'base_currency_desc';
export const dbConstPaymentScheduleString = 'payment_schedule_string';
export const dbConstValue = 'value';
export const dbConstTransDesc = 'trans_desc';
export const dbConstFitemId = 'fitem_id';
export const dbConstTransLoanAmountLc = 'trans_loan_amount_lc';
export const dbConstTransLoanAmountBc = 'trans_loan_amount_bc';
export const dbConstValueCount = 'value_count';
export const dbConstLineDescription = 'line_description';
export const dbConstParameterNumber = 'parameter_number';
export const dbConstTransAmount = 'trans_amount';
export const dbConstPitDate = 'pit_date';
export const dbConstPitFacilityGlobalTransId = 'pit_facility_global_trans_id';
export const dbConstPikFacilityGlobalTransId = 'pik_facility_global_trans_id';
export const dbConstPikRate = 'pik_rate';
export const dbConstAllocationMethod = 'allocation_method';
export const dbConstAllocationBalanceType = 'allocation_balance_type';
export const dbConstPointInTime = 'point_in_time';
export const dbConstLookupTypeCat = 'lookup_type_cat';
export const dbConstParameterType = 'parameter_type';
export const dbConstLineValue = 'line_value';
export const dbConstLineType = 'line_type';
export const dbConstDerivativeId = 'derivative_id';
export const dbConstBloombergUniqueIdUdf = 'bloomberg_unique_id_udf';
export const dbConstTickerSymbol = 'ticker_symbol';
export const dbConstIsAlreadyAdded = 'is_already_added';
export const dbConstVrtsCreditAgreementId = 'vrts_credit_agreement_id';
export const dbConstGlobalSize = 'global_size';
export const dbConstIsBase = 'is_base';
export const dbConstPikPercent = 'pik_percent';
export const dbConstPricingGridName = 'pricing_grid_name';
export const dbConstPricingType = 'pricing_type';
export const dbConstYLabelDescription = 'y_label_description';
export const dbConstVrtsCreditAgreementPricingGridId = 'vrts_credit_agreement_pricing_grid_id';
export const dbConstAgentConsentRequired = 'agent_consent_required';
export const dbConstAmendedIndicator = 'amended_indicator';
export const dbConstAssignmentIncrements = 'assignment_increments';
export const dbConstBorrowerConsentRequired = 'borrower_consent_required';
export const dbConstBorrowerConsentTimeframeDays = 'borrower_consent_timeframe_days';
export const dbConstBuisDayRuleCode = 'buis_day_rule_code';
export const dbConstClosingDt = 'closing_dt';
export const dbConstCreditAgreementName = 'credit_agreement_name';
export const dbConstCusip = 'cusip';
export const dbConstEffectiveDt = 'effective_dt';
export const dbConstMaturityDt = 'maturity_dt';
export const dbConstMinimumAssignmentAmount = 'minimum_assignment_amount';
export const dbConstRefinanceIndicator = 'refinance_indicator';
export const dbConstPointInTimeFgtid = 'point_in_time_fgtid';
export const dbConstRestatedIndicator = 'restated_indicator';
export const dbConstSchedAdjustmentMethod = 'sched_adjustment_method';
export const dbConstSyndicateCountry = 'syndicate_country';
export const dbConstVrtsCreditAgreementParentId = 'vrts_credit_agreement_parent_id';
export const dbConstTransType = 'trans_type';
export const dbConstTransDate = 'trans_date';
export const dbConstTransTypeDesc = 'trans_type_desc';
export const dbConstNetCommitmentAmount = 'net_commitment_amount';
export const dbConstAssignable = 'assignable';
export const dbConstAssignableOutsideSyndication = 'assignable_outside_syndication';
export const dbConstNextPaymentDate = 'next_payment_date';
export const dbConstBorrowowingIncrements = 'borrowing_increments';
export const dbConstBorrowingIncrementsPrime = 'borrowing_increments_prime';
export const dbConstBorrowingMinimum = 'borrowing_minimum';
export const dbConstBorrowingMinimumPrime = 'borrowing_minimum_prime';
export const dbConstEvergreenRevolverIndicator = 'evergreen_revolver_indicator';
export const dbConstGracePeriod = 'grace_period';
export const dbConstIsPrepaymentPenaltyApplicable = 'is_prepayment_penalty_applicable';
export const dbConstIsProrataIndicator = 'is_prorata_indicator';
export const dbConstOriginalCommitmentAmount = 'original_commitment_amount';
export const dbConstFinalCommitmentAmount = 'final_commitment_amount';
export const dbConstAvailableCommitmentAmount = 'available_commitment_amount';
export const dbConstTermoutRevolverConversionDate = 'termout_revolver_conversion_date';
export const dbConstTermoutRevolverConversionindicator = 'termout_revolver_conversion_indicator';
export const dbConstStringConfig = 'string_config';
export const dbConstConfigString = 'config_string';
export const dbConstFitchCode = 'issuer_rating_type_fitch_lkup_code';
export const dbConstMoodysCode = 'issuer_rating_type_moody_lkup_code';
export const dbConstLotCount = 'lot_count';
export const dbConstUnavailableLotCount = 'unavailable_lot_count';
export const dbConstSpCode = 'issuer_rating_type_sp_lkup_code';
export const dbConstIsBusinessDate = 'is_business_date';
export const dbConstPersistentContract = 'persistent_contract';
export const dbConstIntRateResetPeriodAsOfDate = 'int_rate_reset_period_as_of_date';
export const dbConstCurrentMlaCost = 'current_mla_cost';
export const dbConstIntPaymentPeriodAsOfDate = 'int_payment_period_as_of_date';
export const dbConstClosingDate = 'closing_date';
export const dbConstPaymentFrequency = 'payment_frequency';
export const dbConstBusinessDate = 'business_date';
export const dbConstPositionType = 'position_type';
export const dbConstWizardMode = 'wizard_mode';
export const dbConstPrepaymentGlobalTransId = 'prepayment_global_trans_id';
export const dbConstIntPaymentType = 'int_payment_type';
export const dbConstWizardSource = 'wizard_source';
export const dbConstIsSinglePage = 'is_single_page';
export const dbConstIssueTransComments = 'issue_trans_comments';
export const dbConstTransCurrency = 'trans_currency';
export const dbConstDateDesc = 'date_desc';
export const dbConstCalcPaymentAmount = 'calc_payment_amount';
export const dbConstPositionTypeDesc = 'position_type_desc';
export const dbConstRdRuleDesc = 'rd_rule_desc';
export const dbConstRdRuleType = 'rd_rule_type';
export const dbConstReferenceRatingType = 'reference_rating_type';
export const dbConstReferenceSecurityLevel = 'reference_security_level';
export const dbConstReferenceRatingAgency = 'reference_rating_agency';
export const dbConstReferenceIssuerRatingType = 'reference_issuer_rating_type';
export const dbConstRefRatingMin = 'ref_rating_min';
export const dbConstRefRatingMax = 'ref_rating_max';
export const dbConstRefRatingAdjustment = 'ref_rating_adjustment';
export const dbConstRdsNumber = 'rds_number';
export const dbConstRefRatingAdjMax = 'ref_rating_adj_max';
export const dbConstDeterminationDate = 'determination_date';
export const dbConstSchedTermDt = 'sched_term_dt';
export const dbConstDueDate = 'due_date';
export const dbConstRefRatingAdjMin = 'ref_rating_adj_min';
export const dbConstRefRatingDowngrdAdj = 'ref_rating_downgrd_adj';
export const dbConstRefRatingUpgradeAdj = 'ref_rating_upgrade_adj';
export const dbConstMultipleRatingCalcMethod = 'multiple_rating_calc_method';
export const dbConstMultipleRatingRoundingMethod = 'multiple_rating_rounding_method';
export const dbConstApplicabilityRule = 'applicability_rule';
export const dbConstRdRuleComments = 'rd_rule_comments';
export const dbConstrating = 'rating';
export const dbConstRulePriority = 'rule_priority';
export const dbConstSubEntityId = 'sub_entity_id';
export const dbConstTaDesc = 'ta_desc';
export const dbConstCalcPikAmount = 'calc_pik_amount';
export const dbConstDealInterestAmount = 'deal_interest_amount';
export const dbConstLoanDesc = 'loan_desc';
export const dbConstIssueCategory = 'issue_category';
export const dbConstDealPikAmount = 'deal_pik_amount';
export const dbConstCalcDealPikAmount = 'calc_deal_pik_amount';
export const dbConstSavedCalcDealPikAmount = 'saved_calc_deal_pik_amount';
export const dbConstCreateDate = 'create_date';
export const dbConstCreatedBy = 'created_by';
export const dbConstLastUpdateDate = 'last_update_date';
export const dbConstLastUpdatedBy = 'last_updated_by';
export const dbConstFieldValue = 'field_value';
export const dbConstFieldId = 'field_id';
export const dbConstFieldDesc = 'field_desc';
export const dbConstValueType = 'value_type';
export const dbConstFieldValueDesc = 'field_value_desc';
export const dbConstOverrideExistingValue = 'override_existing_value';
export const dbConstCurrentXref = 'current_xref';
export const dbConstUniqueId = 'unique_id';
export const dbConstPbrgId = 'pbrg_id';
export const dbConstPbCalcMethod = 'pb_calc_method';
export const dbConstRsElectionId = 'rs_election_id';
export const dbConstRestructureName = 'restructure_name';
export const dbConstPrice = 'price';
export const dbConstFacilityTransId = 'facility_trans_id';
export const dbConstMarketValueEquity = 'market_value_equity';
export const dbConstRdRuleId = 'rd_rule_id';
export const dbConstRdsId = 'rds_id';
export const dbConstFacilityTradeFeeId = 'facility_trade_fee_id';
export const dbConstTradingScenario = 'trading_scenario';
export const dbConstCashAllocationString = 'cash_allocation_string';
export const dbConstAllocateOnly = 'allocate_only';
export const dbConstApplyCash = 'apply_cash';
export const dbConstFTradeId = 'ftrade_id';
export const dbConstCurrentUnitAmountShort = 'current_unit_amount_short';
export const dbConstDividendType = 'dividend_type';
export const dbConstCompTransType = 'comp_trans_type';
export const dbConstMultiplierValue = 'multiplier_value';
export const dbConstNumberOfUnits = 'number_of_units';
export const dbConstStratId = 'strat_id';
export const dbConstItemList = 'item_list';
export const dbConstOutstandingAmountAvailable = 'outstanding_amount_available';
export const dbConstInterestAmountIssueCurrency = 'interest_amount_issue_currency';
export const dbConstUnitPrice = 'unit_price';
export const dbConstPrincipalAmountIssueCurrency = 'principal_amount_issue_currency';
export const dbConstTradeCode = 'trade_code';
export const dbConstCurrentUnitAmountLong = 'current_unit_amount_long';
export const dbConstSeparateCashTransaction = 'separate_cash_transaction';
export const dbConstTradeDate = 'trade_date';
export const dbConstXrefId = 'xref_id';
export const dbConstItComments = 'it_comments';
export const dbConstTrancheString = 'tranche_string';
export const dbConstcompanyName = 'company_name';
export const dbConstAgentType = 'agent_type';
export const dbConstContactId = 'contact_id';
export const dbConstClassId = 'class_id';
export const dbConstGroupId = 'group_id';
export const dbConstXAxis = 'x_axis';
export const dbConstYAxis = 'y_axis';
export const dbConstClientAssetId = 'client_asset_id';
export const dbConstCouterpartyId = 'counterparty_id';
export const dbConstToPaymentCurrency = 'to_payment_currency';
export const dbConstFromPaymentCurrency = 'from_payment_currency';
export const dbConstToPaymentCurrencyDesc = 'to_payment_currency_desc';
export const dbConstFromPaymentCurrencyDesc = 'from_payment_currency_desc';
export const dbConstGlobalAssetName = 'global_asset_name';
export const dbConstHedgeType = 'hedge_type';
export const dbConstHedgeTypeDesc = 'hedge_type_desc';
export const dbConstReferenceNumber = 'reference_number';
export const dbConstTerminationDate = 'termination_date';
export const dbConstInitialNotionalAmount = 'initial_notional_amount';
export const dbConstFromInitialNotionalAmount = 'from_initial_notional_amount';
export const dbConstToInitialNotionalAmount = 'to_initial_notional_amount';
export const dbConstOutstandingNotionalAmount = 'outstanding_notional_amount';
export const dbConstEarlyTerminProvisions = 'early_termin_provisions';
export const dbConstNbdAdjustment = 'nbd_adjustment';
export const dbConstExchPrincipalAtTermination = 'exch_principal_at_termination';
export const dbConstHedgeComments = 'hedge_comments';
export const dbConstNotionalReductionAccruedIntType = 'notional_reduction_accrued_int_type';
export const dbConstFromCoupanType = 'from_coupon_type';
export const dbConstFromHpComments = 'from_hp_comments';
export const dbConstFromIamId = 'from_iam_id';
export const dbConstFxTradeId = 'fx_trade_id';
export const dbConstTradeCurrency = 'trade_currency';
export const dbConstFxTradeComments = 'fx_trade_comments';
export const dbConstTradeAmount = 'trade_amount';
export const dbConstExchangeRateTradeCurrency = 'exchange_rate_trade_currency';
export const dbConstExchangeRateTransCurrency = 'exchange_rate_trans_currency';
export const dbConstTradeCashAccountAlloc = 'trade_cash_account_alloc';
export const dbConstTransCashAccountAlloc = 'trans_cash_account_alloc';
export const dbConstIntRateCalcMethod = 'int_rate_calc_method';
export const dbConstLookbackDays = 'lookback_days';
export const dbConstObservationShift = 'observation_shift';
export const dbConstAcrPrecision = 'acr_precision';
export const dbConstCompoundingmethod = 'compounding_method';
export const dbConstSpreadAdjustment = 'spread_adjustment';
export const dbConstIsRFR = 'is_RFR';
export const dbConstRowId = 'row_id';
