import React from 'react';
import { PageMessageIconType } from 'util/enums/PageMessageIconTypeEnum';
import { AlertModal } from './AlertModal';

interface IProps {
  alertMessages: string[];
  onCloseModal: any;
  iconType?: PageMessageIconType;
  headerText?: string;
  allowCopy?: boolean;
}

interface IState {
  currentIndex: number;
  isModalOpen: boolean;
}

export default class MultipleAlertModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentIndex: 0,
      isModalOpen: this.props.alertMessages.length > 0 ? true : false,
    };
  }

  public render() {
    let headerText;
    if (this.props.iconType === PageMessageIconType.Information) {
      headerText = 'Information';
    }
    if (this.props.headerText) {
      headerText = this.props.headerText;
    }
    return (
      <AlertModal
        isModalOpen={this.state.isModalOpen}
        alertMessage={this.props.alertMessages[this.state.currentIndex]}
        onCloseModal={this.onAlertModalClose}
        iconType={this.props.iconType || PageMessageIconType.Warning}
        headerText={headerText}
        allowCopy={this.props.allowCopy}
      />
    );
  }

  private onAlertModalClose = async () => {
    await this.setState({
      ...this.state,
      isModalOpen: false,
    });
    const index = this.state.currentIndex;

    if (this.props.alertMessages.length > index + 1) {
      setTimeout(() => {
        this.setState({
          ...this.state,
          currentIndex: this.state.currentIndex + 1,
          isModalOpen: true,
        });
      }, 100);
    } else {
      this.props.onCloseModal();
    }
  };
}
