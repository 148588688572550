import DropDownControlSqlObject from 'components/DropDown/DropDownControlSql/DropDownControlSqlObject';
import { MethodType } from 'components/DropDown/MethodType';
import React, { memo } from 'react';
import { shallowCompare } from 'util/helpers/helperFunctions';

export interface IProps {
  value: string;
  name?: string;
  isReadonly?: boolean;
  onChange?: (event: any) => void;
  codeType: string;
  codeAttributeString?: string;
  groupCode?: string;
  selectOption?: string;
  selectOptionValue?: string;
  isRequired?: boolean;
  isValid?: boolean;
  validationMessage?: string;
  style?: any;
  useFilter?: string;
  useGroupFilter?: string;
  isDisabled?: boolean;
  hideValiationControl?: boolean;
  getDefaultValue?: (value: any, name?: string, data?: any) => void;
  getInitialSelectedText?: (selectedText: string) => void;
  dataTestId?: string;
  allowLiteralNull?: boolean;
}

export const DropDownControlCodeTableObject = (props: IProps) => {
  return props.codeType ? (
    <DropDownControlSqlObject
      value={props.value}
      name={props.name}
      dataTestId={props.dataTestId}
      isReadonly={props.isReadonly}
      displayField="description"
      valueField="code"
      onChange={props.onChange}
      methodType={MethodType.Get}
      methodRoute={
        'FacilityTrades/GetCodes/' +
        props.codeType +
        (props.groupCode !== undefined ? '?groupCode=' + props.groupCode : '') +
        (props.codeAttributeString !== undefined ? '?codeAttributeString=' + props.codeAttributeString : '') +
        (props.useFilter !== undefined ? '&useFilter=' + props.useFilter : '') +
        (props.useGroupFilter !== undefined ? '&useGroupFilter=' + props.useGroupFilter : '')
      }
      selectOption={props.selectOption}
      selectOptionValue={props.selectOptionValue}
      isDisabled={props.isDisabled}
      isRequired={props.isRequired}
      isValid={props.isValid}
      validationMessage={props.validationMessage}
      style={props.style}
      hideValidationControl={props.hideValiationControl}
      getDefaultValue={props.getDefaultValue}
      getInitialSelectedText={props.getInitialSelectedText}
      allowLiteralNull={props.allowLiteralNull}
    />
  ) : (
    <></>
  );
};

function arePropsEqual(prevProps: IProps, nextProps: IProps) {
  const oldProps = {
    ...prevProps,
  };
  const newProps = {
    ...nextProps,
  };
  if ('style' in oldProps) {
    delete oldProps.style;
  }
  if ('style' in newProps) {
    delete newProps.style;
  }

  if ('getDefaultValue' in oldProps) {
    delete oldProps.getDefaultValue;
  }
  if ('getDefaultValue' in newProps) {
    delete newProps.getDefaultValue;
  }

  if ('onChange' in oldProps) {
    delete oldProps.onChange;
  }
  if ('onChange' in newProps) {
    delete newProps.onChange;
  }
  return shallowCompare(oldProps, newProps);
}

export default memo(DropDownControlCodeTableObject, arePropsEqual);
