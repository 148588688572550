import AddControlRow from 'components/AddControlRow';
import DropDownControlSqlObject, { IProps } from 'components/DropDown/DropDownControlSql/DropDownControlSqlObject';
import React, { memo } from 'react';
import { shallowCompare } from 'util/helpers/helperFunctions';
import { MethodType } from '../MethodType';

export interface IProp extends IProps {
  label: string;
  showAddModalLabel?: boolean;
}

function DropdownControlSqlRow(props: IProp) {
  const component = <DropDownControlSqlObject {...props} validationMessage={'Value for "' + props.label + '" is required'} />;
  return (
    <AddControlRow
      validationColor={props.isValid === false ? 'red' : 'white'}
      component={component}
      showAddModalLabel={props.showAddModalLabel}
      description={props.label}
      isReadOnly={props.isReadonly ? props.isReadonly : false}
      isRequired={props.isRequired ? props.isRequired : false}
    />
  );
}

function arePropsEqual(prevProps: IProp, nextProps: IProp) {
  const oldProps = {
    ...prevProps,
  };
  const newProps = {
    ...nextProps,
  };
  if ('onChange' in oldProps) {
    delete oldProps.onChange;
  }
  if ('onChange' in newProps) {
    delete newProps.onChange;
  }
  if (oldProps.methodType === newProps.methodType) {
    if (oldProps.methodType === MethodType.Post) {
      if (!shallowCompare(oldProps.parameters, newProps.parameters)) {
        return false;
      } else {
        delete oldProps.parameters;
        delete newProps.parameters;
      }
    }
  }
  if ('onAddModalLabelClick' in oldProps) {
    delete oldProps.onAddModalLabelClick;
  }
  if ('onAddModalLabelClick' in newProps) {
    delete newProps.onAddModalLabelClick;
  }

  if ('onModalSave' in oldProps) {
    delete oldProps.onModalSave;
  }
  if ('onModalSave' in newProps) {
    delete newProps.onModalSave;
  }

  if ('onAddClick' in oldProps) {
    delete oldProps.onAddClick;
  }
  if ('onAddClick' in newProps) {
    delete newProps.onAddClick;
  }

  if ('onEditClick' in oldProps) {
    delete oldProps.onEditClick;
  }
  if ('onEditClick' in newProps) {
    delete newProps.onEditClick;
  }

  if ('style' in oldProps) {
    delete oldProps.style;
  }
  if ('style' in newProps) {
    delete newProps.style;
  }

  if ('getDefaultValue' in oldProps) {
    delete oldProps.getDefaultValue;
  }
  if ('getDefaultValue' in newProps) {
    delete newProps.getDefaultValue;
  }
  return shallowCompare(oldProps, newProps);
}

export default memo(DropdownControlSqlRow, arePropsEqual);
