import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';

const styles = () =>
  createStyles({
    multilineColor: {
      color: 'var(--text)',
      fontSize: 'var(--main-font-size)',
      paddingLeft: '6px',
    },
    textField: {
      backgroundColor: '#28333c',
      minWidth: '120px',
      width: '25vw',
    },
  });

export interface IProps extends WithStyles<typeof styles> {
  name: string;
  value?: string;
  rows?: number;
  isReadonly?: boolean;
  height?: string;
  color?: string;
  onChange?: (event: any) => void;
  style?: {};
}

const TextArea = (props: IProps) => {
  const { classes } = props;
  const background = props.isReadonly ? 'transparent' : '';
  const textFieldStyle = {
    backgroundColor: background,
    ...props.style,
    padding: '0px',
    paddingTop: '6px',
  };
  const inputPropsStyle: any = {};
  if (props.height) {
    inputPropsStyle.height = props.height;
  }
  if (props.color) {
    inputPropsStyle.color = props.color;
  }
  return (
    <TextField
      name={props.name}
      value={props.value ? props.value : ''}
      rows={props.rows !== undefined ? props.rows : 4}
      multiline={true}
      onChange={props.onChange}
      className={`${props.classes.textField} virtus-text-area`}
      InputProps={{
        classes: {
          input: classes.multilineColor,
        },
        disableUnderline: true,
        readOnly: props.isReadonly,
      }}
      inputProps={{ style: inputPropsStyle }}
      style={textFieldStyle}
    />
  );
};

export default withStyles(styles)(TextArea);
