import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const HelpIcon = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H16V16H0z" />
      <path
        fill="#FFF"
        d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm.8 13.6H7.2V12h1.6v1.6zm1.656-6.2l-.72.736C9.16 8.72 8.8 9.2 8.8 10.4H7.2V10c0-.88.36-1.68.936-2.264l.992-1.008C9.424 6.44 9.6 6.04 9.6 5.6 9.6 4.72 8.88 4 8 4c-.88 0-1.6.72-1.6 1.6H4.8c0-1.768 1.432-3.2 3.2-3.2 1.768 0 3.2 1.432 3.2 3.2 0 .704-.288 1.344-.744 1.8z"
      />
    </g>
  </SvgIcon>
);
