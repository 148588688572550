import { getAccessToken, logout } from '@virtus/common/auth/msalWrapper';
import { SchemaType } from '@virtus/components/DxDataGrid/DxDataGrid';
import { SET_DEAL_LIST } from 'actions/dealActionTypes';
import { SHOW_ERROR } from 'actions/ErrorActions/errorActionTypes';
import { SET_READONLY_HEADER } from 'actions/globalActions';
import { SET_SIGNALR_DATA } from 'actions/signalRActions';
import axios from 'axios';
import { MethodType } from 'components/DropDown/MethodType';
import config from 'config/index';
import {
  dbConstActionMessage,
  dbConstActionMessageCode,
  dbConstOperationDetails,
  dbConstOperationMessage,
  dbConstOperationMessageCode,
  dbConstOperationResultCode,
} from 'constants/DbConstants';
import { GET, POST } from 'coreservices/CoreServicesClient';
import SignalRConnector, { networkInterface } from 'coreservices/SignalRConnector';
import numeral from 'numeral';
import store from 'stores/configureStore';
import { getTrimmedValue, isStringNullOrWhitespace, showToastNotification } from 'util/helpers/helperFunctions';

const expiredTokenMessage = 'Your session has expired, please login again';
let authToken = '';

export const getAuthToken = async () => {
  return authToken ? authToken : `${await getToken()}`;
};

let signalRClientId: string;

const axiosInstance = axios.create({
  baseURL: config.apiUrl,
  headers: {
    Authorization: '',
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
    'x-cdomodern-username': config.appName,
    'x-load-schema': false,
    'x-signalrclientid': config.signalrClientId,
    'x-dataenvironment': config.dataEnvironment,
  },
});

export const setUserName = (userName: string) => {
  getToken().then(async (response) => {
    if (response !== '') {
      authToken = response;
      axiosInstance.defaults.headers['x-cdomodern-username'] = userName;
      axiosInstance.defaults.headers['x-use-read-only'] = true;
      axiosInstance.defaults.headers['x-load-schema'] = false;
      axiosInstance.defaults.headers['x-usesignalr'] = false;
      axiosInstance.defaults.headers['x-sql-timeout'] = 1800;
      axiosInstance.defaults.headers.Authorization = 'Bearer ' + authToken;
      axiosInstance.defaults.timeout = 300000;
    }
  });
};

// Response
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errorMessage = handleError(error);
    return Promise.reject(errorMessage);
  },
);

const handleError = (error: any) => {
  let message = 'Something went wrong.';

  // Setup Error Message
  if (typeof error !== 'undefined') {
    if (error.hasOwnProperty('message')) {
      message = error.message;
    }
  }

  if (typeof error.response !== 'undefined') {
    // Setup Generic Response Messages
    if (error.response.status === 400) {
      message = 'Request Failed due to Invalid Parameters. ' + error.response.request.responseText;
    } else if (error.response.status === 401) {
      message = expiredTokenMessage;
      setTimeout(() => {
        logout();
      }, 3000);
    } else if (error.response.status === 404) {
      message = 'API Route is Missing or Undefined. ' + error.response.request.responseURL;
    } else if (error.response.status === 405) {
      message = 'API Route Method Not Allowed';
    } else if (error.response.status === 500) {
      message = getDBErrorMessage(error);
    } else if (error.response.status > 500) {
      message = 'Server Error';
    }
  }
  return message;
};

const getDBErrorMessage = (error: any) => {
  let message = 'Server Error';

  try {
    if ('response' in error) {
      if ('data' in error.response) {
        let errorString: string = `{${error.response.data}}`;
        errorString = errorString.replace(errorString.charAt(0), '');
        errorString = errorString.replace(errorString.charAt(errorString.length - 1), '');
        const errorObj = JSON.parse(errorString);
        if (errorObj.errorMessage) {
          message = `Internal Server Error - ${errorObj.errorMessage}`;
        } else if (typeof errorObj === 'string') {
          message = `Internal Server Error - ${errorObj}`;
        } else if (typeof errorObj === 'object') {
          message = `Invalid SQL statement: `;
          if ('Detail' in errorObj) {
            message += `${errorObj.Detail ? errorObj.Detail.split('\r') : errorObj.Detail} `;
          } else {
            for (const err of Object.keys(errorObj)) {
              message += `${errorObj[err]} `;
            }
          }
        }
      }
    }
  } catch (exception) {
    //
  }
  return message + '<br/><br/> Please contact system administrator.';
};

export interface IDataObject {
  schema: Array<{
    BaseColumnName: string;
    ColumnName: string;
    ColumnSize: number;
    DataTypeName: string;
    NumericPrecision: number;
    NumericScale: number;
    Format?: string;
    Width?: number;
    Hidden?: boolean;
    IsReadOnly?: boolean;
    Caption?: string;
    DataField?: string;
    ColumnOrder?: number;
  }>;
  data: any[];
  schemaType?: SchemaType;
  sqlCommandText?: string;
  lookupDataList?: any;
}

async function getToken() {
  let accessToken: string;
  try {
    accessToken = await getAccessToken();
  } catch (error) {
    accessToken = '';
  }

  return accessToken;
}

export interface IResponseObject {
  arrDataObjects: IDataObject[]; // The array of all the data objects received
  hasError: boolean; // Flag to show error
  errorMessage: string; // Error message if received otherwise false
  requestStatusCode?: number;
  requestMessage?: any;
  dataObject: IDataObject; // data object received (in case of multiple, it shows the data object at 0th index)
  hasConfirmationMessage: boolean; // Flag to show confirmation message
  hasMultipleDataObjects: boolean; // Flag to show multiple data objects
  confirmationMessage: string[]; // array of string containing the confirmation message to be displayed.
}

export interface IGetParameterObject {
  name: string;
  value: string | number | boolean;
  sequence: number;
  isOptional?: boolean;
}

export async function saveData(controllerName: string, methodName: string, parameters: any, displayError?: boolean): Promise<IResponseObject> {
  const confirmationMessage: any = [];
  const methodRoute: string = `${controllerName}/${methodName}`;

  let hasConfirmationMessage = false;
  let errorMessage = '';
  let hasError = false;
  let requestStatusCode;

  let responseObject: IResponseObject = {
    arrDataObjects: [],
    confirmationMessage: [],
    dataObject: { schema: [], data: [] },
    errorMessage: '',
    hasConfirmationMessage: false,
    hasError: false,
    hasMultipleDataObjects: false,
  };

  try {
    axiosInstance.defaults.headers['x-load-schema'] = false;
    store.dispatch({ type: SET_READONLY_HEADER, data: false });
    axiosInstance.defaults.headers['x-use-read-only'] = false;
    const result = await axiosInstance.post(methodRoute, parameters);
    if (result !== undefined) {
      if (!verifyResponse(result)) {
        return Promise.reject();
      }
      requestStatusCode = 200;
      const recordSets = JSON.parse(result.data).recordSets;
      const sqlCommandText = JSON.parse(result.data).sqlCommandText;

      if (recordSets !== undefined && recordSets.length > 0) {
        const dataObject = {
          ...recordSets[0],
          sqlCommandText,
        };

        if (dataObject.data.length > 0) {
          const operationMessageCode =
            dataObject.data[0][dbConstOperationMessageCode] && dataObject.data[0][dbConstOperationMessageCode] !== null ? dataObject.data[0][dbConstOperationMessageCode] : '';
          const operationResultCode = getTrimmedValue(dataObject.data[0][dbConstOperationResultCode]);
          const operationMessage =
            dataObject.data[0][dbConstOperationMessage] && dataObject.data[0][dbConstOperationMessage] !== null ? dataObject.data[0][dbConstOperationMessage] : '';
          const operationDetails =
            dataObject.data[0][dbConstOperationDetails] && dataObject.data[0][dbConstOperationDetails] !== null ? dataObject.data[0][dbConstOperationDetails] : '';
          const actionMessage = dataObject.data[0][dbConstActionMessage] && dataObject.data[0][dbConstActionMessage] !== null ? dataObject.data[0][dbConstActionMessage] : '';
          const actionMessageCode =
            dataObject.data[0][dbConstActionMessageCode] && dataObject.data[0][dbConstActionMessageCode] !== null ? dataObject.data[0][dbConstActionMessageCode] : '';

          switch (actionMessageCode) {
            case 'ARE_YOU_SURE':
              if (!isStringNullOrWhitespace(operationMessage) && !isStringNullOrWhitespace(operationDetails)) {
                hasConfirmationMessage = true;
                confirmationMessage.push(operationMessage);
                confirmationMessage.push(operationDetails);
              } else if (!isStringNullOrWhitespace(operationMessage)) {
                hasConfirmationMessage = true;
                confirmationMessage.push(operationMessage);
              }
              break;
            default:
              if (
                (operationMessageCode && !isStringNullOrWhitespace(operationMessageCode) && getTrimmedValue(operationMessageCode) !== 'BLANK_SUCCESS') ||
                (operationMessage && !isStringNullOrWhitespace(operationMessage)) ||
                getTrimmedValue(operationDetails)
              ) {
                // Success
                if (operationResultCode !== '' && Number(operationResultCode) <= 9) {
                  hasError = false;
                } else {
                  hasError = true;
                  errorMessage = operationMessage + ' ' + (operationDetails ? operationDetails : '') + `${actionMessage ? '<br/><br/>' + actionMessage : ''}`;
                  showError(errorMessage, displayError);
                }
              }
              break;
          }

          responseObject = {
            ...responseObject,
            confirmationMessage,
            dataObject,
            errorMessage,
            hasConfirmationMessage,
            hasError,
            requestStatusCode,
          };
        }
      }
    }
  } catch (error) {
    responseObject = {
      ...responseObject,
      errorMessage: error.toString(),
      hasError: true,
    };
    showError(error.toString(), displayError);
  }

  return responseObject;
}

const fetchDataUsingSignalR = async (controllerName: string, methodName: string, methodType: MethodType, parameters?: any, details?: { key: string; message: string }) => {
  if (!details) {
    details = {
      key: 'SIGNALR',
      message: 'Signal R',
    };
  }
  let methodRoute: string = `${controllerName}/${methodName}`;
  if (methodType === MethodType.Get) {
    methodRoute = encodeURI(methodRoute + createQueryString(parameters));
  }
  if (!signalRClientId) {
    const signalRConnector = new SignalRConnector({ isUseSignalR: true });
    signalRClientId = await signalRConnector.connectToSignalR();
  }
  const networkHandler = networkInterface(methodRoute, methodType === MethodType.Post ? POST : GET, {
    body: methodType === MethodType.Post ? parameters : {},
    headers: {
      ...axiosInstance.defaults.headers,
      'x-usesignalr': true,
    },
  });
  const currentPath = window.location.href;
  store.dispatch({ type: SET_SIGNALR_DATA, data: { key: details.key, status: 'pending' } });
  return new Promise<any>((resolve) => {
    networkHandler.execute((error: any, status: any, data: any, blank: any, responseHeaders: any, reqStartTime?: Date) => {
      if (!error) {
        const endTime = new Date();
        const runningTime = reqStartTime ? Number(endTime) - Number(reqStartTime) : 0;
        const formaatedRunningTime = numeral(Math.round(runningTime / 1000)).format('00:00:00');
        const message = `Success! (ran in ${formaatedRunningTime})`;
        showToastNotification(`Data Fetched Successfully: ${details ? details.message : ''}`, 'success', currentPath, message);
        store.dispatch({ type: SET_SIGNALR_DATA, data: { key: details ? details.key : '', status: 'success', data } });
      } else {
        showToastNotification(`Data Fetch Failed: ${details ? details.message : ''}`, 'error', currentPath, error);
        store.dispatch({ type: SET_SIGNALR_DATA, data: { key: details ? details.key : '', status: 'failed', data } });
      }
      resolve(data);
    });
  });
};

export async function fetchData(
  controllerName: string,
  methodName: string,
  methodType: MethodType,
  parameters?: any,
  displayError?: boolean,
  useSignalR?: boolean,
  signalRDetails?: { key: string; message: string },
  useReadOnly: boolean = false,
): Promise<IResponseObject> {
  axiosInstance.defaults.headers['x-load-schema'] = true;
  if (useSignalR) {
    return fetchDataUsingSignalR(controllerName, methodName, methodType, parameters, signalRDetails);
  }
  // special case to read entity list from store if exists
  if (methodName === 'EntityList' && ((Object.keys(parameters).length === 1 && 'userId' in parameters) || Object.keys(parameters).length === 0)) {
    if (store.getState().dealRdcr.dealsList) {
      return store.getState().dealRdcr.dealsList;
    }
  }

  let responseObjectWithMultipleDataObjects: IResponseObject = {
    arrDataObjects: [],
    confirmationMessage: [],
    dataObject: { schema: [], data: [] },
    errorMessage: '',
    hasConfirmationMessage: false,
    hasError: false,
    hasMultipleDataObjects: false,
  };

  const methodRoute: string = `${controllerName}/${methodName}`;
  try {
    let result;
    axiosInstance.defaults.headers['x-use-read-only'] = store.getState().globalRdcr.useReadonlyHeader;
    if (useReadOnly !== undefined) {
      axiosInstance.defaults.headers['x-use-read-only'] = useReadOnly;
    }

    switch (methodType) {
      case MethodType.Get:
        const url = encodeURI(methodRoute + createQueryString(parameters));
        result = await axiosInstance.get(url);
        break;
      case MethodType.Post:
        result = await axiosInstance.post(methodRoute, parameters);
    }
    if (result !== undefined) {
      if (!verifyResponse(result)) {
        return Promise.reject();
      }
      const parsedData = JSON.parse(result.data);
      const lookupDataList = parsedData.lookupDataList;
      const recordSets = parsedData.recordSets;
      const requestMessage = parsedData.RequestMessage;
      const sqlCommandText = parsedData.sqlCommandText;
      if (recordSets !== undefined && recordSets.length > 0) {
        const arrDataObjects: IDataObject[] = [];
        recordSets.map((recordSet: any) => {
          const dataObject = {
            ...recordSet,
            lookupDataList,
            sqlCommandText,
          };
          arrDataObjects.push(dataObject);
          return recordSet;
        });

        responseObjectWithMultipleDataObjects = {
          ...responseObjectWithMultipleDataObjects,
          arrDataObjects,
          dataObject: arrDataObjects[0],
          hasMultipleDataObjects: arrDataObjects.length > 1,
        };
      } else if (requestMessage !== undefined) {
        responseObjectWithMultipleDataObjects = {
          ...responseObjectWithMultipleDataObjects,
          requestMessage,
        };
      }
    }
  } catch (error) {
    responseObjectWithMultipleDataObjects = {
      ...responseObjectWithMultipleDataObjects,
      errorMessage: error.toString(),
      hasError: true,
    };
    showError(error.toString(), displayError);
  }
  // save entity list to store
  if (methodName === 'EntityList' && ((Object.keys(parameters).length === 1 && 'userId' in parameters) || Object.keys(parameters).length === 0)) {
    store.dispatch({ type: SET_DEAL_LIST, data: responseObjectWithMultipleDataObjects });
  }
  return responseObjectWithMultipleDataObjects;
}

export async function deleteData1(controllerName: string, methodName: string, parameters: any, isRequestBodyRequired: boolean): Promise<IResponseObject> {
  let responseObject: IResponseObject = {
    arrDataObjects: [],
    confirmationMessage: [],
    dataObject: { schema: [], data: [] },
    errorMessage: '',
    hasConfirmationMessage: false,
    hasError: false,
    hasMultipleDataObjects: false,
  };
  const methodRoute: string = `${controllerName}/${methodName}`;

  try {
    axiosInstance.defaults.headers['x-load-schema'] = false;
    store.dispatch({ type: SET_READONLY_HEADER, data: false });
    axiosInstance.defaults.headers['x-use-read-only'] = false;
    const result = isRequestBodyRequired ? await axiosInstance.delete(methodRoute, { data: parameters }) : await axiosInstance.delete(methodRoute + createQueryString(parameters));
    if (result !== undefined) {
      if (!verifyResponse(result)) {
        return Promise.reject();
      }
      const recordSets = JSON.parse(result.data).recordSets;
      const sqlCommandText = JSON.parse(result.data).sqlCommandText;
      if (recordSets !== undefined && recordSets.length > 0) {
        const arrDataObjects: IDataObject[] = [];
        recordSets.map((recordSet: any) => {
          const dataObject = {
            ...recordSet,
            sqlCommandText,
          };
          arrDataObjects.push(dataObject);
          return recordSet;
        });

        responseObject = {
          ...responseObject,
          arrDataObjects,
          dataObject: arrDataObjects[0],
          hasMultipleDataObjects: arrDataObjects.length > 1,
        };
      }
    }
  } catch (error) {
    responseObject = {
      ...responseObject,
      errorMessage: error.toString(),
      hasError: true,
    };
    showError(error.toString());
  }
  return responseObject;
}

function showError(errorMessage: string, displayError?: boolean) {
  store.dispatch({ type: SHOW_ERROR, data: { showError: displayError === false ? displayError : true, errorMessage } });
}

function createQueryString(parameters: IGetParameterObject[]) {
  let queryString: string = '';
  let mandatory: string = '';
  let optional: string = '';
  let isFirstOptionalAdded = false;

  if (parameters !== undefined && parameters !== null && parameters.length > 0) {
    parameters.sort(myCompare);
    for (const parameter of parameters) {
      if (parameter.isOptional !== true) {
        mandatory += `/${encodeURIComponent(parameter.value.toString().trim())}`;
      } else {
        if (isFirstOptionalAdded === false) {
          optional += `?${parameter.name}=${encodeURIComponent(parameter.value.toString().trim())}`;
          isFirstOptionalAdded = true;
        } else {
          optional += `&${parameter.name}=${encodeURIComponent(parameter.value.toString().trim())}`;
        }
      }
    }
  }
  queryString = mandatory + optional;
  return queryString;
}

function myCompare(a: IGetParameterObject, b: IGetParameterObject) {
  if (a.sequence < b.sequence) {
    return -1;
  } else if (a.sequence > b.sequence) {
    return 1;
  }
  return 0;
}

const verifyResponse = (response: any): boolean => {
  if (response.data.indexOf('AuthenticationFailed') >= 0) {
    showError(expiredTokenMessage);
    setTimeout(() => {
      logout();
    }, 3000);
    return false;
  }

  return true;
};

export async function deleteData(methodRoute: string, methodParams: string, isRequestBodyRequired?: boolean): Promise<string> {
  axiosInstance.defaults.headers['x-load-schema'] = false;
  store.dispatch({ type: SET_READONLY_HEADER, data: false });
  axiosInstance.defaults.headers['x-use-read-only'] = false;
  if (isRequestBodyRequired) {
    return axiosInstance.delete(methodRoute, { data: methodParams }).then((response) => {
      if (!verifyResponse(response)) {
        return Promise.reject();
      }
      return response.data;
    });
  }
  return axiosInstance.delete(methodRoute + '/' + methodParams).then((response) => {
    if (!verifyResponse(response)) {
      return Promise.reject();
    }
    return response.data;
  });
}
export async function getData(methodRoute: string, methodParams: string, useReadOnly?: boolean): Promise<string> {
  axiosInstance.defaults.headers['x-load-schema'] = false;
  axiosInstance.defaults.headers['x-use-read-only'] = store.getState().globalRdcr.useReadonlyHeader;
  if (useReadOnly !== undefined) {
    axiosInstance.defaults.headers['x-use-read-only'] = useReadOnly;
  }
  if (methodParams !== undefined && methodParams !== '') {
    return axiosInstance.get(encodeURI(methodRoute + '/' + methodParams)).then((response) => {
      if (!verifyResponse(response)) {
        return Promise.reject();
      }
      return response.data;
    });
  } else {
    return axiosInstance.get(encodeURI(methodRoute)).then((response) => {
      if (!verifyResponse(response)) {
        return Promise.reject();
      }
      return response.data;
    });
  }
}

export async function postData(methodRoute: string, methodParams: {}): Promise<string> {
  axiosInstance.defaults.headers['x-load-schema'] = false;
  axiosInstance.defaults.headers['x-use-read-only'] = false;
  return axiosInstance.post(methodRoute, methodParams).then((response) => {
    if (!verifyResponse(response)) {
      return Promise.reject();
    }
    return response.data;
  });
}

export async function getFile(url = '') {
  axiosInstance.defaults.headers['x-load-schema'] = false;
  axiosInstance.defaults.headers['x-use-read-only'] = false;

  return axiosInstance.get(url, { responseType: 'blob' }).then((response) => {
    return response;
  });
}

export function getSSRSReport(requestUri: string) {
  axiosInstance.defaults.headers.Authorization = 'Basic ' + config.ssrsKey;
  //axiosInstance.defaults.headers['Access-Control-Allow-Origin'] = 'https://*.virtusconnect.com';
  let resp = axiosInstance
    .get(requestUri, { responseType: 'blob' })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw new Error('Bad Response');
    });

  getToken().then(async (response) => {
    if (response !== '') {
      axiosInstance.defaults.headers.Authorization = 'Bearer ' + response;
    }
  });
  return resp;
}

export async function fileDownload(requestUri: string, fileName?: string) {
  const blobUrl = new URL(requestUri);
  const values = blobUrl.pathname.split('/');

  if (values.length > 3) {
    const blobContainer = values[3];
    const blobName = decodeURI(values[4]);
    return getFile(`Converter/DownloadReport/${blobContainer}/${blobName}`).then((res) => {
      if (res.status >= 400) {
        throw new Error('Bad response from server');
      }
      const file = new Blob([res.data]);
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName === undefined ? blobName : fileName;
      a.click();
      return res;
    });
  }
}

export function emptyList(): IDataObject {
  return {
    schema: [
      {
        ColumnName: '',
        BaseColumnName: '',
        DataTypeName: '',
        ColumnSize: 10,
        NumericPrecision: 255,
        NumericScale: 255,
      },
    ],
    data: [{}],
  };
}

export const fetchDataReadWrite = async (controllerName: string, methodName: string, methodType: MethodType, parameters?: any): Promise<IResponseObject> =>
  fetchData(controllerName, methodName, methodType, parameters, undefined, undefined, undefined, false);
