export enum IconEnum {
  Edit,
  Delete,
  Properties,
  Transactions,
  Add,
  Clear,
  PasteFromClipboard,
  Folder,
  Unlink,
  Pause,
  Resume,
}
