import { auth } from '@virtus/common/auth/reducer';
import { setUserName } from 'data/DataConnector';
import { dealReducer } from 'reducers/deals';
import { errorReducer } from 'reducers/Error/Error';
import { globalReducer } from 'reducers/Global';
import { countriesReducer } from 'reducers/GlobalInfo/Countries/Countries';
import { searchCriteaRdcr } from 'reducers/SearchCriteria/SearchCriteria';
import { signalRReducer } from 'reducers/signalR';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { runAllSagas } from 'sagas/rootSaga';

const reducers = combineReducers({
  auth,
  countriesRdcr: countriesReducer,
  dealRdcr: dealReducer,
  errorRdcr: errorReducer,
  globalRdcr: globalReducer,
  searchCriteriaRdcr: searchCriteaRdcr,
  signalRRdcr: signalRReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['searchCriteriaRdcr'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware)));
runAllSagas(sagaMiddleware);

store.subscribe(() => {
  setUserName(store.getState().globalRdcr.username);
});

// prints store state every time store state is changed
// store.subscribe(function () {
//   console.log('store state', store.getState());
// });
const persistor = persistStore(store);

export { persistor };
export default store;
