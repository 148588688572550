import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import { Color } from 'util/enums/ColorEnum';
import { PageMessageIconType } from 'util/enums/PageMessageIconTypeEnum';
import './PageMessageControl.css';

export interface IProps {
  iconType: PageMessageIconType;
  label: string;
  style?: React.CSSProperties;
  component?: any;
}

const PageMessageControl = (props: IProps) => {
  let icon;
  let style;
  switch (props.iconType) {
    case PageMessageIconType.Warning:
      icon = <WarningIcon className="PageMessageIcon" />;
      break;
    case PageMessageIconType.Information:
      icon = <InfoIcon className="PageMessageIcon" />;
      break;
    case PageMessageIconType.Error:
      icon = <ErrorIcon className="PageMessageIcon" style={{ color: Color.MessageErrorBackground }} />;
      style = { backgroundColor: Color.ErrorBackground, color: Color.MessageErrorBackground };
      break;
  }
  style = { ...style, ...props.style };
  return (
    <div data-testid={'PageMessage'} className="PageMessage" style={style}>
      {icon}
      {props.component}
      <p data-testid={'PageMessageLabel'} className="PageMessageLabel" style={style}>
        {props.label}
      </p>
    </div>
  );
};

export default PageMessageControl;
