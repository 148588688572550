import { ComponentType } from 'components/hoc/PopupModal/ModalEnum';
import PasteLogo from 'images/clipboard-paste.svg';
import CloseLogo from 'images/close.svg';
import CopyLogo from 'images/create-copy.svg';
import HelpLogo from 'images/help.svg';
import PrintLogo from 'images/print-white.svg';
import React from 'react';
import './ModalHeader.css';

interface IProps {
  isComponentLoading: boolean;
  componentHeader: string;
  onPrintClicked?: () => any;
  onCloseClicked: () => void;
  onCopyClicked?: () => void;
  onPasteClicked?: () => void;
  componentType: ComponentType;
  customHeaderButtons?: any[];
  copyButtonText?: string;
  pasteButtonText?: string;
  hideCancel?: boolean;
}

const headerStyle = {
  backgroundColor: 'var(--modal-header-bg)',
  border: '0px',
  color: 'var(--text)',
  display: 'flex',
  height: '40px',
  width: '100%',
};

const ModalHeader = (props: IProps) => {
  return (
    <div className="modal-header" style={headerStyle}>
      <table className="inner">
        <tbody>
          <tr>
            <td>
              <span>
                <b data-testid={props.componentHeader}>{props.isComponentLoading === true ? '' : props.componentHeader}</b>
              </span>
            </td>
            <td className="icons">
              {getCustomIcons(props)}
              {GetPasteIcon(props)}
              {GetCopyIcon(props)}
              {GetHelpIcon()}
              {GetPrintIcon(props)}
              {!props.hideCancel && GetCloseIcon(props)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

function getCustomIcons(props: IProps) {
  if (props.customHeaderButtons) {
    return props.customHeaderButtons.map((element: any, index: number) => {
      return <React.Fragment key={index}>{element}</React.Fragment>;
    });
  }
}

function GetPasteIcon(props: IProps) {
  const pasteButtonText = props.pasteButtonText ? props.pasteButtonText : 'Paste';
  if (props.onPasteClicked) {
    return <img data-testid={'Paste'} alt="PasteLogo" title={pasteButtonText} height="20px" style={{ height: '20px' }} src={PasteLogo} onClick={props.onPasteClicked} />;
  }
}

function GetCopyIcon(props: IProps) {
  const copyButtonText = props.copyButtonText ? props.copyButtonText : 'Copy';
  if (props.onCopyClicked) {
    return <img data-testid={'Copy'} alt="CopyLogo" title={copyButtonText} src={CopyLogo} onClick={props.onCopyClicked} />;
  }
}

function GetHelpIcon() {
  return (
    <img
      data-testid={'Help'}
      alt="HelpLogo"
      title="Help"
      src={HelpLogo}
      onClick={(event: any) => {
        event.preventDefault();
        window.alert('You clicked on help!');
      }}
    />
  );
}

function GetPrintIcon(props: IProps) {
  if (props.componentType === ComponentType.Edit) {
    return (
      <img
        data-testid={'Print'}
        alt="PrintLogo"
        title="Print"
        src={PrintLogo}
        onClick={(event: any) => {
          event.preventDefault();
          window.print();
        }}
      />
    );
  }
}

function GetCloseIcon(props: IProps) {
  return <img data-testid={'Close'} alt="CloseLogo" title="Close" src={CloseLogo} onClick={props.onCloseClicked} />;
}

export default ModalHeader;
