export enum ComponentType {
  Alert,
  Delete,
  Edit,
  Information,
  Search,
  Confirm,
  SelectList,
  DataDictionary,
  YesOrNo,
}
