import React from 'react';
import uniqid from 'uniqid';
import ValidationControl from '../ValidationControl/ValidationControl';
import TextArea from './TextArea';

export interface IProps {
  name: string;
  value: string;
  dataType?: string;
  onChange?: (event: any) => void;
  isReadonly: boolean;
  rows?: number;
  isRequired?: boolean;
  isValid?: boolean;
  validationMessage?: string;
  height?: string;
  style?: {};
  hideValidationControl?: boolean;
}

export class TextAreaControlObject extends React.Component<IProps> {
  private uid = uniqid();
  public render() {
    return (
      <table>
        <tbody>
          <tr>
            {!this.props.hideValidationControl && (
              <td style={{ verticalAlign: 'top' }}>
                <ValidationControl
                  {...this.props}
                  isValid={this.props.isValid !== undefined ? this.props.isValid : true}
                  message={this.props.validationMessage ? this.props.validationMessage : 'An input is required'}
                  uid={this.uid}
                />
              </td>
            )}
            <td>
              <TextArea {...this.props} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default TextAreaControlObject;
