import LoadingIcon, { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import 'components/ActivityIndicator/ActivityIndicator.css';
import { Theme } from 'components/GridControl';
import activityIndicator from 'images/loading-dark.gif';
import React from 'react';

interface IProps {
  theme?: Theme;
  showLoadingMessage?: boolean;
  loadingMessage?: string;
}

const GridLoadingIcon = (props: IProps) => {
  const message = props.showLoadingMessage ? (props.loadingMessage ? props.loadingMessage : 'Please wait while your request is being processed...') : undefined;
  return props.theme === Theme.Dark ? (
    <div className="activity-indicator">
      <LoadingIcon type={LoadingIconType.Virtus} size={LoadingIconSizes.medium} show={true} />
      <p style={{ color: props.theme === Theme.Dark ? undefined : 'white' }}>{message}</p>
    </div>
  ) : (
    <div>
      <img
        id="loadingIndicator"
        alt={'loadingIndicator'}
        title="Please wait while your request is being processed..."
        key="loadingIndicator"
        src={activityIndicator}
        height="48px"
        width="48px"
        style={{ margin: 'auto' }}
      />
      <p>{message}</p>
    </div>
  );
};

export default GridLoadingIcon;
