import ActivityIndicator from 'components/ActivityIndicator/ActivityIndicator';
import 'components/hoc/PopupModal/ModalContent.css';
import * as ColorPalette from 'components/Themes/theme';
import React from 'react';
import './ModalContent.css';

interface IProps {
  isLoading: boolean;
  onSubmit?: any;
  style?: any;
}
const style = {
  backgroundColor: ColorPalette.colors.bgDark,
  padding: '20px 12px 20px 20px',
};

class ModalContent extends React.Component<IProps> {
  public render() {
    // TODO: Needs improvement for this.props.isLoading
    return (
      <div className="modal-content" style={{ ...style, ...this.props.style }}>
        {this.props.isLoading ? <ActivityIndicator /> : this.props.children}
      </div>
    );
  }
}

export default ModalContent;
