import { Folder } from '@material-ui/icons';
import Magnify from '@material-ui/icons/Search';
import { navigate } from '@reach/router';
import { Inspector } from '@virtus/components/Inspector';
import { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import { Body, Page } from '@virtus/components/page';
import { CLOSE_TOAST, SHOW_ERROR, SHOW_MESSAGE } from 'actions/ErrorActions/errorActionTypes';
import { SET_HEIGHT_TO_REMOVE, SET_READONLY_HEADER } from 'actions/globalActions';
import ActionButton, { ButtonType, IconType } from 'components/Buttons/ActionButton';
import GridLayoutEdit from 'components/GridLayoutEdit';
import HeaderBizObject, { IHeaderBizObjectProps } from 'components/HeaderBizObject/HeaderBizObject';
import MultipleAlertModal from 'components/Modals/AlertModal/MultipleAlertModal';
import 'components/PageControl/PageControl.css';
import ToastNotification, { VirtusToast } from 'components/ToastNotification/ToastNotification';
import config from 'config/index';
import { getPath } from 'config/routeConfig';
import { RouteConstAccessDenied, RouteConstError, RouteConstNotFound } from 'constants/RouteConstants';
import { setUserPermissions } from 'helpers/AuthenticationHelper';
import React from 'react';
import { connect } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import { find } from 'reducers/Global';
import { IRoute } from 'reducers/routeFormatter';
import store from 'stores/configureStore';
import styled from 'styled-components';
import { PageMessageIconType } from 'util/enums/PageMessageIconTypeEnum';
import { ConvertDouble, getIconNameFromPath, getMenuIdByPath, getMenuInfo, getPageTitle } from 'util/helpers/helperFunctions';
import { PageTab } from './PageTab';

export interface IProps {
  appName?: string;
  pageTitle?: string;
  searchCriteria?: any;
  showSearch?: boolean;
  onSearchOpen?: any;
  onSearchClose?: any;
  errorMessage: string[];
  showError: boolean;
  inspectorTitle?: string;
  inspectorChildren?: React.ReactNode;
  headerBizObjectProps?: IHeaderBizObjectProps;
  children?: any;
  onExpandChange?: (isExpanded: boolean) => void;
  isExpanded?: boolean;
  pageWidth?: string;
  showInformation: boolean;
  errorHeader: string;
  closeAlertModal: any;
  setHeightToRemove: (value: number) => void;
  showToast: boolean;
  toastDetails: any;
  currentToast: any;
  closeToast: any;
  allowAlertMessageCopy: boolean;
  hideTabs?: boolean;
  pageHeaderComp?: React.ReactNode;
  setReadonlyHeader: (value: boolean) => void;
  showLayoutEdit: boolean;
  closeLayoutEdit: () => void;
  layoutInfo: any;
  hideLine?: boolean;
  preAuth?: boolean;
}

interface IState {
  isExpanded: boolean;
  heightToRemove: number;
}

class PageControl extends React.Component<IProps, IState> {
  private pageTitle: string = '';
  private tabList: any = [];
  private selectedTab: number = 0;
  private menuId: number = 0;
  public constructor(props: IProps) {
    super(props);
    this.state = {
      heightToRemove: 60,
      isExpanded: props.isExpanded || false,
    };
  }

  public closeErrorModal = async () => {
    await this.props.closeAlertModal();
  };

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.isExpanded !== prevProps.isExpanded) {
      this.setState({
        isExpanded: this.props.isExpanded || false,
      });
    }
    if (this.props.hideTabs !== prevProps.hideTabs) {
      this.setHeightToRemove();
    }
  }

  public async componentDidMount() {
    try {
      if (window.location.pathname !== RouteConstNotFound && window.location.pathname !== RouteConstError && !window.location.pathname.startsWith('/DealList')) {
        const menuId = getMenuIdByPath(window.location.pathname);
        const menuInfo = getMenuInfo(ConvertDouble(menuId)) || {};
        const menutType = menuInfo.type;
        if (menutType === 'T') {
          this.menuId = ConvertDouble(menuId);
          const parentMenuId = menuInfo.parentId;
          const parentMenu = find(store.getState().globalRdcr.completeMenu, ConvertDouble(parentMenuId)) || {};
          this.tabList = (parentMenu.subroutes || []).map((e: IRoute, index: number) => {
            if (+e.menuId === this.menuId) {
              this.selectedTab = index;
            }
            return {
              icon: getIcon(getIconNameFromPath(e.urlImage || '') || ''),
              path: e.fullPath,
              tabId: e.menuId,
              text: e.name,
            };
          });
        }
        const authStatus = Boolean(menuId);
        if (!authStatus && !this.props.preAuth) {
          navigate(RouteConstAccessDenied);
        } else {
          this.props.setReadonlyHeader(true);
          this.pageTitle = getPageTitle(menuId);
          const defaultPathMap = store.getState().globalRdcr.defaultPathMap;
          setUserPermissions(defaultPathMap[menuId || 0].originalPath);
        }
      }
      this.setHeightToRemove();
    } catch (error) {
      //
    }
  }

  public render() {
    let keys: any = [];
    if (this.props.searchCriteria) {
      keys = Object.keys(this.props.searchCriteria);
    }
    return (
      <Page appName={process.env.REACT_APP_APPNAME} pageTitle={this.pageTitle || this.props.pageTitle || ''}>
        <ToastProvider placement="bottom-right" autoDismiss={false} components={{ Toast: VirtusToast }}>
          {config.envColor && !this.props.hideLine && <EnvStrip />}
          <div style={{ width: this.props.pageWidth || '100%' }}>
            <div style={{ width: '100%', padding: '10px 10px 0px 10px' }}>
              {this.props.headerBizObjectProps && <HeaderBizObject {...this.props.headerBizObjectProps} />}
              {this.props.pageHeaderComp && this.props.pageHeaderComp}
              {this.tabList.length > 0 && !this.props.hideTabs && (
                <PageTab
                  onChange={(data: any) => {
                    const path = data.path;
                    const newPath = getPath(path.slice(0), window.location.pathname, true);
                    navigate(newPath);
                  }}
                  dataSource={this.tabList}
                  selectedIndex={this.selectedTab}
                />
              )}
            </div>
            <Body>
              <div id="genesisAppContent" style={{ display: 'flex', width: '100%', padding: '0px 10px 0px 10px', height: `calc(100vh - ${this.state.heightToRemove}px)` }}>
                <div style={{ width: '100%' }}>
                  {this.props.searchCriteria && (
                    <div>
                      <table style={{ width: '100%' }}>
                        <tbody>
                          <tr>
                            {keys.length > 0 && (
                              <td>
                                {keys
                                  .filter((item: any) => this.props.searchCriteria[item] !== '')
                                  .map((item: any) => (
                                    <div key={item} className="search-criteria-items">
                                      <span style={{ color: 'rgba(255, 255, 255, 0.5)' }}>{item + ': '}</span>
                                      <span style={{ color: 'var(--text)' }}>{this.props.searchCriteria[item]}</span>
                                    </div>
                                  ))}
                              </td>
                            )}
                            <td style={{ verticalAlign: 'top' }}>
                              <div className="refine-search">
                                {this.props.showSearch ? (
                                  <Magnify className="refine-search-icon" style={{ height: '20px' }} onClick={this.closeSearch} />
                                ) : (
                                  <ActionButton onClick={this.openSearch} buttonText="Refine Search" IconType={IconType.RefineSearch} buttonType={ButtonType.Page} />
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                  <div className="page-content">
                    <div>{this.props.children}</div>
                  </div>
                </div>
                {this.props.searchCriteria && this.props.inspectorTitle ? (
                  <div style={{ boxSizing: 'border-box' }}>
                    <Inspector
                      onExpandChange={this.onExpandChange}
                      isExpanded={this.state.isExpanded || false}
                      title={this.props.inspectorTitle || this.props.pageTitle || ''}
                      closeInspector={this.closeSearch}
                      loading={false}
                      loadingIcon={{ type: LoadingIconType.Genesis, size: LoadingIconSizes.large }}
                      open={this.props.showSearch}
                    >
                      {this.props.inspectorChildren}
                    </Inspector>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Body>
          </div>
          {this.props.showError === true || this.props.showInformation ? (
            <MultipleAlertModal
              alertMessages={this.props.errorMessage}
              onCloseModal={this.closeErrorModal}
              headerText={this.props.errorHeader}
              iconType={this.props.showInformation ? PageMessageIconType.Information : PageMessageIconType.Warning}
              allowCopy={this.props.allowAlertMessageCopy}
            />
          ) : (
            <></>
          )}
          {<ToastNotification closeToast={this.props.closeToast} toastDetails={this.props.toastDetails} currentToast={this.props.currentToast} />}
          {this.props.showLayoutEdit && <GridLayoutEdit onSave={this.props.closeLayoutEdit} onClose={this.props.closeLayoutEdit} parameters={this.props.layoutInfo} />}
        </ToastProvider>
      </Page>
    );
  }

  private openSearch = () => {
    this.props.onSearchOpen();
  };

  private setHeightToRemove = () => {
    let heightToRemove = 60;

    if (this.tabList.length > 0 && !this.props.hideTabs) {
      heightToRemove += 35;
    }
    if (this.props.headerBizObjectProps) {
      heightToRemove += 15;
    }
    if (this.props.pageHeaderComp) {
      heightToRemove += 30;
    }

    let gridHeightToRemove = heightToRemove;
    if (this.props.searchCriteria) {
      gridHeightToRemove += 40;
    }
    this.setState({ heightToRemove });
    this.props.setHeightToRemove(gridHeightToRemove);
  };

  private closeSearch = () => {
    this.props.onSearchClose();
  };

  private onExpandChange = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  };
}

const mapGlobalStateToProps = (stateFromStore: any) => {
  return {
    allowAlertMessageCopy: stateFromStore.errorRdcr.allowAlertCopy,
    currentToast: stateFromStore.errorRdcr.currentToast,
    errorHeader: stateFromStore.errorRdcr.errorHeader,
    errorMessage: stateFromStore.errorRdcr.errorMessage,
    layoutInfo: stateFromStore.errorRdcr.layoutInfo,
    showError: stateFromStore.errorRdcr.showError,
    showInformation: stateFromStore.errorRdcr.showInformation,
    showLayoutEdit: stateFromStore.errorRdcr.showLayoutEdit,
    showToast: stateFromStore.errorRdcr.showToast,
    toastDetails: stateFromStore.errorRdcr.toastDetails,
  };
};

const mapDispatchActionToProps = (dispatch: any) => {
  return {
    closeAlertModal: () => {
      dispatch({ type: SHOW_ERROR, data: { errorMessage: '', showError: false } });
      dispatch({ type: SHOW_MESSAGE, data: { errorMessage: '', showMessage: false, errorHeader: '' } });
    },
    closeLayoutEdit: () => {
      dispatch({ type: 'HIDE_LAYOUT_EDIT', data: {} });
    },
    closeToast: (id: string) => {
      dispatch({ type: CLOSE_TOAST, data: id });
    },
    setHeightToRemove: (value: number) => {
      dispatch({ type: SET_HEIGHT_TO_REMOVE, data: value });
    },
    setReadonlyHeader: (value: boolean) => {
      dispatch({ type: SET_READONLY_HEADER, data: value });
    },
  };
};

export default connect(mapGlobalStateToProps, mapDispatchActionToProps)(PageControl);

const EnvStrip: any = styled.div`
  position: fixed;
  z-index: 1;
  height: 3px;
  width: 100%;
  background-color: ${config.envColor};
`;

const getIcon = (iconName: string) => {
  switch (iconName.toUpperCase()) {
    case 'FOLDER':
    case 'FOLDER_OUT':
      return <Folder style={{ height: '16px' }} />;
    default:
      break;
  }
};
