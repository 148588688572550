import { Redirect, Router } from '@reach/router';
import { setTabData } from 'helpers/AuthenticationHelper';
import React from 'react';
import store from 'stores/configureStore';
import { getEntityId, splitPath } from 'util/helpers/helperFunctions';

let defaultRedirectMap: any = {};
const initialMap: any = {
  '/': { to: '/DealList' },
};

const componentModuleMap: any = {
  '/GlobalInfo/LoanFacilities/FacilityProperties/Clients/Custom/CustomFieldsList': '/GlobalInfo/LoanFacilities/FacilityProperties/Custom/CustomFieldsList',
};

let previousPath = '';
let route: any;
let refresh: boolean;

export const getPath = (path: string, currentPath: string, refrsh?: boolean) => {
  refresh = refrsh || false;
  if (previousPath !== path) {
    defaultRedirectMap = store.getState().globalRdcr.defaultPathMap;
    const originalPath = path;
    let type = 'M';
    let menuId: any = -10000;
    menuId = getMenuId(path) || menuId;
    while (menuId in defaultRedirectMap && defaultRedirectMap[menuId].firstChild !== 0) {
      let childId = defaultRedirectMap[menuId].firstChild;
      if (defaultRedirectMap[childId].type === 'G') {
        const trimmedPath = currentPath.replace(defaultRedirectMap[menuId].fullPath, '');
        let childPath = trimmedPath.split('/')[1];
        childPath = defaultRedirectMap[menuId].fullPath + '/' + childPath;
        childId = getMenuId(childPath);
      }
      menuId = childId;
    }
    if (defaultRedirectMap[menuId]) {
      path = defaultRedirectMap[menuId].fullPath;
      type = defaultRedirectMap[menuId].type;
    }
    let redirectToPath = path;
    if (originalPath !== path) {
      if (currentPath && (currentPath.includes('entityId/') || type === 'T')) {
        currentPath = currentPath.replace(new RegExp('_', 'g'), '/');
        if (type === 'T') {
          const paths = splitPath(currentPath);
          redirectToPath += paths.query || '';
        } else {
          const entityId = getEntityId();
          redirectToPath += `/entityId/${entityId}`;
        }
      }
    } else if (menuId === -10000 && currentPath) {
      currentPath = currentPath.replace(new RegExp('_', 'g'), '/');
      const paths = splitPath(currentPath);
      redirectToPath += paths.query || '';
    }
    if (getEntityId() && !redirectToPath.includes('entityId/') && !redirectToPath.startsWith('/DealList')) {
      redirectToPath += `/entityId/${getEntityId()}`;
    }

    // Custom handling if the url_path contains url_page.
    if (redirectToPath.includes('.asp')) {
      redirectToPath = redirectToPath.replace(new RegExp('_', 'g'), '/').replace('.asp', '');
    }
    previousPath = redirectToPath;
    return redirectToPath;
  }
  return path;
};

export const generateRoute = (path: string, component?: any, currentPath?: string) => {
  if (previousPath !== path || refresh) {
    defaultRedirectMap = { ...store.getState().globalRdcr.defaultPathMap, ...initialMap };
    previousPath = path;
    const originalPath = path;
    let type = 'M';
    while (path in defaultRedirectMap) {
      type = defaultRedirectMap[path].type;
      path = defaultRedirectMap[path].to;
    }
    setTabData(path);
    const routerChildren = [];
    if (originalPath !== path) {
      let redirectToPath = path;
      if (currentPath && type === 'T') {
        const paths = splitPath(currentPath);
        redirectToPath += paths.query || '';
      }
      const redirectElement = React.createElement(Redirect, { key: 'appRedirect', from: originalPath, to: redirectToPath, noThrow: true });
      routerChildren.push(redirectElement);
    }
    const componentElement = getComponent(path);
    routerChildren.push(componentElement);
    route = React.createElement(Router, { key: 'appRouter', className: 'Router' }, routerChildren);
    refresh = false;
    if (component) {
      component.forceUpdate();
    }
  }
  return route;
};

const getMenuId = (path: string) => {
  // Custom case for DDARecon_DDAReconcashteam page.
  if (path.endsWith('DDARecon_DDAReconcashteam.asp')) {
    return 1391;
  }

  for (const menu of Object.keys(defaultRedirectMap)) {
    if (defaultRedirectMap[menu].fullPath.replace(new RegExp(defaultRedirectMap[menu].urlPage + '$'), '') === path) {
      return menu;
    }
  }
};

const getComponent = (path: string) => {
  const paths = splitPath(path);
  const componentPath = paths.rootPath;
  const modulePath = componentModuleMap[componentPath] || componentPath;
  const Component = React.lazy(() => import(`views/${modulePath.substring(1)}`));
  const parameters: any = {};
  let queryArray: any = [];
  if (paths.query) {
    queryArray = paths.query.substring(1).split('/');
    for (let index = 0; index < queryArray.length; index++) {
      if (index % 2) {
        queryArray[index] = `:${queryArray[index - 1]}`;
      } else {
        parameters[queryArray[index]] = 0;
      }
    }
  }
  return React.createElement(Component, { key: `${componentPath}`, path: `${componentPath}/${queryArray.join('/')}` });
};
