import { navigate } from '@reach/router';
import { SET_ERROR_MESSAGE } from 'actions/globalActions';
import { RouteConstError, RouteConstNotFound } from 'constants/RouteConstants';
import React from 'react';
import store from 'stores/configureStore';
import { getTrimmedValue } from 'util/helpers/helperFunctions';

class ErrorBoundary extends React.Component<any, any> {
  public static getDerivedStateFromError() {
    navigate(RouteConstError);
  }

  constructor(props: Readonly<{}>) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error: any) {
    if (getTrimmedValue(error).includes('Cannot find module')) {
      navigate(RouteConstNotFound);
    }
    store.dispatch({ type: SET_ERROR_MESSAGE, data: error });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  public render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
