import * as GlobalActions from 'actions/globalActions';
import { MethodType } from 'components/DropDown/MethodType';
import * as MethodNameConstants from 'constants/MethodNameConstants';
import { fetchData } from 'data/DataConnector';
import store from 'stores/configureStore';
import { ControllerNameEnum } from 'util/enums/Enum';
import { getMenuIdByPath } from 'util/helpers/helperFunctions';

// export async function getTabData(sysPath: string, overrideExisting?: boolean): Promise<boolean> {
// const param = { userId: store.getState().globalRdcr.username, sysPath };
// const response = await fetchData(ControllerNameEnum.CommonFunctions, MethodNameConstants.CdosaUserPageAccess, MethodType.Post, param);
// const authStatus = response.dataObject.data[0].at_code !== -1;
// setPermissions(response.dataObject.data);
// if (authStatus) {
//   response = await fetchData(ControllerNameEnum.TransactionDetails, MethodNameConstants.CdosaTabXmlList, MethodType.Post, param);
//   const data = JSON.parse(response.dataObject.data.toString());
//   store.dispatch({
//     data,
//     overrideExisting,
//     type: GlobalActions.APPEND_MENU,
//   });
// }
// return true;
// }

// export async function getParentId(sysPath: string) {
// const param = { userId: store.getState().globalRdcr.username, sysPath };
// const response = await fetchData(ControllerNameEnum.TransactionDetails, MethodNameConstants.CdosaTabXmlList, MethodType.Post, param);
// const data = JSON.parse(response.dataObject.data.toString());
// if (data.m_level1.menu_type === 'G') {
//   return data.m_level1.parent_menu_id;
// } else if (data.m_level1.menu_type === 'M') {
//   return data.m_level1.menu_id;
// }
// return '0';
// }

// export async function getUserPageAccess(sysPath: string): Promise<boolean> {
// const param = { userId: store.getState().globalRdcr.username, sysPath };
// const response = await fetchData(ControllerNameEnum.CommonFunctions, MethodNameConstants.CdosaUserPageAccess, MethodType.Post, param);
// const authStatus = response.dataObject.data[0].at_code !== -1;
// setPermissions(response.dataObject.data);
// return authStatus;
// return true;
// }

// function setPermissions(data: any) {
// store.dispatch({
//   data,
//   type: GlobalActions.SET_USER_PERMISSIONS,
// });
// }

// export function removeSubroutes(parentId?: string) {
// store.dispatch({
//   data: parentId,
//   type: GlobalActions.REMOVE_SUBROUTES,
// });
// }

// export async function setCurrentId(currentId: number) {
// store.dispatch({ type: GlobalActions.SET_CURRENT_ID, data: currentId });
// }

export async function setEntityId(entityId: number) {
  store.dispatch({ type: GlobalActions.SET_ENTITY_ID, data: entityId });
  if (entityId === 0) {
    store.dispatch({ type: GlobalActions.SET_MENU, data: window.location.pathname });
  }
}

// export async function setCompanyId(companyId: number) {
// store.dispatch({ type: GlobalActions.SET_COMPANY_ID, data: companyId });
// }

export const setUserPermissions = async (sysPath: string) => {
  const param = { userId: store.getState().globalRdcr.username, sysPath };
  const response = await fetchData(ControllerNameEnum.CommonFunctions, MethodNameConstants.CdosaUserPageAccess, MethodType.Post, param);
  const authStatus = response.dataObject.data[0].at_code !== -1;
  store.dispatch({
    data: response.dataObject.data,
    type: GlobalActions.SET_USER_PERMISSIONS,
  });
  return authStatus;
};

export const setTabData = (path: string) => {
  const menuId = getMenuIdByPath(path);
  store.dispatch({ type: GlobalActions.SET_MENU, data: window.location.pathname });
  store.dispatch({ type: GlobalActions.APPEND_TAB_MENU, data: menuId });
};
