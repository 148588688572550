import axios from 'axios';
import { getTrimmedValue } from 'util/helpers/helperFunctions';
import { CoreServicesClient, GET } from './CoreServicesClient';
import { useSignalR } from './SignalRConnector';

export type ExecuteCallback = (err: any, resStatus: number, resBody: any, resText: string, resHeaders: object, reqStartTime?: Date) => void;

export interface NetworkHandler {
  execute: (callback: ExecuteCallback) => void;
  abort: () => void;
}

export type NetworkInterface = (
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  config?: { body?: string | object; headers?: object; credentials?: 'omit' | 'include' },
) => NetworkHandler;

interface IConfig {
  apiUrl: string;
  clientId: string;
  signalrUrl?: string;
  signalrApiUrl: string;
  signalrClientId?: string;
  onUnauthorized: () => void;
  getUsername?: () => string;
  isGlideAuth?: boolean;
}

let csClient: any;
let isAlert: boolean;
let errorMessage: string;
/**
 * Creates a network interface to use with redux-query
 * @param baseUrl
 */

export default function createCoreServicesNetworkInterface({ apiUrl, signalrUrl, signalrApiUrl, onUnauthorized }: IConfig): NetworkInterface {
  csClient = new CoreServicesClient({
    baseURL: apiUrl,
    onUnauthorized,
    signalrUrl,
    signalrApiUrl,
  });

  return (url: string, method: string, { body, headers }: { body?: any; headers?: object } = {}): NetworkHandler => {
    const cancelSource = axios.CancelToken.source();
    isAlert = false;
    errorMessage = '';
    const requestConfig: any = { url, method, data: body, headers, cancelToken: cancelSource.token };
    if (method === GET && body) {
      requestConfig.params = body;
      delete requestConfig.data;
    }

    const request = csClient.request(requestConfig, useSignalR);
    const execute = async (cb: ExecuteCallback) => {
      try {
        const result = await request;
        const response = result.payload;
        const reqStartTime = result.reqStartTime;
        let errorMessage = '';
        if (response.hasError) {
          errorMessage = response.errorMessage || '';
          errorMessage = errorMessage.replace('<br/><br/> Please contact system administrator.', '');
        }
        cb(errorMessage, 200, response, '', {}, reqStartTime);
      } catch (err) {
        const { response = {} } = err;
        const { data, status, headers: responseHeaders } = response;
        if (status >= 300) {
          isAlert = true;
          if (data.Detail !== undefined && data.Detail !== '' && data.Detail !== null) {
            errorMessage = data.Detail;
            errorMessage = errorMessage.substring(0, 300) + ' .....';
          } else {
            errorMessage = data;
          }
        }

        if (status === 401 && onUnauthorized) {
          onUnauthorized();
        }
        const error = getTrimmedValue(err);
        const errorCode = error.split('\n')[0].split(':')[1] || '';
        errorMessage = (error.split('\n')[1] ? error.split('\n')[1].split(':')[1] : '') || '';
        cb(`${errorMessage} : ${errorCode}`, status, data, '', responseHeaders);
      }
    };

    const abort = () => cancelSource.cancel();

    return {
      abort,
      execute,
    };
  };
}
export function updateAlert() {
  isAlert = false;
}
export { csClient, isAlert, errorMessage };
