import { ConvertDouble, getTrimmedValue, validateDate } from 'util/helpers/helperFunctions';

export enum ValidationType {
  None,
  RequiredField,
  Integer,
  Numeric,
  MaxLength,
  MaxNumericLength,
  ValidDate,
  NonZeroNumeric,
  ValidInteger,
  ValidSmallInteger,
  PositiveNumber,
}

interface IOptions {
  message?: string;
  maxLength?: number;
}

export const validationMessages: any = {
  [ValidationType.RequiredField]: 'Value for "$PROPERTY_NAME" is required.',
  [ValidationType.Integer]: 'Value for "$PROPERTY_NAME" should be a valid integer.',
  [ValidationType.ValidInteger]: 'Value for "$PROPERTY_NAME" should be a valid integer.',
  [ValidationType.Numeric]: 'Value for "$PROPERTY_NAME" must be a valid numeric format.',
  [ValidationType.ValidDate]: 'Value for "$PROPERTY_NAME" should be a valid date.',
  [ValidationType.MaxLength]: '"$PROPERTY_NAME" should not be more than $MaxLength characters.',
  [ValidationType.MaxNumericLength]: '"$PROPERTY_NAME" should not be more than $MaxLength digits.',
  [ValidationType.ValidSmallInteger]: 'Value for "$PROPERTY_NAME" should be a valid small integer.',
  [ValidationType.PositiveNumber]: 'Value for "$PROPERTY_NAME" should be a valid positive number.',
};

export class ValidationModel {
  public ValidationTypes: ValidationType[] = [ValidationType.None];
  public PropertyName: string = '';
  public IsValid: boolean = true;
  public Message: string | undefined = undefined;
  public maxLength: number = 0;
  constructor(validationTypes: ValidationType[], propertyName: string, isValid: boolean, options?: IOptions) {
    this.ValidationTypes = validationTypes;
    this.PropertyName = propertyName;
    this.IsValid = isValid;
    if (options !== undefined) {
      this.Message = options.message;
      this.maxLength = options.maxLength || 0;
    }
  }
}

export const Validate = (validationType: ValidationType, value: any, maxLength: number = 0, allowLiteralNull: boolean = false): boolean => {
  let returnValue = true;
  switch (validationType) {
    case ValidationType.RequiredField:
      if (getTrimmedValue(value, allowLiteralNull) === '') {
        returnValue = false;
      }
      break;
    case ValidationType.Numeric:
      returnValue = !isNaN(getTrimmedValue(value));
      break;
    case ValidationType.MaxLength:
      if ((value || '').length > maxLength) {
        returnValue = false;
      }
      break;
    case ValidationType.MaxNumericLength:
      if (ConvertDouble(value).toFixed(0).toString().length > maxLength) {
        returnValue = false;
      }
      break;
    case ValidationType.ValidDate:
      if (value) {
        return validateDate(value);
      }
      break;
    case ValidationType.ValidInteger:
      returnValue = getTrimmedValue(value) === '' ? true : Number.isInteger(Number(value)) && getTrimmedValue(value).split('.').length === 1 && Number(value) <= 2147483647;
      break;
    case ValidationType.Integer:
      returnValue = Number.isInteger(Number(value)) && getTrimmedValue(value).split('.').length === 1;
      break;
    case ValidationType.NonZeroNumeric:
      returnValue = Boolean(ConvertDouble(value));
      break;
    case ValidationType.ValidSmallInteger:
      returnValue = getTrimmedValue(value) === '' ? true : Number.isInteger(Number(value)) && getTrimmedValue(value).split('.').length === 1 && Number(value) <= 32767;
      break;
    case ValidationType.PositiveNumber:
      returnValue = getTrimmedValue(value) === '' ? true : Number(value) >= 0;
      break;
    default:
      returnValue = true;
  }
  return returnValue;
};

export const OnChange = (component: any, property: any, event: any) => {
  property[event.target.name] = event.target.value;
  component.setState({
    ...component.state,
  });
};

export const OnChangeWithValidation = (component: any, property: any, validationModel: any, event: any, allowLiteralNull: boolean = false) => {
  let name = event.target.name;
  const value = event.target.value;
  let validationStatus = true;
  const validationModelTemp = validationModel.PropertyName ? validationModel : validationModel[name];

  for (const validation of validationModelTemp.ValidationTypes) {
    validationStatus = Validate(validation, value, validationModelTemp.maxLength, allowLiteralNull);
    validationModelTemp.IsValid = validationStatus;
    if (!validationStatus) {
      const message = validationMessages[validation];
      validationModelTemp.Message =
        message !== undefined ? message.replace('$PROPERTY_NAME', validationModelTemp.PropertyName).replace('$MaxLength', validationModelTemp.maxLength) : '';
      break;
    }
  }
  property[name] = value;
  component.setState({
    ...component.state,
  });
  return validationStatus;
};

export const OnInlineControlChange = (component: any, property: any, data: any, dataValue: any, name: string) => {
  property[name] = dataValue;
  component.setState({
    ...component.state,
  });
};

export const OnCurrencyChange = (component: any, property: any, event: any) => {
  property[event.name] = event.value;
  component.setState({
    ...component.state,
  });
};

export const OnCurrencyChangeWithValidation = (component: any, property: any, validationModel: any, event: any) => {
  const name = event.name;
  const value = event.value;
  const validationModelTemp = validationModel.PropertyName ? validationModel : validationModel[name];
  let validationStatus = true;
  for (const validation of validationModelTemp.ValidationTypes) {
    validationStatus = Validate(validation, value, validationModelTemp.maxLength);
    validationModelTemp.IsValid = validationStatus;
    if (!validationStatus) {
      const message = validationMessages[validation];
      validationModelTemp.Message =
        message !== undefined ? message.replace('$PROPERTY_NAME', validationModelTemp.PropertyName).replace('$MaxLength', validationModelTemp.maxLength) : '';
      break;
    }
  }
  property[name] = value;
  component.setState({
    ...component.state,
  });
  return validationStatus;
};

export const OnDateChange = async (component: any, property: any, value: any, name: any) => {
  property[name] = value;
  await component.setState({
    ...component.state,
  });
};

export const OnDateChangeWithValidation = async (component: any, property: any, validationModel: any, value: any, name: any) => {
  let validationStatus = true;
  const validationModelTemp = validationModel.PropertyName ? validationModel : validationModel[name];
  for (const validation of validationModelTemp.ValidationTypes) {
    validationStatus = Validate(validation, value);
    validationModelTemp.IsValid = validationStatus;
    if (!validationStatus) {
      const message = validationMessages[validation];
      validationModelTemp.Message = message !== undefined ? message.replace('$PROPERTY_NAME', validationModelTemp.PropertyName) : '';
      break;
    }
  }
  property[name] = value;
  await component.setState({
    ...component.state,
  });
  return validationStatus;
};

export const OnDateBlur = (component: any, property: any, validationModel: any, value: any, name: any) => {
  let validationStatus = true;
  property[name] = value;
  const validationModelTemp = validationModel.PropertyName ? validationModel : validationModel[name];
  for (const validation of validationModelTemp.ValidationTypes) {
    validationStatus = Validate(validation, value);
    validationModelTemp.IsValid = validationStatus;
    if (!validationStatus) {
      const message = validationMessages[validation];
      validationModelTemp.Message = message !== undefined ? message.replace('$PROPERTY_NAME', validationModelTemp.PropertyName) : '';
      break;
    }
  }
  component.setState({
    ...component.state,
  });
  return validationStatus;
};

export const OnCheckBoxChange = (component: any, property: any, event: any) => {
  property[event.target.name] = event.target.checked;
  component.setState({
    ...component.state,
  });
};

export const ValidateWithModel = async (component: any, value: any, validationModel: ValidationModel): Promise<boolean> => {
  let returnValue = true;
  for (const validationType of validationModel.ValidationTypes) {
    if (!Validate(validationType, value)) {
      validationModel.IsValid = false;
      const message = validationMessages[validationType];
      validationModel.Message = message !== undefined ? message.replace('$PROPERTY_NAME', validationModel.PropertyName) : '';
      returnValue = false;
      break;
    }
  }
  validationModel.IsValid = returnValue;
  await component.setState({
    ...component.state,
  });
  return returnValue;
};

export const OnSaveValidation = (component: any, properties: any, validationData: any, allowLiteralNull: boolean = false): boolean => {
  let returnStatus = true;
  for (const param in properties) {
    const validationModel = validationData[param];
    const value = properties[param];
    if (validationModel !== undefined) {
      for (const validation of validationModel.ValidationTypes) {
        const validationStatus = Validate(validation, value, validationModel.maxLength, allowLiteralNull);
        validationModel.IsValid = validationStatus;
        if (!validationStatus) {
          returnStatus = false;
          const message = validationMessages[validation];
          validationModel.Message =
            message !== undefined ? message.replace('$PROPERTY_NAME', validationModel.PropertyName).replace('$MaxLength', validationModel.maxLength || 0) : '';
          break;
        }
      }
    }
  }
  component.setState({
    ...component.state,
  });
  return returnStatus;
};
