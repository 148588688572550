import { MethodType } from 'components/DropDown/MethodType';
import { ValidationControl } from 'components/ValidationControl/ValidationControl';
import { dbConstIsBusinessDate } from 'constants/DbConstants';
import { fetchData } from 'data/DataConnector';
import { DateBox } from 'devextreme-react';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import { localeDateString, parseDate, validateDate } from 'util/helpers/helperFunctions';
import { Theme } from '../GridControl';

export enum DatePickerPosition {
  absolute,
  fixed,
  relative,
  inherit,
  initial,
}

export interface IProps {
  isDisabled?: boolean;
  onBlur?: (date: string, name: string | undefined) => void;
  name?: string;
  value: Date | undefined;
  onChange: any;
  isRequired: boolean;
  isValid?: boolean;
  validationMessage?: string;
  datePickerPosition?: DatePickerPosition;
  checkBusinessDay?: boolean;
  nbdAdjustment?: string;
  jurisdictionCodesString?: string;
  style?: any;
  theme?: Theme;
}

interface IProp extends IProps {
  format: string;
  placeholder?: string;
  hideValidationControl?: boolean;
}

interface IState {
  format: any;
  showCalendar: boolean;
  value: any;
  isBusinessDate: boolean;
  showClose: boolean;
}

const unit: any = 1;
const tooltipData = `d - display current date. ${moment().toDate().toLocaleDateString()} \nd1 - add 1 day to the current date. ${moment()
  .add(unit, 'd')
  .toDate()
  .toLocaleDateString()}\nm1 - add 1 month to the current month. ${moment().add(unit, 'M').toDate().toLocaleDateString()}\ny1 - add 1 year to the current year. ${moment()
  .add(unit, 'Y')
  .toDate()
  .toLocaleDateString()}`;
class DevXCalendar extends React.Component<IProp, IState> {
  private myref: any = '';
  private dateValue: any = '';
  constructor(props: IProp) {
    super(props);
    if (this.props.value !== undefined) {
      this.dateValue = validateDate(this.props.value) ? this.props.value : '';
    }

    this.state = {
      format: this.props.format.toUpperCase(),
      isBusinessDate: true,
      showCalendar: false,
      showClose: false,
      value: this.dateValue,
    };

    if (this.props.theme === Theme.Light) {
      require('./LightDevXCalendar.css');
    } else {
      require('./DarkDevXCalendar.css');
    }
  }

  public async componentDidMount() {
    await this.getFormattedDateValue(this.props.value);
    if (this.props.value && this.props.value !== this.dateValue && this.props.onBlur) {
      this.props.onBlur(this.dateValue, this.props.name);
    }
  }

  public async componentDidUpdate(prevProps: IProps) {
    if (this.props.value !== prevProps.value) {
      await this.getFormattedDateValue(this.props.value);
    }
  }

  public render() {
    const uid = uniqid();
    return (
      <table>
        <tbody>
          <tr>
            <td>
              <div>
                <table>
                  <tbody>
                    <tr>
                      {this.props.hideValidationControl !== true && (
                        <td>
                          <ValidationControl
                            {...this.props}
                            isValid={this.props.isValid !== undefined ? this.props.isValid : true}
                            message={this.props.validationMessage ? this.props.validationMessage : 'Valid date is required'}
                            uid={uid}
                          />
                        </td>
                      )}
                      <td>
                        <div className={this.props.theme === Theme.Light ? '' : 'DateBox'} title={tooltipData} data-testid={this.props.name}>
                          <DateBox
                            data-testid={this.props.name}
                            ref={this.myref}
                            isValid={true}
                            style={
                              this.props.style
                                ? this.props.style
                                : {
                                    // minWidth: '120px',
                                    marginLeft: '-1px',
                                    paddingRight: '0px',
                                    width: '140px',
                                  }
                            }
                            onFocusIn={this.onFocusIn}
                            onFocusOut={this.onFocusOut}
                            onChange={this.onInputChange}
                            onOpened={this.onOpened}
                            stylingMode={'filled'}
                            value={this.state.value}
                            disabled={this.props.isDisabled}
                            placeholder={this.props.placeholder}
                            showClearButton={this.state.showClose}
                            pickerType={'calendar'}
                            min={new Date(1900, 0, 1)}
                            onValueChanged={this.onValueSelected}
                          />
                        </div>
                      </td>
                      <td>
                        {!this.state.isBusinessDate && (
                          <div style={{ backgroundColor: 'var(--accent-grey)', color: 'var(--text)', padding: '2px', height: '22px', verticalAlign: 'middle' }}>
                            Non-business Day.
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  private onFocusIn = () => this.setState({ ...this.state, showClose: true });
  private onFocusOut = () => this.setState({ ...this.state, showClose: false });

  private async getFormattedDateValue(date: any) {
    let val;
    if (moment.utc(date).isValid()) {
      const finalDate = parseDate(date);
      val = finalDate;
    }
    if (val) {
      this.setState({ ...this.state, value: localeDateString(date) });
    } else if (!date) {
      this.setState({ ...this.state, value: '' });
    }
  }

  private onInputChange = async (e: any) => {
    const value = e.event.target.value;
    const data = await this.getShortcuts(value);
    const dts = this.getFormattedDate(data);
    if (this.props.checkBusinessDay === true) {
      await this.checkBusinessDay(dts, this.props.jurisdictionCodesString, this.props.nbdAdjustment);
    }
    if (dts) {
      // Added extra setState as it was causing issues with shortcuts to render the Datebox for the same props 'value'
      await this.setState({
        ...this.state,
        value: undefined,
      });
      await this.setState({
        ...this.state,
        value: data,
      });
    }
    this.props.onChange(dts, this.props.name);
    if (this.props.onBlur) {
      this.props.onBlur(data, this.props.name);
    }
  };

  private onOpened = (e: any) => {
    if (this.props.theme !== Theme.Light) {
      e.component._popup.content().parentElement.classList.add('DateBox');
    }
  };

  private onValueSelected = async (e: any) => {
    const dt = e.value;
    if (dt) {
      await this.setState({
        ...this.state,
        value: new Date(dt).toLocaleDateString(),
      });
      const dts = this.getFormattedDate(this.state.value);
      if (this.props.checkBusinessDay === true) {
        await this.checkBusinessDay(dts, this.props.jurisdictionCodesString, this.props.nbdAdjustment);
      }
      this.props.onChange(dts, this.props.name);
      if (this.props.onBlur) {
        this.props.onBlur(this.state.value, this.props.name);
      }
    } else {
      await this.setState({
        ...this.state,
        isBusinessDate: true,
        value: undefined,
      });
      this.props.onChange(undefined, this.props.name);
      if (this.props.onBlur) {
        this.props.onBlur(this.state.value, this.props.name);
      }
    }
  };

  private async checkBusinessDay(actualDate: string | undefined, jurisdictionCodesString: string | undefined, nbdAdjustment: string | undefined) {
    if (validateDate(actualDate) && this.props.jurisdictionCodesString) {
      actualDate = parseDate(actualDate);
      const parameters = {
        actualDate,
        jurisdictionCodesString,
        nbdAdjustment,
      };
      const result = await fetchData('Facility', 'DateBusinessDateCheck', MethodType.Post, parameters);
      if (!result.hasError && !result.hasConfirmationMessage && result.dataObject.data.length > 0) {
        const isBusinessDate: boolean = result.dataObject.data[0][dbConstIsBusinessDate];
        this.setState({
          ...this.state,
          isBusinessDate,
        });
      }
    } else {
      await this.setState({
        ...this.state,
        isBusinessDate: true,
      });
    }
  }

  private getFormattedDate(value: any) {
    if (moment.utc(value, this.state.format).isValid()) {
      const finalDate = parseDate(value);
      return finalDate;
    } else {
      return undefined;
    }
  }

  private async getShortcuts(value: string) {
    if (value) {
      value = value.toLowerCase();
      let firstChar = value.charAt(0);
      let finalValue: any = value;
      let splitString: any = [];
      switch (firstChar) {
        case 'd':
          splitString = value.split('d');
          if (splitString.length === 1) {
            finalValue = moment().toDate().toLocaleDateString();
          }
          break;
        case 'm':
          splitString = value.split('m');
          firstChar = firstChar.toUpperCase();
          break;
        case 'y':
          splitString = value.split('y');
          firstChar = firstChar.toUpperCase();
          break;
      }
      if (splitString.length === 2) {
        const val = splitString[1];
        if (!isNaN(+val)) {
          finalValue = moment().add(val, firstChar).toDate().toLocaleDateString();
        }
      }
      if (moment(finalValue, this.state.format).isValid()) {
        finalValue = moment(finalValue, this.state.format).toDate().toLocaleDateString();
      }
      return finalValue;
    }
  }
}
const mapStateToProps = (stateFromStore: any) => {
  return {
    format: stateFromStore.globalRdcr.dateFormat,
    placeholder: stateFromStore.globalRdcr.placeholder,
  };
};
export default connect(mapStateToProps)(DevXCalendar);
