import { SHOW_ERROR, SHOW_MESSAGE, SHOW_TOAST } from 'actions/ErrorActions/errorActionTypes';
import { IS_BACK_CLICKED } from 'actions/SearchCriteria/searchCriteriaActionTypes';
import { MethodType } from 'components/DropDown/MethodType';
import config from 'config';
import {
  dbConstActionMessageCode,
  dbConstMessageDesc,
  dbConstOperationDetails,
  dbConstOperationMessage,
  dbConstOperationMessageCode,
  dbConstOperationResultCode,
} from 'constants/DbConstants';
import csvtojson from 'csvtojson';
import { fetchData, IGetParameterObject, IResponseObject } from 'data/DataConnector';
import Excel from 'exceljs';
import _ from 'lodash';
import moment from 'moment';
import { AppearanceTypes } from 'react-toast-notifications';
import store from 'stores/configureStore';
import { isNumber } from 'util';
import { ControllerNameEnum, CouponIndex, FileType } from 'util/enums/Enum';

export const isStringNullOrWhitespace = (inputString: string) => {
  return inputString === null || inputString.match(/^ *$/) !== null;
};

export const getQueryString = (parameters: any) => {
  let queryString: string = '?';
  for (const key in parameters) {
    queryString = queryString + '&' + key + '=' + parameters[key];
  }
  return queryString;
};

export const ProcessSaveResponse = (response: any) => {
  let errorMessage = '';
  const operationResultCode = JSON.parse(response).recordSets[0].data[0][dbConstOperationMessageCode];
  const operationMessage = JSON.parse(response).recordSets[0].data[0][dbConstOperationMessage];
  const operationDetails = JSON.parse(response).recordSets[0].data[0][dbConstOperationDetails];
  if (!isStringNullOrWhitespace(operationResultCode) || !isStringNullOrWhitespace(operationMessage)) {
    errorMessage = JSON.parse(response).recordSets[0].data[0][dbConstOperationMessage];
    if (!isStringNullOrWhitespace(operationDetails)) {
      errorMessage += ' ' + operationDetails;
    }
  }
  return errorMessage;
};

export function TrimChar(str: string, charToRemove: string) {
  while (str.charAt(0) === charToRemove) {
    str = str.substring(1);
  }
  while (str.charAt(str.length - 1) === charToRemove) {
    str = str.substring(0, str.length - 1);
  }
  return str;
}

export function getLocaleDateString(language: string) {
  const formats: any = {
    'af-ZA': 'yyyy/MM/dd',
    'am-ET': 'd/M/yyyy',
    'ar-AE': 'dd/MM/yyyy',
    'ar-BH': 'dd/MM/yyyy',
    'ar-DZ': 'dd-MM-yyyy',
    'ar-EG': 'dd/MM/yyyy',
    'ar-IQ': 'dd/MM/yyyy',
    'ar-JO': 'dd/MM/yyyy',
    'ar-KW': 'dd/MM/yyyy',
    'ar-LB': 'dd/MM/yyyy',
    'ar-LY': 'dd/MM/yyyy',
    'ar-MA': 'dd-MM-yyyy',
    'ar-OM': 'dd/MM/yyyy',
    'ar-QA': 'dd/MM/yyyy',
    'ar-SA': 'dd/MM/yy',
    'ar-SY': 'dd/MM/yyyy',
    'ar-TN': 'dd-MM-yyyy',
    'ar-YE': 'dd/MM/yyyy',
    'arn-CL': 'dd-MM-yyyy',
    'as-IN': 'dd-MM-yyyy',
    'az-Cyrl-AZ': 'dd.MM.yyyy',
    'az-Latn-AZ': 'dd.MM.yyyy',
    'ba-RU': 'dd.MM.yy',
    'be-BY': 'dd.MM.yyyy',
    'bg-BG': 'dd.M.yyyy',
    'bn-BD': 'dd-MM-yy',
    'bn-IN': 'dd-MM-yy',
    'bo-CN': 'yyyy/M/d',
    'br-FR': 'dd/MM/yyyy',
    'bs-Cyrl-BA': 'd.M.yyyy',
    'bs-Latn-BA': 'd.M.yyyy',
    'ca-ES': 'dd/MM/yyyy',
    'co-FR': 'dd/MM/yyyy',
    'cs-CZ': 'd.M.yyyy',
    'cy-GB': 'dd/MM/yyyy',
    'da-DK': 'dd-MM-yyyy',
    'de-AT': 'dd.MM.yyyy',
    'de-CH': 'dd.MM.yyyy',
    'de-DE': 'dd.MM.yyyy',
    'de-LI': 'dd.MM.yyyy',
    'de-LU': 'dd.MM.yyyy',
    'dsb-DE': 'd. M. yyyy',
    'dv-MV': 'dd/MM/yy',
    'el-GR': 'd/M/yyyy',
    'en-029': 'MM/dd/yyyy',
    'en-AU': 'd/MM/yyyy',
    'en-BZ': 'dd/MM/yyyy',
    'en-CA': 'dd/MM/yyyy',
    'en-GB': 'dd/MM/yyyy',
    'en-IE': 'dd/MM/yyyy',
    'en-IN': 'dd-MM-yyyy',
    'en-JM': 'dd/MM/yyyy',
    'en-MY': 'd/M/yyyy',
    'en-NZ': 'd/MM/yyyy',
    'en-PH': 'M/d/yyyy',
    'en-SG': 'd/M/yyyy',
    'en-TT': 'dd/MM/yyyy',
    'en-US': 'M/d/yyyy',
    'en-ZA': 'yyyy/MM/dd',
    'en-ZW': 'M/d/yyyy',
    'es-AR': 'dd/MM/yyyy',
    'es-BO': 'dd/MM/yyyy',
    'es-CL': 'dd-MM-yyyy',
    'es-CO': 'dd/MM/yyyy',
    'es-CR': 'dd/MM/yyyy',
    'es-DO': 'dd/MM/yyyy',
    'es-EC': 'dd/MM/yyyy',
    'es-ES': 'dd/MM/yyyy',
    'es-GT': 'dd/MM/yyyy',
    'es-HN': 'dd/MM/yyyy',
    'es-MX': 'dd/MM/yyyy',
    'es-NI': 'dd/MM/yyyy',
    'es-PA': 'MM/dd/yyyy',
    'es-PE': 'dd/MM/yyyy',
    'es-PR': 'dd/MM/yyyy',
    'es-PY': 'dd/MM/yyyy',
    'es-SV': 'dd/MM/yyyy',
    'es-US': 'M/d/yyyy',
    'es-UY': 'dd/MM/yyyy',
    'es-VE': 'dd/MM/yyyy',
    'et-EE': 'd.MM.yyyy',
    'eu-ES': 'yyyy/MM/dd',
    'fa-IR': 'MM/dd/yyyy',
    'fi-FI': 'd.M.yyyy',
    'fil-PH': 'M/d/yyyy',
    'fo-FO': 'dd-MM-yyyy',
    'fr-BE': 'd/MM/yyyy',
    'fr-CA': 'yyyy-MM-dd',
    'fr-CH': 'dd.MM.yyyy',
    'fr-FR': 'dd/MM/yyyy',
    'fr-LU': 'dd/MM/yyyy',
    'fr-MC': 'dd/MM/yyyy',
    'fy-NL': 'd-M-yyyy',
    'ga-IE': 'dd/MM/yyyy',
    'gd-GB': 'dd/MM/yyyy',
    'gl-ES': 'dd/MM/yy',
    'gsw-FR': 'dd/MM/yyyy',
    'gu-IN': 'dd-MM-yy',
    'ha-Latn-NG': 'd/M/yyyy',
    'he-IL': 'dd/MM/yyyy',
    'hi-IN': 'dd-MM-yyyy',
    'hr-BA': 'd.M.yyyy.',
    'hr-HR': 'd.M.yyyy',
    'hsb-DE': 'd. M. yyyy',
    'hu-HU': 'yyyy. MM. dd.',
    'hy-AM': 'dd.MM.yyyy',
    'id-ID': 'dd/MM/yyyy',
    'ig-NG': 'd/M/yyyy',
    'ii-CN': 'yyyy/M/d',
    'is-IS': 'd.M.yyyy',
    'it-CH': 'dd.MM.yyyy',
    'it-IT': 'dd/MM/yyyy',
    'iu-Cans-CA': 'd/M/yyyy',
    'iu-Latn-CA': 'd/MM/yyyy',
    'ja-JP': 'yyyy/MM/dd',
    'ka-GE': 'dd.MM.yyyy',
    'kk-KZ': 'dd.MM.yyyy',
    'kl-GL': 'dd-MM-yyyy',
    'km-KH': 'yyyy-MM-dd',
    'kn-IN': 'dd-MM-yy',
    'ko-KR': 'yyyy-MM-dd',
    'kok-IN': 'dd-MM-yyyy',
    'ky-KG': 'dd.MM.yy',
    'lb-LU': 'dd/MM/yyyy',
    'lo-LA': 'dd/MM/yyyy',
    'lt-LT': 'yyyy.MM.dd',
    'lv-LV': 'yyyy.MM.dd.',
    'mi-NZ': 'dd/MM/yyyy',
    'mk-MK': 'dd.MM.yyyy',
    'ml-IN': 'dd-MM-yy',
    'mn-MN': 'yy.MM.dd',
    'mn-Mong-CN': 'yyyy/M/d',
    'moh-CA': 'M/d/yyyy',
    'mr-IN': 'dd-MM-yyyy',
    'ms-BN': 'dd/MM/yyyy',
    'ms-MY': 'dd/MM/yyyy',
    'mt-MT': 'dd/MM/yyyy',
    'nb-NO': 'dd.MM.yyyy',
    'ne-NP': 'M/d/yyyy',
    'nl-BE': 'd/MM/yyyy',
    'nl-NL': 'd-M-yyyy',
    'nn-NO': 'dd.MM.yyyy',
    'nso-ZA': 'yyyy/MM/dd',
    'oc-FR': 'dd/MM/yyyy',
    'or-IN': 'dd-MM-yy',
    'pa-IN': 'dd-MM-yy',
    'pl-PL': 'yyyy-MM-dd',
    'prs-AF': 'dd/MM/yy',
    'ps-AF': 'dd/MM/yy',
    'pt-BR': 'd/M/yyyy',
    'pt-PT': 'dd-MM-yyyy',
    'qut-GT': 'dd/MM/yyyy',
    'quz-BO': 'dd/MM/yyyy',
    'quz-EC': 'dd/MM/yyyy',
    'quz-PE': 'dd/MM/yyyy',
    'rm-CH': 'dd/MM/yyyy',
    'ro-RO': 'dd.MM.yyyy',
    'ru-RU': 'dd.MM.yyyy',
    'rw-RW': 'M/d/yyyy',
    'sa-IN': 'dd-MM-yyyy',
    'sah-RU': 'MM.dd.yyyy',
    'se-FI': 'd.M.yyyy',
    'se-NO': 'dd.MM.yyyy',
    'se-SE': 'yyyy-MM-dd',
    'si-LK': 'yyyy-MM-dd',
    'sk-SK': 'd. M. yyyy',
    'sl-SI': 'd.M.yyyy',
    'sma-NO': 'dd.MM.yyyy',
    'sma-SE': 'yyyy-MM-dd',
    'smj-NO': 'dd.MM.yyyy',
    'smj-SE': 'yyyy-MM-dd',
    'smn-FI': 'd.M.yyyy',
    'sms-FI': 'd.M.yyyy',
    'sq-AL': 'yyyy-MM-dd',
    'sr-Cyrl-BA': 'd.M.yyyy',
    'sr-Cyrl-CS': 'd.M.yyyy',
    'sr-Cyrl-ME': 'd.M.yyyy',
    'sr-Cyrl-RS': 'd.M.yyyy',
    'sr-Latn-BA': 'd.M.yyyy',
    'sr-Latn-CS': 'd.M.yyyy',
    'sr-Latn-ME': 'd.M.yyyy',
    'sr-Latn-RS': 'd.M.yyyy',
    'sv-FI': 'd.M.yyyy',
    'sv-SE': 'yyyy-MM-dd',
    'sw-KE': 'M/d/yyyy',
    'syr-SY': 'dd/MM/yyyy',
    'ta-IN': 'dd-MM-yyyy',
    'te-IN': 'dd-MM-yy',
    'tg-Cyrl-TJ': 'dd.MM.yy',
    'th-TH': 'd/M/yyyy',
    'tk-TM': 'dd.MM.yy',
    'tn-ZA': 'yyyy/MM/dd',
    'tr-TR': 'dd.MM.yyyy',
    'tt-RU': 'dd.MM.yyyy',
    'tzm-Latn-DZ': 'dd-MM-yyyy',
    'ug-CN': 'yyyy-M-d',
    'uk-UA': 'dd.MM.yyyy',
    'ur-PK': 'dd/MM/yyyy',
    'uz-Cyrl-UZ': 'dd.MM.yyyy',
    'uz-Latn-UZ': 'dd/MM yyyy',
    'vi-VN': 'dd/MM/yyyy',
    'wo-SN': 'dd/MM/yyyy',
    'xh-ZA': 'yyyy/MM/dd',
    'yo-NG': 'd/M/yyyy',
    'zh-CN': 'yyyy/M/d',
    'zh-HK': 'd/M/yyyy',
    'zh-MO': 'd/M/yyyy',
    'zh-SG': 'd/M/yyyy',
    'zh-TW': 'yyyy/M/d',
    'zu-ZA': 'yyyy/MM/dd',
  };
  return formats[language] !== undefined ? formats[language].toUpperCase() : 'M/D/YYYY';
}

export function isDateInDateRange(date: any, startDate: any, endDate: any) {
  startDate = parseDate(startDate);
  endDate = parseDate(endDate);
  const dt = parseDate(date);
  if (startDate === undefined && endDate === undefined) {
    return true;
  } else if (startDate === undefined) {
    if (dt <= endDate) {
      return true;
    }
  } else if (endDate === undefined) {
    if (dt >= startDate) {
      return true;
    }
  } else {
    if (dt >= startDate && dt <= endDate) {
      return true;
    }
  }
  return false;
}

export const getObjectFromString = (stringToParse: string, propertyDelimiter: string, keyValueDelimiter: string) => {
  const returnData: any = {};
  if (stringToParse !== undefined) {
    if (stringToParse.endsWith(propertyDelimiter)) {
      stringToParse = stringToParse.slice(0, stringToParse.length - 1);
    }
    const propertyArray = stringToParse.split(propertyDelimiter);
    for (const value of propertyArray) {
      const valueArray = value.split(keyValueDelimiter);
      returnData[valueArray[0].trim()] = valueArray[1];
    }
  }
  return returnData;
};

export const createnumberListWithSuffix = (limit: number) => {
  let data: any[];
  data = [];
  for (let i = 1; i <= limit; i++) {
    const temp = {
      key: i.toString(),
      value: appendIntegerSuffix(i),
    };
    data = [...data, temp];
  }
  return data;
};

export const appendIntegerSuffix = (i: number) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
};

export const createnumberList = (start: number, end: number) => {
  let data: any[];
  data = [];
  for (let i = start; i <= end; i++) {
    const temp = {
      key: i.toString(),
      value: i.toString(),
    };
    data = [...data, temp];
  }
  return data;
};

export const getTrimmedValue = (value: any, allowLiteralNull: boolean = false) => {
  if (value !== undefined && value !== null && value !== '') {
    if (value.toString().trim().toLowerCase() !== 'null' || allowLiteralNull) {
      return value.toString().trim();
    }
    return '';
  }
  return '';
};

export const nameValueSearch = (arrArray: string, strKeyName: string) => {
  let serachResult = '';
  if (arrArray) {
    if (arrArray.substr(-1) === '|') {
      arrArray = arrArray.substr(0, arrArray.length - 1);
    }
    const splitArray = arrArray.split('|');
    for (const data of splitArray) {
      const splitRow = data.split(';');
      if (splitRow.length > 1) {
        const loopKeyName = getTrimmedValue(splitRow[0]);
        const loopKeyValue = getTrimmedValue(splitRow[1]);
        if (loopKeyName === getTrimmedValue(strKeyName)) {
          serachResult = loopKeyValue;
          break;
        }
      }
    }
  }
  return serachResult;
};

export const enclose = (value: any) => {
  if (value) {
    return '(' + getTrimmedValue(value) + ')';
  }
  return '';
};

export const formatNumberCustom = (value: any, precision?: number) => {
  if (value !== undefined && value !== null && value !== '') {
    if (getTrimmedValue(precision)) {
      return Number(value).toLocaleString('en', { minimumFractionDigits: precision, maximumFractionDigits: precision });
    }
    return Number(value).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  return '';
};

export const getConfigValue = async (configCode: string, entityId: any) => {
  let resultSet;
  if (entityId) {
    const parameter = { entityId, configCode };
    resultSet = await fetchData(ControllerNameEnum.Generic, 'CdosysConfigDealValueGet', MethodType.Post, parameter);
  } else {
    const parameter = [{ name: 'configCode', value: configCode, sequence: 1 }];
    resultSet = await fetchData('TransactionMatching', 'CdosysConfigValueGet', MethodType.Get, parameter);
  }
  let configValue: any = '';
  if (!resultSet.hasError && resultSet.dataObject.data.length > 0) {
    if (Number(getTrimmedValue(entityId)) === 0) {
      configValue = getTrimmedValue(resultSet.dataObject.data[0].system_config_value);
    } else {
      const dealConfigValue = getTrimmedValue(resultSet.dataObject.data[0].deal_config_value);
      const systemConfigValue = getTrimmedValue(resultSet.dataObject.data[0].system_config_value);
      configValue = dealConfigValue === '' && systemConfigValue !== '' ? systemConfigValue : dealConfigValue;
    }
  }
  return configValue;
};

export const ConvertDouble = (value: any) => {
  if (value) {
    value = value.toString().replace(new RegExp(',', 'g'), '');
  }
  let doubleValue = parseFloat(value);
  if (isNaN(doubleValue)) {
    doubleValue = 0;
  }
  return doubleValue;
};

export const ConvertBool = (value: any) => {
  let blnValue: boolean = false;

  if (isNumber(value)) {
    blnValue = !!+value;
  } else if (getTrimmedValue(value).toString().toLowerCase() !== 'false') {
    blnValue = true;
  }

  return blnValue;
};

export const booleanToText = (blnValue: any): string => {
  return blnValue === null || blnValue === undefined ? '' : blnValue === true || blnValue === 1 ? '1' : '0';
};

export const textToBoolean = (txtValue: any): any => {
  if (txtValue === null || txtValue === undefined || txtValue === '') {
    return undefined;
  } else if (txtValue === '1' || txtValue.toLowerCase() === 'y' || txtValue.toLowerCase() === 'yes' || txtValue.toLowerCase() === 'true') {
    return 1;
  }
  return 0;
};

export const MessageGet = async (messageCode: string) => {
  const mandatoryParam = [{ name: 'messageCode', value: messageCode, sequence: 1 }];
  const resultSet = await fetchData('CommonFunctions', 'CdosysMessageGet', MethodType.Get, mandatoryParam);
  if (resultSet.dataObject.data.length > 0) {
    return resultSet.dataObject.data[0][dbConstMessageDesc];
  } else {
    return "Unable to locate message for '" + messageCode + "'.";
  }
};

export const MessageFormatGet = async (messageCode: string, parameters: string[]) => {
  let message = await MessageGet(messageCode);
  parameters.forEach((element: string, index: number) => {
    message = message.replace(new RegExp('\\{' + index + '\\}', 'g'), element);
  });
  return message;
};

export const RoundToTwo = (num: any) => {
  return +(Math.round(+(num + 'e+2')) + 'e-2');
};

export const Round = (num: any, precision: any) => {
  return +(Math.round(+(num + `e+${precision}`)) + `e-${precision}`);
};

export const GetSystemBalance = async (facilityId: any, tradeType: string, tradeDate: any, settleDate: any, entityId: any, stratId: any, taIdLong: any, taIdShort: any) => {
  const param: any = {
    facilityId,
    silentMode: 0,
    targetSettleDate: settleDate,
    tradeDate,
    tradeType,
  };
  const paramShort = Object.create({});
  const paramLong = Object.create({});
  const result: any = {};
  if (stratId && stratId > 0) {
    param.stratId = stratId;
  }
  if (entityId && entityId > 0) {
    param.entityId = entityId;
  }

  Object.assign(paramShort, param);
  Object.assign(paramLong, param);

  if (ConvertDouble(taIdLong) || ConvertDouble(taIdLong) === 0) {
    paramLong.taId = taIdLong;
    paramLong.positionType = 'L';
  }
  if (ConvertDouble(taIdShort) || ConvertDouble(taIdShort) === 0) {
    paramShort.taId = taIdShort;
    paramShort.positionType = 'SP';
  }
  let commitmentL = 0;
  let commitmentSp = 0;
  let res = await fetchData('FacilityAssetSettlement', 'FacilitySystemBalanceGet', MethodType.Post, param);

  if (tradeType !== 'P') {
    res = await fetchData('FacilityAssetSettlement', 'FacilitySystemBalanceGet', MethodType.Post, paramLong);
    if (res.dataObject.data[0]) {
      commitmentL = res.dataObject.data[0].current_commitment_amount;
      result.current_commitment_amount_l = commitmentL;
      result.facility_outstanding_lots_l = res.dataObject.data[0].facility_outstanding_lots;
    }
  }
  if (tradeType !== 'S') {
    res = await fetchData('FacilityAssetSettlement', 'FacilitySystemBalanceGet', MethodType.Post, paramShort);
    if (res.dataObject.data[0]) {
      commitmentSp = res.dataObject.data[0].current_commitment_amount;
      result.current_commitment_amount_sp = commitmentSp;
      result.facility_outstanding_lots_sp = res.dataObject.data[0].facility_outstanding_lots;
    }
  }
  if (tradeType === 'T') {
    result.available_commitment = Math.min(commitmentL, commitmentSp);
  } else if (tradeType === 'S') {
    result.available_commitment = commitmentL;
  } else if (tradeType === 'P') {
    result.available_commitment = commitmentSp;
  }
  return result;
};

export const parseNumber = (num: any) => {
  const trimmedNum = getTrimmedValue(num);
  if (trimmedNum !== '') {
    return ConvertDouble(trimmedNum);
  }
  return num;
};

export const toPascal = (s: string) => {
  return s.charAt(0).toUpperCase() + toCamelWithLowerCase(s.substring(1, s.length));
};

// The current objectToCamel() function doesn't handle the case when we are passing key with Upper case or first chracter in upper case.
// In this function, introduced a new parameter “withLowerCaseHandling” in the existing objectToCamel() function, It will pass the lower case key into toLower() function.
export const objectToCamel = (object: any, withLowerCaseHandling: boolean = false) => {
  let newObject = {};
  for (const key in object) {
    const strKey = withLowerCaseHandling ? key.toLowerCase() : key;
    newObject = {
      ...newObject,
      [toCamel(strKey)]: object[key],
    };
  }
  return newObject;
};

// The current toCamel() function doesn't handle the case when we are passing key with Upper case or first chracter in upper case.
// In this function, it will pass the key in lower case to toCamel() function.
export const toCamelWithLowerCase = (s: string) => {
  return toCamel(s.toLowerCase());
};

export const toCamel = (s: string) => {
  if (s === 'ftrade_id') {
    return 'fTradeId';
  }
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const ListContains = (list: string, delimiter: string, searchValue: any) => {
  const listItem = list.split(delimiter);
  for (const item of listItem) {
    if (item === searchValue) {
      return true;
    }
  }
  return false;
};

export const vrtsHeaderCreditAgreement = async (vrtsCreditAgreementId: number) => {
  let resultSet;
  if (vrtsCreditAgreementId !== undefined && vrtsCreditAgreementId !== 0) {
    const params: IGetParameterObject[] = [{ name: 'vrtsCreditAgreementId', value: vrtsCreditAgreementId, sequence: 1 }];
    resultSet = await fetchData(ControllerNameEnum.Facility, 'VrtsCreditAgreementGet', MethodType.Get, params);
    return resultSet.dataObject.data.length > 0 ? 'Credit Agreement Information: ' + resultSet.dataObject.data[0].credit_agreement_name : '';
  } else {
    return 'Credit Agreement ID is missing; cannot display header';
  }
};

export const validateDate = (date: any) => {
  const constUtcFormat1 = '^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z$';
  const constUtcFormat2 = '^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}$';
  const utcFormat1 = new RegExp(constUtcFormat1);
  const utcFormat2 = new RegExp(constUtcFormat2);
  const format = store.getState().globalRdcr.dateFormat;
  const parsedDateRange1 = parseDate('1-1-1900');
  const parsedDateRange2 = parseDate('12-31-9999');

  const isDateinRange = isDateInDateRange(date, parsedDateRange1, parsedDateRange2);
  return (moment.utc(date, format, true).isValid() || utcFormat1.test(date) || utcFormat2.test(date)) && isDateinRange;
};

export const getDateTimeString = (date: any) => {
  if (!date) {
    return '';
  }
  const dt = new Date(date);
  if (!dt) {
    return '';
  }
  return moment(dt).format('MM/DD/YYYY h:mm:ss A');
};

export const parseDate = (date: any) => {
  const constUtcFormat1 = '^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z$';
  const constUtcFormat2 = '^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}$';
  const utcFormat1 = new RegExp(constUtcFormat1);
  const utcFormat2 = new RegExp(constUtcFormat2);
  const format = store.getState().globalRdcr.dateFormat;
  let finalDate;
  if (utcFormat1.test(date) || utcFormat2.test(date)) {
    finalDate = moment.utc(date.toString().split('T')[0]).format().replace('Z', '');
  } else if (date instanceof Date) {
    finalDate = moment.utc(moment.utc(date.toLocaleDateString(), format).format().split('T')[0]).format().replace('Z', '');
  } else if (moment.utc(date, format).isValid()) {
    finalDate = moment.utc(moment.utc(date, format).format().split('T')[0]).format().replace('Z', '');
  } else {
    finalDate = date;
  }
  return finalDate;
};

export const localeDateString = (date: any) => {
  if (moment(date).isValid()) {
    const dt = new Date(parseDate(date)).toLocaleDateString();
    return dt;
  }
  return date;
};

export const getDatePart = (date: string, partNum?: number) => {
  if (date === undefined || date === null || date === '') {
    return '';
  } else {
    const dateParts = date.split('T');
    const dateIndex = partNum !== undefined ? partNum : 0;
    if (dateParts.length > 0) {
      return dateParts[dateIndex];
    } else {
      return date;
    }
  }
};

// amount is the number of days/months/years to be added
// unit has possible values of 'd','days','M','month' and 'Y'
export const addToDate = (date: any, amount: any, unit: string) => {
  date = parseDate(date);
  return parseDate(moment.utc(date).add(amount, unit).format());
};

export const replaceBlankWithNull = async (parameters: any) => {
  for (const parameter in parameters) {
    if (getTrimmedValue(parameters[parameter]) === '') {
      parameters[parameter] = null;
    }
  }
  return parameters;
};

export const dateParser = (date: string) => {
  const dt = date.replace(new RegExp('[ -.//]', 'g'), ' ').toLowerCase();
  const newDate = new Date(dt);
  if (moment(newDate).isValid()) {
    if (date.includes('2001')) {
      newDate.setFullYear(2001);
    } else {
      const getYear = newDate.getFullYear();
      const splittedString = date.split(' ');
      if (splittedString.length <= 2 && getYear === 2001) {
        newDate.setFullYear(2019);
      }
    }
    return parseDate(newDate);
  }
  return date;
};

// This removes trailing zeroes
export const toPrecision = (val: number | undefined, precision: number) => {
  return val != null ? Number(Number(val).toPrecision(precision)) : '';
};

export const getClientUTC = () => {
  const now = new Date();
  const offset: number = (now.getTimezoneOffset() / 60) * -1;
  return offset;
};

export const shallowCompare = (obj1: any, obj2: any): boolean => {
  if (!obj1 || !obj2) {
    return obj1 === obj2;
  }
  if (typeof obj1 === typeof obj2 && typeof obj1 === 'object') {
    for (const key of Object.keys(obj1)) {
      if (!(key in obj2 && obj1[key] === obj2[key])) {
        return false;
      }
    }
    return true;
  } else {
    return obj1 === obj2;
  }
};

export const getCurrentDate = () => {
  return parseDate(new Date());
};

export const getCurrentDateTime = () => {
  return new Date().toLocaleString();
};

export const getDateTimeKey = () => {
  const currentDate = new Date();
  return (
    currentDate.getFullYear() +
    currentDate.getMonth() +
    currentDate.getDay() +
    currentDate.getHours() +
    currentDate.getMinutes() +
    currentDate.getSeconds() +
    currentDate.getMilliseconds()
  );
};

export const getUsername = () => store.getState().globalRdcr.username;

export const getUserFullName = () => store.getState().globalRdcr.userFullName;

export const getEntityId = () => {
  const entityId = store.getState().globalRdcr.entityId;
  return entityId === undefined ? 0 : entityId;
};

export const getCurrentId = () => {
  return store.getState().globalRdcr.currentId;
};

export const isValidJson = (stringToCheck: string): boolean => {
  try {
    JSON.parse(stringToCheck);
  } catch {
    return false;
  }
  return true;
};

export const getJson = (data: any): any => {
  try {
    return JSON.parse(getTrimmedValue(data));
  } catch {
    return undefined;
  }
};

export const showErrorMessage = (message: string) => {
  store.dispatch({ type: SHOW_ERROR, data: { showError: true, errorMessage: message } });
};

export const setIsBackClicked = (isBackClicked: boolean) => {
  store.dispatch({ type: IS_BACK_CLICKED, data: isBackClicked });
};

export const showToastNotification = (message: string, type: AppearanceTypes, navigateTo?: string, headerText?: string) => {
  store.dispatch({ type: SHOW_TOAST, data: { showToast: true, toastMessage: message, toastType: type, navigateTo, headerText } });
};

export const showInformationMessage = (message: string) => {
  store.dispatch({ type: SHOW_MESSAGE, data: { showMessage: true, errorMessage: message } });
};

export const showCustomInformationMessage = (message: string, header: string, allowCopy?: boolean) => {
  store.dispatch({ type: SHOW_MESSAGE, data: { showMessage: true, errorMessage: message, header, allowCopy } });
};

export const showGridLayoutEdit = (data: any) => {
  store.dispatch({ type: 'SHOW_LAYOUT_EDIT', data });
};

export const isEqualProps = (props1: any, props2: any) => {
  return _.isEqual(_.omit(props1, _.functions(props1)), _.omit(props2, _.functions(props2)));
};

export const setResponseFields = (response: IResponseObject) => {
  if (response.dataObject.data.length > 0) {
    const operationResultCode = response.dataObject.data[0][dbConstOperationResultCode];
    const operationMessage = response.dataObject.data[0][dbConstOperationMessage];
    const operationDetails = response.dataObject.data[0][dbConstOperationDetails];
    const actionMessageCode = response.dataObject.data[0][dbConstActionMessageCode];
    if (ConvertDouble(operationResultCode) || getTrimmedValue(operationMessage)) {
      let message = operationMessage;
      if (message === undefined || message === null) {
        message = '';
      }
      if (getTrimmedValue(operationDetails)) {
        message += '\n' + operationDetails;
      }

      if (getTrimmedValue(actionMessageCode)) {
        switch (actionMessageCode) {
          case 'ARE_YOU_SURE':
            response.confirmationMessage = [response.dataObject.data[0][dbConstOperationMessage], operationDetails];
            response.hasConfirmationMessage = true;
            break;
          case 'DEL_NOT_ALLOWED':
            if (operationMessage) {
              message = operationMessage;
            }
            message += '\n\nDelete operation is not allowed.';
            response.errorMessage = message;
            response.hasError = true;
        }
        return;
      } else {
        response.errorMessage = message;
        response.hasError = true;
      }
    }
  }
};

export const rsLookup = (data: any[], key: string) => {
  if (data.length > 0) {
    return key in data[0] ? data[0][key] : '';
  }
  return '';
};

export const splitPath = (path: string) => {
  const indexArray: number[] = [];
  indexArray.push(path.indexOf('Id/'));
  indexArray.push(path.indexOf('countryCode/'));
  indexArray.push(path.indexOf('providerKeyValue/'));
  indexArray.push(path.indexOf('ratingagency/'));
  indexArray.push(path.indexOf('amountType/'));
  indexArray.push(path.indexOf('searchText/'));
  indexArray.push(path.indexOf('searchType/'));
  indexArray.push(path.indexOf('searchStringType/'));

  const filtered = indexArray.filter((i: number) => i > 0);
  let index = filtered.length > 0 ? Math.min(...filtered) : -1;

  let pathArray: any = { rootPath: path };
  if (index !== -1) {
    index = path.lastIndexOf('/', index);
    pathArray = { rootPath: path.substring(0, index), query: path.substring(index) };
  }
  return pathArray;
};

export const setCookie = (key: string, value: string) => {
  document.cookie = `${key}=${value}`;
};

export const getCookie = (key: string) => {
  const cookieString = document.cookie;
  const cookieArray = cookieString.split(';');
  for (const cookie of cookieArray) {
    if (getTrimmedValue(cookie.split('=')[0]) === key) {
      return cookie.split('=')[1];
    }
  }
  return undefined;
};

export const isAccessAllowed = async (objectName: string, atCode: number) => {
  const result = await fetchData(ControllerNameEnum.CommonFunctions, 'CdosaUserObjectAccess', MethodType.Post, { userId: getUsername(), objectName });
  return result.dataObject.data.length > 0 && Number(getTrimmedValue(result.dataObject.data[0].at_code)) >= atCode;
};

export const isInRange = (value: any, description: string, lowerValue: any, higherValue: any) => {
  let returnValue: boolean = false;
  if (value !== '') {
    if (!Number(value) && validateDate(value)) {
      if (getTrimmedValue(lowerValue) === '') {
        returnValue = true;
      } else {
        if (dateDiff('d', lowerValue, value) < 0) {
          showErrorMessage(`Value for "${description}" must be on or after ${localeDateString(lowerValue)}.`);
          returnValue = false;
        } else {
          returnValue = true;
        }
      }
      if (getTrimmedValue(higherValue) !== '' && returnValue) {
        if (dateDiff('d', value, higherValue) < 0) {
          showErrorMessage(`Value for "${description}" must be on or before ${localeDateString(higherValue)}.`);
          returnValue = false;
        } else {
          returnValue = true;
        }
      }
    } else if (!isNaN(value)) {
      if (getTrimmedValue(lowerValue) === '') {
        returnValue = true;
      } else {
        if (ConvertDouble(value) < ConvertDouble(lowerValue)) {
          showErrorMessage(`Value for "${description}" must be greater than or equal to ${formatNumberCustom(lowerValue, 2)}.`);
          returnValue = false;
        } else {
          returnValue = true;
        }
      }
      if (getTrimmedValue(higherValue) !== '' && returnValue) {
        if (ConvertDouble(value) > ConvertDouble(higherValue)) {
          showErrorMessage(`Value for "${description}" must be lower than or equal to ${formatNumberCustom(higherValue, 2)}.`);
          returnValue = false;
        } else {
          returnValue = true;
        }
      }
    } else {
      showErrorMessage(`Field "${description}" must be numeric or a date.`);
      returnValue = false;
    }
  } else {
    returnValue = true;
  }
  return returnValue;
};

/*
 ** The function compares the 2 objects.
 ** Input:
 **     1. oObjectToValidate - The object being validated.
 **			2. sObjectToValidateDesc - The description for the object to validate (for display purposes)
 **			3. oObjectToCompare - The object being compared.
 **			4. sObjectToCompareDesc - the description for the object to compare (for display purposes)
 **			5. sDataType - the datatype for the objects, which can only be one of the following: "DATE", "NUMERIC", "TEXT"
 **			6. nCompareOperator - type of comparison. Possible values (integers only):
 **					 2 = Greater than or equal to
 **					 1 = Great than
 **					 0 = Equal to
 **					-1 = Less than
 **					-2 = Less than or equal to
 **			7. bValidDataRequired - boolean value to determine if the validation should be skipped or not if either/both of the controls have inavlid/empty value
 **			9. bReturnCode - boolean value passed in from the previous validation (determines if validation should continue)
 ** Output: This function returns a boolean value of either True (if the comparison condition is met) or False (if the comparison condition is not met) */
export const compareObjects = (
  oObjectToValidate: any,
  sObjectToValidateDesc: string,
  oObjectToCompare: any,
  sObjectToCompareDesc: string,
  sDataType: string,
  nCompareOperator: number,
  bValidDataRequired: boolean,
  bReturnCode: any,
  showError: boolean = true,
) => {
  let returnValue = bReturnCode;
  const dataType = getTrimmedValue(sDataType).toUpperCase();
  let vObjectToValidate;
  let vObjectToCompare;
  let sEqualToMSg;
  let sGrtrThanMsg;
  let sGrtrThanEqualMsg;
  let sLessThanMsg;
  let sLessThanEqualMsg;
  let errorMessage = '';

  let bPerformComparision;
  if (bReturnCode) {
    bPerformComparision = true;

    // Basic Validation
    if (getTrimmedValue(oObjectToValidate) === '' && getTrimmedValue(oObjectToCompare) === '') {
      bPerformComparision = false;
      returnValue = bValidDataRequired ? false : true;
    }
    switch (dataType) {
      case 'DATE':
        if (!validateDate(oObjectToValidate) || !validateDate(oObjectToCompare)) {
          bPerformComparision = false;
          returnValue = bValidDataRequired ? false : true;
        }
        break;
      case 'NUMERIC':
      case 'CURRENCY':
      case 'PERCENT':
        if (!Number(oObjectToValidate) && !Number(oObjectToCompare)) {
          bPerformComparision = false;
          returnValue = bValidDataRequired ? false : true;
        }
        break;
    }

    // Comparision
    if (bPerformComparision) {
      switch (dataType) {
        case 'DATE':
          vObjectToValidate = parseDate(oObjectToValidate);
          vObjectToCompare = parseDate(oObjectToCompare);
          sEqualToMSg = '" must be the same as "';
          sGrtrThanMsg = '" must be after "';
          sGrtrThanEqualMsg = '" must be on or after "';
          sLessThanMsg = '" must be before "';
          sLessThanEqualMsg = '" must be on or before "';
          break;
        case 'NUMERIC':
        case 'CURRENCY':
        case 'PERCENT':
          vObjectToValidate = ConvertDouble(oObjectToValidate);
          vObjectToCompare = ConvertDouble(oObjectToCompare);
          sEqualToMSg = '" must be equal to "';
          sGrtrThanMsg = '" must be greater than "';
          sGrtrThanEqualMsg = '" must be greater than or equal to "';
          sLessThanMsg = '" must be less than "';
          sLessThanEqualMsg = '" must be less than or equal to "';
          break;
        case 'TEXT':
          vObjectToValidate = getTrimmedValue(oObjectToValidate);
          vObjectToCompare = getTrimmedValue(oObjectToCompare);
          sEqualToMSg = '" must be the same as "';
          sGrtrThanMsg = '" must be greater than "';
          sGrtrThanEqualMsg = '" must be greater than or equal to "';
          sLessThanMsg = '" must be less than "';
          sLessThanEqualMsg = '" must be less than or equal to "';
          break;
      }

      switch (nCompareOperator) {
        case 0: // Equal To comparision
          if (vObjectToValidate === vObjectToCompare) {
            returnValue = true;
          } else {
            returnValue = false;
            errorMessage = '"' + sObjectToValidateDesc + sEqualToMSg + sObjectToCompareDesc + '".';
          }
          break;
        case 1: // Greater than comparision
          if (vObjectToValidate > vObjectToCompare) {
            returnValue = true;
          } else {
            returnValue = false;
            errorMessage = '"' + sObjectToValidateDesc + sGrtrThanMsg + sObjectToCompareDesc + '".';
          }
          break;
        case 2: // Greater than or equal to comparision
          if (vObjectToValidate >= vObjectToCompare) {
            returnValue = true;
          } else {
            returnValue = false;
            errorMessage = '"' + sObjectToValidateDesc + sGrtrThanEqualMsg + sObjectToCompareDesc + '".';
          }
          break;
        case -1: // Less than comparision
          if (vObjectToValidate < vObjectToCompare) {
            returnValue = true;
          } else {
            returnValue = false;
            errorMessage = '"' + sObjectToValidateDesc + sLessThanMsg + sObjectToCompareDesc + '".';
          }
          break;
        case -2: // Less than or equal to comparision
          if (vObjectToValidate <= vObjectToCompare) {
            returnValue = true;
          } else {
            returnValue = false;
            errorMessage = '"' + sObjectToValidateDesc + sLessThanEqualMsg + sObjectToCompareDesc + '".';
          }
          break;
      }
      if (getTrimmedValue(errorMessage) !== '' && showError) {
        showErrorMessage(errorMessage);
      }
    }

    return showError ? returnValue : errorMessage;
  }
};

export const getCCTParamMethodMapping = (methodName: string, params: any, useParamArray: boolean, useEntityId: boolean) => {
  let methodType: MethodType = MethodType.Get;
  let controllerName = '';
  let methodNameTemp = methodName;
  let parameter: any = '';
  switch (methodName.toUpperCase()) {
    case 'DEBTNOTELIST':
    case 'NEXACCOUNTLIST':
    case 'NEXPORTFOLIOLIMITATIONTESTSLIST':
    case 'NEXMOODYSMAXRATINGFACTORGROUPLIST':
    case 'NEXGWATESTSLIST':
    case 'NEXPARVALUEADJUSTMENTLIST':
    case 'NEXDEALVARIABLEANDTESTLIST':
    case 'NEXDEALTESTRESULTXREFLIST':
      methodType = MethodType.Get;
      controllerName = ControllerNameEnum.Compliance;
      parameter = useEntityId ? (useParamArray ? [{ name: 'entityId', value: params.entityId, sequence: 1 }] : params.entityId) : '';
      break;
    case 'NEXDEALTESTLIST':
      methodNameTemp = 'NEXDealTestList';
      methodType = MethodType.Get;
      controllerName = ControllerNameEnum.Compliance;
      parameter = useEntityId ? params.entityId : '';
      break;
    case 'MVSCENARIOLIST':
    case 'MVCLASSLIST':
      methodType = MethodType.Get;
      controllerName = ControllerNameEnum.VirtusMarketValue;
      parameter = useEntityId ? params.entityId : '';
      break;
    case 'NEXISSUERATINGLIST':
    case 'NEXISSUERRATINGLIST':
    case 'NEXEXPRESSIONNUMERICCURRENCYFIELDSLIST':
    case 'NEXRATINGAGENCYLIST':
    case 'NEXPSCASHTRANSACTIONTYPELIST':
    case 'NEXEXPRESSIONFIELDSLIST':
    case 'NEXPSDETAILDATASOURCEGET':
    case 'NEXEXPRESSIONISSUERUDFFIELDSLIST':
      methodType = MethodType.Get;
      controllerName = ControllerNameEnum.Compliance;
      parameter = '';
      break;
    case 'VRTSCDOBIZDATADICTCURRENCYLIST':
    case 'VRTSCDOBIZDATADICTITEMCURRENCYLIST':
      methodType = MethodType.Get;
      controllerName = ControllerNameEnum.DealInfo;
      parameter = '';
      break;
    case 'NEXUDFLIST':
      methodType = MethodType.Get;
      controllerName = ControllerNameEnum.Compliance;
      parameter = '';
      break;
    case 'NEXPSLAYOUTLIST':
    case 'NEXPSCONCENTRATIONFIELDLIST':
    case 'NEXDEALVARIABLELIST':
    case 'NEXDEALVARIABLELISTFORDEALTEST':
      methodType = MethodType.Post;
      controllerName = ControllerNameEnum.Compliance;
      parameter = useEntityId ? { entityId: params.entityId, dealTestId: params.dealTestId ? params.dealTestId : undefined } : '';
      break;
    case 'CASHACCOUNTLIST':
      methodType = MethodType.Post;
      controllerName = ControllerNameEnum.DealInfo;
      parameter = useEntityId ? { entityId: params.entityId } : '';
      break;
    case 'VRTSPBMETHODLIST':
      methodType = MethodType.Get;
      methodNameTemp = 'VrtsPbMethodList';
      controllerName = ControllerNameEnum.DealInfo;
      parameter = useEntityId ? `?entityId=${params.entityId}` : '';
      break;
    case 'NEXLOOKUPCODELIST':
      methodType = MethodType.Post;
      controllerName = ControllerNameEnum.Compliance;
      parameter = { lookupTypeId: params.defaultParameter };
      break;
    case 'CDOSYSLOOKUPCODELIST':
      methodType = MethodType.Get;
      controllerName = ControllerNameEnum.Generic;
      parameter = useParamArray ? [{ name: 'lookupTypeId', value: params.defaultParameter, sequence: 1 }] : params.defaultParameter;
      break;
    case 'ACCOUNTLIST':
      methodType = MethodType.Post;
      controllerName = ControllerNameEnum.Settlement;
      parameter = useEntityId ? { entityId: params.entityId } : '';
      break;
    case 'DEALTESTUSEBALANCEOPTIONS':
    case 'DEALTESTCURRENCYOPTIONS':
      methodType = MethodType.Get;
      controllerName = ControllerNameEnum.Compliance;
      parameter = useEntityId ? `?entityId=${params.entityId}` : '';
      break;
  }
  return {
    controllerName,
    methodName: methodNameTemp,
    methodType,
    parameter,
  };
};

export const getMenuInfo = (menuId: number) => {
  return { ...store.getState().globalRdcr.defaultPathMap[menuId] };
};

export const getMenuIdByPath = (path: string) => {
  const paths = splitPath(path);
  const defaultPathMap = store.getState().globalRdcr.defaultPathMap;
  let menuId = '-10000';
  const pathArray = paths.rootPath.split('/');
  pathArray.splice(-1, 1);
  path = pathArray.join('/');
  for (const menu of Object.keys(defaultPathMap)) {
    if (defaultPathMap[menu].fullPath.replace(new RegExp(defaultPathMap[menu].urlPage + '$'), '') === path) {
      if (menuId === '-10000' || defaultPathMap[menu].type === 'T') {
        menuId = menu;
      }
    }
  }
  return menuId !== '-10000' ? menuId : undefined;
};

export const getSaAEDCode = () => {
  const ACode = store.getState().globalRdcr.allowAdd;
  const ECode = store.getState().globalRdcr.allowEdit;
  const DCode = store.getState().globalRdcr.allowDelete;
  const SaAEDCode = `${ACode ? 'A' : ''}${ECode ? 'E' : ''}${DCode ? 'D' : ''}`;
  return SaAEDCode;
};

export const getSaAtCode = () => {
  return store.getState().globalRdcr.saAtCode;
};

export const openReportWindow = (sysUseRsProxy: string, rsServer: string, rsPath: string, rptPath: string, rptTitle: string, parametersList: any) => {
  if (getTrimmedValue(sysUseRsProxy) !== '1') {
    const targetURL =
      rsServer +
      '?' +
      rsPath +
      '&rs:ClearSession=true&rs:Command=Render&rc:Parameters=False&rc:DocMap=False&rc:Stylesheet=CDOSuiteHTMLViewer&rpt_title=' +
      rptTitle +
      '' +
      parametersList +
      '&key=' +
      config.actuateKey;
    window.open(targetURL);
  } else {
    const newParam = parametersList.replace('rs:Format', 'Format');
    const targetURL =
      getTrimmedValue(sysUseRsProxy) +
      rsServer +
      '?' +
      rsPath +
      '&rs:ClearSession=true&rs:Command=Render&rc:Parameters=False&rc:DocMap=False&rc:Stylesheet=CDOSuiteHTMLViewer&rpt_title=' +
      rptTitle +
      '' +
      newParam +
      '&key=' +
      config.actuateKey;
    window.open(targetURL);
  }
};

export const getEntityName = (entityId: any) => {
  const dealsList = store.getState().dealRdcr.dealsList;
  let entityName = '';
  if (dealsList.dataObject.data.length > 0) {
    for (const data of dealsList.dataObject.data) {
      if (data.ID === getTrimmedValue(entityId)) {
        entityName = getTrimmedValue(data.deal_nickname);
      }
    }
  }
  return entityName;
};

export const formatTotalRow = (event: any, columnName: string) => {
  if (event.data && columnName in event.data && !event.data[columnName]) {
    if (event.column.dataField === columnName) {
      event.cellElement.innerHTML = 'Total:';
    }
    event.cellElement.style.fontWeight = 'bold';
  }
};

export const getPageTitle = (menuId: any): string => {
  if (ConvertDouble(menuId) === 0) {
    return '';
  }
  const menu = store.getState().globalRdcr.defaultPathMap[menuId];
  const parentPath = getPageTitle(menu.parentId);
  if (menu.type === 'M') {
    return parentPath ? parentPath + ' >> ' + menu.caption : menu.caption;
  }
  return parentPath;
};

export const dateDiff = (diffType: string, date1: Date, date2: Date): number => {
  let diff: number = 0;
  switch (diffType.toLowerCase()) {
    case 'd':
      diff = moment.duration(moment(date2).diff(moment(date1))).days();
      break;
    case 'm':
      diff = moment.duration(moment(date2).diff(moment(date1))).months();
      break;
    case 'y':
      diff = moment.duration(moment(date2).diff(moment(date1))).years();
      break;
  }
  return diff;
};

export const showUnsavedDataAlert = (title?: string) => {
  store.dispatch({
    data: { showError: true, errorMessage: `${getTrimmedValue(title)} Grid has unsaved data. Save/Undo changes before performing any other operation.` },
    type: SHOW_ERROR,
  });
};

export const handleResponse = (response: IResponseObject) => {
  if (response.hasError) {
    return [];
  }
  return response.dataObject.data;
};

export const setGridColumnAlignment = (event: any, columnNames: string[], alignment: string) => {
  if (event.column && 'dataField' in event.column && columnNames.includes(event.column.dataField)) {
    event.cellElement.style.textAlign = alignment;
  }
};

export const formatExpressionText = (sValue: string) => {
  let returnValue = sValue && sValue !== null ? sValue : '';
  returnValue = returnValue.replace(new RegExp('\\(', 'g'), '<b style="color:#0d83c9">(</b>');
  returnValue = returnValue.replace(new RegExp('\\)', 'g'), '<b style="color:#0d83c9">)</b>');
  returnValue = returnValue.replace(new RegExp('AND', 'g'), '<br/>AND');
  returnValue = returnValue.replace(new RegExp('OR', 'g'), '<br/>OR');
  returnValue = returnValue.replace(new RegExp('\\[', 'g'), '<span style="background-color:rgba(255, 178, 0, 0.2);">[');
  returnValue = returnValue.replace(new RegExp('\\]', 'g'), ']</span>');
  returnValue = returnValue.replace(new RegExp('\\{', 'g'), '<span style="background-color:rgba(0, 255, 242, 0.2);">{');
  returnValue = returnValue.replace(new RegExp('\\}', 'g'), '}</span>');
  return returnValue;
};

export const readInputFile = (fileObject: any, filetype: FileType, sheetName: any) => {
  let jsonData: any = {};
  jsonData[sheetName] = [];
  return new Promise((resolve: any) => {
    const reader = new FileReader();
    switch (filetype) {
      case FileType.XLSX:
        reader.readAsArrayBuffer(fileObject);
        reader.onload = () => {
          const result: any = reader.result;
          jsonData = readExcelFile(result, sheetName);
          resolve(jsonData);
        };
        break;
      case FileType.CSV:
        reader.readAsText(fileObject);
        reader.onload = () => {
          const result: any = reader.result;
          jsonData = csvToJsonObject(result, sheetName);
          resolve(jsonData);
        };
        break;
      default:
        resolve(jsonData);
    }
  }).catch(() => {
    jsonData = {};
    jsonData[sheetName] = [];
  });
};

const readExcelFile = async (binaryString: any, sheetName: any) => {
  const jsonData: any = {};
  jsonData[sheetName] = [];
  const workbook = new Excel.Workbook();
  try {
    await workbook.xlsx.load(binaryString);
  } catch (e) {
    jsonData.readError = true;
    jsonData.errorMessage = e.message;
    return jsonData;
  }
  let worksheet: any;
  for (let sheet of workbook.worksheets) {
    if (sheet.name.toLowerCase() === sheetName.toLowerCase()) {
      worksheet = sheet;
      break;
    }
  }
  if (!worksheet) {
    jsonData.hasError = true;
    jsonData.errorMessage = 'Sheet Not Found';
    return jsonData;
  }
  const header = worksheet.getRow(1);
  worksheet.eachRow({ includeEmpty: true }, (row: any, rowNumber: any) => {
    if (rowNumber !== 1) {
      const obj: any = {};
      header.eachCell({ includeEmpty: true }, (headerCell: any, cellIndex: number) => {
        const key = getTrimmedValue(headerCell.value);
        if (key !== '') {
          const rowCell = row.getCell(cellIndex);
          obj[key] = rowCell ? (rowCell.type === 4 ? getTrimmedValue(rowCell.value.toJSON().split('T')[0]) : getTrimmedValue(rowCell.value)) : '';
        }
      });
      jsonData[sheetName].push(obj);
    }
  });
  return jsonData;
};

const csvToJsonObject = async (csvString: any, objectName: any) => {
  const jsonData: any = {};
  jsonData[objectName] = [];
  await csvtojson()
    .fromString(csvString)
    .then((result) => {
      jsonData[objectName] = result;
    });
  return jsonData;
};

// fixes the js rounding error after operations like add , sub etc
// For ex 0.1 + 0.2 = 0.30000000000000004
// this func is a workaround to get rid of the rounding error (0.1 + 0.2 = 0.3)
export const fixNum = (num: number) => {
  return +num.toFixed(14);
};

// Make use of the fixNum method while adding the args passed
export const addNum = (...args: any[]) => {
  let res: number = 0;
  [...args].forEach((element: any) => {
    res += ConvertDouble(element);
    res = fixNum(res);
  });
  return res;
};

// Make use of the fixNum method while subtracting the args passed
export const subNum = (...args: any[]) => {
  let res: number = 0;
  [...args].forEach((element: any) => {
    res -= ConvertDouble(element);
    res = fixNum(res);
  });
  return res;
};

// Make use of the fixNum method while multiplying  the args passed
export const mulNum = (...args: any[]) => {
  let res: number = 1;
  [...args].forEach((element: any) => {
    res *= ConvertDouble(element);
    res = fixNum(res);
  });
  return res;
};

export const getIconNameFromPath = (path: string) => {
  const aPath = path.split('/');
  if (aPath.length > 1) {
    const aImage = aPath[aPath.length - 1].split('.');
    if (aImage.length > 1) {
      return aImage[0];
    }
  }
};

export const isRFR = (couponIndex: any, couponDataType: CouponIndex, rfrTypes: string): boolean => {
  let returnValue: boolean = false;
  if (couponIndex !== undefined && rfrTypes !== undefined && rfrTypes !== null) {
    const aryIndex = rfrTypes.split('|');
    for (let index = 0; index < aryIndex.length; index++) {
      const aryIndexSub = aryIndex[index].split(';');
      if (getTrimmedValue(aryIndexSub[couponDataType]) === getTrimmedValue(couponIndex)) {
        returnValue = true;
        break;
      }
    }
  }
  return returnValue;
};

export const RFRIndexType = (couponIndex: any, couponIndexList: any, rfrTypes: string): string => {
  let couponIndexType: string = '';

  if (couponIndex !== undefined && couponIndex !== '' && rfrTypes !== undefined && rfrTypes !== null) {
    const couponIndexData = couponIndexList.filter((row: any) => getTrimmedValue(row.code) === getTrimmedValue(couponIndex));
    if (couponIndexData.length > 0 && isRFR(couponIndexData[0].group_code, CouponIndex.CouponIndexType, rfrTypes)) {
      couponIndexType = couponIndexData[0].group_code;
    }
  }

  return couponIndexType;
};

export const isDailyIntCalcMethod = (intCalcMethod: string): boolean => {
  return intCalcMethod === 'CDAB' || intCalcMethod === 'CDAR' || intCalcMethod === 'SIA' ? true : false;
};

export const getCouponIamId = (couponIndexType: string): string => {
  return couponIndexType === 'SOFR' ? 'A0' : couponIndexType === 'SONA' ? 'A5' : '';
};

export const getSSRSUrl = (reportPath: string, params: any, reportFormat: string) => {
  //let url = `https://${config.ssrsServer}.virtusapp.local/ReportServer?/${reportPath}`;

  let url = `https://${config.ssrsServer}.virtusapp.local/ReportServer/Pages/ReportViewer.aspx?/${reportPath}`;

  for (let key of Object.keys(params)) {
    url += `&${key}=${params[key]}`;
  }

  //url += `&rs:Format=${reportFormat}`;

  console.log('url:', url);
  return url;
};
