import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Search } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';
import { getTrimmedValue } from './helperFunctions';

export const showTestResult = (result: string) => {
  switch (getTrimmedValue(result).substr(0, 1)) {
    case 'F':
      return <b style={{ color: '#c0392b' }}>{result}</b>;
    case 'P':
      return <b style={{ color: '#57b124' }}>{result}</b>;
    default:
      return <b style={{ color: '#9f9f9f' }}>{result}</b>;
  }
};

export const RuleBox: any = styled.div`
  border: solid 1px #333b43;
  color: black;
  background-color: #fff;
  max-height: 400px;
  min-height: 16px;
  overflow-y: scroll;
  padding: 2px;
  max-width: 800px;
`;

export const LineType: any = styled.span`
  font-size: 10px;
  height: 15px;
  background-color: darkgrey;
  color: white;
  padding: 0px 4px;
  border-radius: 10px;
  font-weight: bold;
`;

export const CLEField: any = styled.span`
  background-color: #ffffd3;
  color: BLACK;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
  margin: 0px 4px;
`;

export const CLELogicExpr: any = styled.span`
  color: RED;
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
`;

export const REFLogicExpr: any = styled.span`
  background-color: #ffeaea;
  color: black;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
  margin: 0px 4px;
`;

export const Header: any = styled.h6`
  color: var(--text);
  font-size: 14px;
  font-weight: bold;
`;

export const PageLink: any = styled.h6`
  color: var(--text);
  cursor: pointer;
  display: inline;
  font-size: var(--main-font-size);
  :hover {
    color: var(--light-blue);
    text-decoration: underline;
  }
`;

export const PageDiv: any = styled.div`
  color: var(--text);
`;

export const MessageDiv: any = styled.div`
  border: solid 1px #333b43;
  padding: 2px;
  height: 100px;
  overflow: auto;
`;

export const searchComponent = (onSearchClicked: any) => {
  return (
    <Tooltip title="Search">
      <Search onClick={onSearchClicked} style={{ height: '20px', cursor: 'pointer', marginLeft: '5px', width: '20px', color: 'rgb(13, 131, 201)' }} />
    </Tooltip>
  );
};

export const StyledTooltip = withStyles({
  popper: {
    zIndex: 1501,
  },
})(Tooltip);

export const handleAsteriskConditions = (value: any) => {
  return !Boolean(value) ? (
    <span style={{ color: 'var(--red)' }}>
      <b>*</b>
    </span>
  ) : (
    <span style={{ color: 'var(--white)' }}>
      <b>*</b>
    </span>
  );
};

const divStyle: any = { display: 'flex', flexDirection: 'row', paddingLeft: '20px' };
const innerDivStyle: any = { textAlign: 'right', marginTop: 'auto', marginBottom: 'auto', marginRight: '2px', fontSize: '12px' };

export const StartEndDateStyledComponentRow = (beginDateComp: any, endDateComp: any) => {
  return (
    <tr>
      <td style={{ verticalAlign: 'top' }}>Start/End Date</td>
      <td>
        <div style={divStyle}>
          <div style={{ ...innerDivStyle, width: '27px' }}>Start</div>
          {beginDateComp}
        </div>
        <div style={divStyle}>
          <div style={{ ...innerDivStyle, width: '27px' }}>End</div>
          {endDateComp}
        </div>
      </td>
    </tr>
  );
};

export const CharacterLimitStyledComponentRow = (beginCharComp: any, endCharComp: any, label: string) => {
  return (
    <tr>
      <td style={{ verticalAlign: 'top' }}>{label}</td>
      <td>
        <div className="characterLimit">
          <div style={{ width: '30px' }}>{beginCharComp}</div>
          {endCharComp}
        </div>
      </td>
    </tr>
  );
};

export const PeriodBeginEndDateStyledComponentRow = (beginDateComp: any, endDateComp: any) => {
  return (
    <tr>
      <td style={{ verticalAlign: 'top' }}>Period Begin/End Date</td>
      <td>
        <div style={divStyle}>
          <div style={{ ...innerDivStyle, width: '27px' }}>Begin</div>
          {beginDateComp}
        </div>
        <div style={divStyle}>
          <div style={{ ...innerDivStyle, width: '27px' }}>End</div>
          {endDateComp}
        </div>
      </td>
    </tr>
  );
};

export const PaymentBeginEndDateStyledComponentRow = (beginDateComp: any, endDateComp: any) => {
  return (
    <tr>
      <td style={{ verticalAlign: 'top' }}>Payment Start/End Date</td>
      <td>
        <div style={divStyle}>
          <div style={{ ...innerDivStyle, width: '27px' }}>Start</div>
          {beginDateComp}
        </div>
        <div style={divStyle}>
          <div style={{ ...innerDivStyle, width: '27px' }}>End</div>
          {endDateComp}
        </div>
      </td>
    </tr>
  );
};

export const StartEndDateCustomStyledComponentRow = (beginDateComp: any, endDateComp: any, labelTitleDate: string, labelStartDate: string, labelEndDate: string) => {
  return (
    <tr>
      <td style={{ verticalAlign: 'top' }}>{labelTitleDate}</td>
      <td>
        <div style={divStyle}>
          <div style={{ ...innerDivStyle, width: '27px' }}>{labelStartDate}</div>
          {beginDateComp}
        </div>
        <div style={divStyle}>
          <div style={{ ...innerDivStyle, width: '27px' }}>{labelEndDate}</div>
          {endDateComp}
        </div>
      </td>
    </tr>
  );
};

export const RangeStyledComponentRow = (minRangeComp: any, maxRangeComp: any, label: string, fromLabel?: string) => {
  return (
    <tr>
      <td style={{ verticalAlign: 'top' }}>{label}</td>
      <td>
        <div style={divStyle}>
          <div style={{ ...innerDivStyle, width: '77px' }}>{fromLabel ? fromLabel : 'From/Equal To'}</div>
          {minRangeComp}
        </div>
        <div style={divStyle}>
          <div style={{ ...innerDivStyle, width: '77px' }}>To</div>
          {maxRangeComp}
        </div>
      </td>
    </tr>
  );
};

export const InlineRowComponent = (component1: any, component2: any, label: string, divId?: string) => {
  return (
    <tr>
      <td style={{ verticalAlign: 'top' }}>{label}</td>
      <td>
        <div id={divId ? divId : 'divComponent'} style={{ display: 'flex', flexDirection: 'row', paddingLeft: '18px' }}>
          {component1}
          {component2}
        </div>
      </td>
    </tr>
  );
};

export const headerKeyValuePair = (data: any[], valueColor?: string) => {
  const returnValue: any[] = [];
  for (const item of data) {
    returnValue.push(<span style={{ color: 'var(--accent-grey)' }}>{item.key + ': '}</span>);
    returnValue.push(' ');
    returnValue.push(<span style={{ color: valueColor ? valueColor : 'var(--text)' }}>{item.value}</span>);
    returnValue.push('  ');
  }
  return <div>{returnValue}</div>;
};
