import { navigate } from '@reach/router';
import check from 'images/check-light.svg';
import darkClose from 'images/close-dark.svg';
import error from 'images/error-white.svg';
import React from 'react';
import { AppearanceTypes, useToasts } from 'react-toast-notifications';
import './ToastNotification.css';

interface IToastDetail {
  message: string;
  navigateTo: string;
  type: AppearanceTypes;
  id: string;
  headerText?: string;
}

interface IProps {
  toastDetails: IToastDetail[];
  currentToast: IToastDetail;
  closeToast: (id: string) => void;
}

const ToastNotification = ({ toastDetails, closeToast, currentToast }: IProps) => {
  const { addToast, removeAllToasts, removeToast } = useToasts();
  const didMount = React.useRef(false);
  const removeToastHandler = (tId: string, id: string) => {
    removeToast(tId);
    closeToast(id);
  };
  React.useEffect(() => {
    if (!didMount.current) {
      removeAllToasts();
      (toastDetails || []).forEach((details: IToastDetail) => {
        let tId: string;
        addToast(
          <ToastContent
            headerText={details.headerText}
            appearance={details.type}
            message={details.message}
            navigateTo={details.navigateTo}
            onClose={() => removeToastHandler(tId, details.id)}
          />,
          {
            appearance: details.type || 'success',
            autoDismiss: false,
          },
          (id: string) => (tId = id),
        );
      });
      didMount.current = true;
    } else if (currentToast) {
      let tId: string;
      addToast(
        <ToastContent
          headerText={currentToast.headerText}
          appearance={currentToast.type}
          message={currentToast.message}
          navigateTo={currentToast.navigateTo}
          onClose={() => removeToastHandler(tId, currentToast.id)}
        />,
        {
          appearance: currentToast.type || 'success',
          autoDismiss: false,
        },
        (id: string) => {
          tId = id;
        },
      );
    }
  }, [toastDetails, addToast, removeAllToasts, currentToast]);

  return <></>;
};

const ToastContent = ({ message, navigateTo, onClose, appearance, headerText }: any) => {
  return (
    <div className={'toast-content'}>
      <p>{headerText ? headerText : appearance === 'success' ? 'Success!' : 'Failure'}</p>
      <div>
        <img src={darkClose} alt="cross" height="16px" width="16px" onClick={onClose} />
      </div>
      <p>{message}</p>
      <a href={'#self'} onClick={(event: any) => onAnchorClick(event, navigateTo, onClose)}>
        {appearance === 'success' ? 'View' : 'Try Again'}
      </a>
    </div>
  );
};

const onAnchorClick = (event: any, navigateTo: string, onClose: any) => {
  event.preventDefault();
  onClose();
  navigate(navigateTo);
};

interface IToastProps {
  appearance: AppearanceTypes;
  children: any;
}

export const VirtusToast = ({ appearance, children }: IToastProps) => {
  return (
    <div className={'notification'}>
      <div className={appearance}>
        <div>
          <img id="check" src={check} alt="check" />
          <img id="error" style={{ padding: '5px' }} src={error} alt="error" />
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default ToastNotification;
